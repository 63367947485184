import React, { useEffect, useState } from 'react';

import './progressBar.css';

const ProgressBar = ({ value, status }) => {
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    if (currentValue < 0) {
      setCurrentValue(Math.abs(Number(currentValue)));
    }
  }, [currentValue]);

  return <progress className={`${status} progress`} max="100" value={currentValue.toString()} />;
};

export default ProgressBar;

import React, { useEffect, useState } from 'react';

import Loader from '../../Components/Shared/Loader';
import Card from '../../Components/Shared/Card';
import Error from '../../Components/Shared/Error';
import SearchBar from '../../Components/Shared/SearchBar';
import { useOptionsList } from '../../hooks/useOptionsList';
import useToast from '../../hooks/useToast';

import './productionModelListPage.css';

const ProductionModelListPage = () => {
  const {
    data: modelsOptionsList,
    setData: setModelsOptionsList,
    isLoading: loadModelsList,
    setLoading: setLoadModelsList,
    error: errorOptionsList,
  } = useOptionsList('/energyModels');

  const [currentList, setCurrentList] = useState([]);
  const [searchOption, setSearchOption] = useState('');
  const { showToast } = useToast();

  useEffect(() => {
    setCurrentList(modelsOptionsList);
  }, [modelsOptionsList]);

  useEffect(() => {
    setCurrentList(
      modelsOptionsList.filter(({ dropDownName }) =>
        dropDownName.toUpperCase().includes(searchOption.toString().toUpperCase())
      )
    );
  }, [searchOption]);

  const renderModelsList = () => {
    if (errorOptionsList) {
      return <Error />;
    }
    if (loadModelsList) {
      return <Loader />;
    }
    if (!loadModelsList && !currentList.length) {
      return <div className="no-data">No Energy Models in Workspace</div>;
    }
    return currentList?.map((data) => (
      <Card
        type="energyModels"
        key={data.id}
        data={data}
        setList={setModelsOptionsList}
        setLoading={setLoadModelsList}
        showToast={showToast}
      />
    ));
  };
  return (
    <>
      <SearchBar
        searchOption={searchOption}
        setSearchOption={setSearchOption}
        uploadName="Start New Prediction"
        header="Energy Model"
      />
      <div className="list-section">{renderModelsList()}</div>
    </>
  );
};

export default ProductionModelListPage;

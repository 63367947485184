import React from 'react';

import './loader.css';

const Loader = () => {
  return (
    <div className="loader-container">
      <img className="spinner-loader" src="/Icons/Loader.svg" alt="Loading" />
    </div>
  );
};

export default Loader;

import React, { useEffect, useState } from 'react';

import Loader from '../Loader';
import Error from '../Error';

import './itemsList.css';

const ItemsList = ({
  title,
  list,
  selectedItem,
  handleClick,
  isLoading,
  isItemDataLoading,
  error,
}) => {
  const [currentList, setCurrentList] = useState();
  const [searchOption, setSearchOption] = useState('');

  useEffect(() => {
    if (list?.length) {
      setCurrentList(
        list.filter(({ dropDownName }) =>
          dropDownName.toUpperCase().includes(searchOption.toString().toUpperCase())
        )
      );
    } else {
      setCurrentList([]);
    }
  }, [list, searchOption]);

  if (isLoading) return <Loader />;
  if (error) return <Error />;
  return (
    <div className="items-list">
      <h3 className="items-list-heading">{title}</h3>
      <input
        type="text"
        className="items-list-search"
        placeholder="&#xF002; Search Location"
        value={searchOption}
        onChange={(e) => setSearchOption(e.target.value)}
      />
      {currentList?.length ? (
        <div className="items-list-container">
          {currentList?.map((item) => (
            <div
              role="presentation"
              title={item?.formattedAddress}
              className={`${
                selectedItem?.id === item.id && !isItemDataLoading
                  ? 'list-item active'
                  : 'list-item'
              } ${selectedItem?.id === item.id && isItemDataLoading ? 'list-item-loading' : ''}
              ${selectedItem?.id !== item.id && isItemDataLoading ? 'list-item-disabled' : ''}`}
              key={item.id}
              onClick={() => {
                handleClick(item);
              }}
            >
              <span>
                <img className="list-image" src="/Icons/Location.svg" alt="List item logo" />
                {item.dropDownName}
              </span>
              <span>
                <img className="list-image" src="/Icons/Date.svg" alt="date" />
                {new Date(item.dateRun * 1000).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}
              </span>
              <span>{item.formattedAddress}</span>
              {selectedItem?.id === item?.id && isItemDataLoading && (
                <span className="list-item-progress">(in progress)</span>
              )}
            </div>
          ))}
        </div>
      ) : (
        currentList?.length === 0 && (
          <div className="items-list-empty">
            There are no {title.toLowerCase()} yet. Please create!
          </div>
        )
      )}
    </div>
  );
};

export default ItemsList;

import { useEffect, useState } from 'react';

import axiosConfig from '../utils/axiosConfig';

export const useShadingSceneData = (id) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      await setLoading(true);
      await axiosConfig
        .get(`/api/shading`, {
          params: { id },
        })
        .then((response) => {
          if (response) {
            setData(response.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    })();
    return () => setData([]);
  }, [id]);

  return {
    data,
    isLoading,
    error,
  };
};

import React, { createContext, useState } from 'react';

export const UserContext = createContext(false);

const UserContextProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState();
  return (
    <UserContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={{
        userInfo,
        setUserInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

import React from 'react';
import ReactDOM from 'react-dom';

import './confirm.css';

const Confirm = ({ onAccept, onDecline, message, confirmRef }) => {
  return ReactDOM.createPortal(
    <div className="confirm">
      <div className="confirm-content" ref={confirmRef}>
        <h2 className="confirm-message">{message}</h2>
        <div className="confirm-buttons">
          <button type="button" onClick={onAccept}>
            Yes
          </button>
          <button type="button" className="cancel-button" onClick={onDecline}>
            No
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('app-modal')
  );
};

export default Confirm;

import React from 'react';
import { useParams } from 'react-router-dom';
import Chart from '../../Components/Shared/Charts';
import Loader from '../../Components/Shared/Loader';
import Error from '../../Components/Shared/Error';
import { useInvertersData } from '../../hooks/useInvertersData';
import { getInvertersChartData, getTemperatureChartData } from '../../utils/adapters/chartAdapter';
import { chartConfig } from '../../utils/chartConfig';
import { formatNumber } from '../../utils/helpers';

import './inverters.css';

const InvertersPage = (props) => {
  const { id: propsId } = props;
  const { id } = useParams();

  const {
    data: inverterData,
    isLoading: loadInverterData,
    error,
  } = useInvertersData(propsId || id);

  const inverterChartData = getInvertersChartData(inverterData);
  const inverterTemperatureChartData = getTemperatureChartData(inverterData);

  const { details, dropDownName } = inverterData || {};
  const {
    manufacturer,
    model,
    vmppMin,
    vmppMax,
    vabsMax,
    pnomConv,
    pmaxOut,
    nightLoss,
    imaxDc,
    modeOper,
    nbInputs,
    nbMppt,
    inomAc,
    imaxAc,
    voutConv,
    monoTri,
  } = details || {};

  if (error) return <Error />;
  return loadInverterData ? (
    <Loader />
  ) : (
    <div className="main-content">
      <section className="properties-section">
        <div className="properties-section-title">
          <div
            className="equipment-img"
            style={{ backgroundImage: 'url(/Icons/Inverter-Box.svg)' }}
          />
          <div>{dropDownName}</div>
        </div>
        <hr className="divider" />
        <div>
          <div>Manufacturer:</div>
          <div>{manufacturer}</div>
        </div>
        <div>
          <div>Model:</div>
          <div>{model}</div>
        </div>
        <br />
        <div>
          <h3>AC Output Envelope:</h3>
        </div>
        <div>
          <div>Nominal AC Power:</div>
          <div>{formatNumber(pnomConv)}kW</div>
        </div>
        <div>
          <div>Max AC Power:</div>
          <div>{formatNumber(pmaxOut)}kW</div>
        </div>
        <div>
          <div>Nominal AC Current:</div>
          <div>{formatNumber(inomAc)}A</div>
        </div>
        <div>
          <div>Max AC Current:</div>
          <div>{formatNumber(imaxAc)}A</div>
        </div>
        <div>
          <div>Grid Voltage:</div>
          <div>{formatNumber(voutConv)}V</div>
        </div>
        <div>
          <div>Output Phases:</div>
          <div>{monoTri}</div>
        </div>
        <div>
          <div>Night Loss:</div>
          <div>{formatNumber(nightLoss)}W</div>
        </div>
        <br />
        <div>
          <h3>DC Input Envelope:</h3>
        </div>
        <div>
          <div>Max Input Current:</div>
          <div>{formatNumber(imaxDc)}A</div>
        </div>
        <div>
          <div>MPPT Voltage Min:</div>
          <div>{formatNumber(vmppMin)}V</div>
        </div>
        <div>
          <div>MPPT Voltage Max:</div>
          <div>{formatNumber(vmppMax)}V</div>
        </div>
        <div>
          <div>Max DC Voltage:</div>
          <div>{formatNumber(vabsMax)}V</div>
        </div>
        <br />
        <div>
          <h3>Architecture:</h3>
        </div>
        <div>
          <div>Operating Mode:</div>
          <div>{modeOper}</div>
        </div>
        <div>
          <div>Number of Inputs:</div>
          <div>{formatNumber(nbInputs)}</div>
        </div>
        <div>
          <div>Number of MPPTs:</div>
          <div>{formatNumber(nbMppt)}</div>
        </div>
      </section>
      <section className="chart-section">
        <Chart
          chartData={{
            ...chartConfig,
            series: inverterChartData,
            title: {
              text: 'Inverter Efficiency',
            },

            yAxis: {
              title: {
                text: 'Efficiency (%)',
              },
            },
            xAxis: {
              title: {
                text: '% of Rated Power',
              },
            },
            tooltip: {
              headerFormat: '<span class="location-chart-tooltip"/>',
              pointFormat:
                '<span class="location-chart-tooltip">Percent Of Rated Power {point.category} %; Efficiency {point.y}<br/>{series.name}</span>',
              valueSuffix: ' %',
              useHTML: true,
            },
            legend: {
              ...chartConfig.legend,
              y: 135,
            },
          }}
        />
        <Chart
          chartData={{
            ...chartConfig,
            series: [{ data: inverterTemperatureChartData, color: '#FF9B15' }],
            title: {
              text: 'Inverter Temperature Power Limitation',
            },

            yAxis: {
              title: {
                text: 'Power [kWac]',
              },
            },
            xAxis: {
              title: {
                text: 'Ambient Temperature °C',
              },
            },
            tooltip: {
              headerFormat: '<span class="location-chart-tooltip"/>',
              pointFormat:
                '<span class="location-chart-tooltip">Temperature: {point.x}°C;<br/>Power: {point.y}kWac</span>',
              useHTML: true,
            },
            legend: {
              enabled: false,
            },
          }}
        />
      </section>
    </div>
  );
};
export default InvertersPage;

import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { DATA_TYPES, UPLOAD_TYPES } from '../../../utils/constants';
import Tooltip from '../Tooltip';
import EPMtab from '../EPMtab';
import { assumptionInitialValues, assumptionsSchema } from './assumptionsSchema';
import axiosConfig from '../../../utils/axiosConfig';

const Assumptions = ({ workspaceData, setIsError, setIsLoading }) => {
  const [selectedModule, setSelectedModule] = useState();
  const [selectedInverter, setSelectedInverter] = useState();
  const [selectedScene, setSelectedScene] = useState();
  const [blocks, setBlocks] = useState({});
  const [simulated, setSimulated] = useState(false);

  return (
    <Formik initialValues={assumptionInitialValues} validationSchema={assumptionsSchema}>
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        } = props;

        // eslint-disable-next-line no-shadow
        const sendAssumptions = async (values) => {
          setIsLoading(true);
          const assumption = await Object.assign(values, blocks[0]);
          try {
            // await setSimulationData(simulationValues);
            const response = await axiosConfig.post(`/api/assumption`, assumption);
            await setIsLoading(false);
          } catch (e) {
            setIsError(true);
          }
        };

        return (
          <div className="right-side">
            <h2>Workspace Base Input Assumptions:</h2>

            <select
              name="uploadType"
              required
              defaultValue="Select Upload Type"
              onChange={handleChange}
              onBlur={handleBlur}
              className={`w-100 ${
                (errors.api && 'input-error') ||
                (errors.uploadType && touched.uploadType && 'input-error')
              }`}
            >
              <option value="Select Upload Type" disabled>
                Select Upload Type
              </option>
              <option value="solaranywhere">Solar Anywhere</option>
              <option value="nsrdb">NSRDB</option>
            </select>
            {errors.uploadType && touched.uploadType && (
              <div className="input-feedback">{errors.uploadType}</div>
            )}
            {errors.api && <div className="input-feedback">{errors.api}</div>}

            {values?.uploadType === UPLOAD_TYPES.NSRDB && (
              <div className="weather-data-info w-100">
                <h4 className="weather-data-header">Weather Data:</h4>
                <div className="weather-data-inner">
                  <div className="section-heading">Data Type</div>
                  <Tooltip content="Field is required. Please choose an option from the list below" />
                </div>
                <select
                  name="dataType"
                  defaultValue="Select Data Type"
                  onChange={(e) => {
                    // eslint-disable-next-line no-undef
                    handleChange(e);
                    setFieldValue('year', '');
                    setFieldValue('interval', '');
                    setFieldTouched('year', false);
                    setFieldTouched('interval', false);
                  }}
                  onBlur={handleBlur}
                  className={`${
                    (errors.api && 'input-error') ||
                    (errors.dataType && touched.dataType && 'input-error')
                  }`}
                >
                  <option value="Select Data Type" disabled>
                    Select Data Type
                  </option>
                  {Object.keys(DATA_TYPES).map((key) => (
                    <option key={key} value={key}>
                      {DATA_TYPES[key].title}
                    </option>
                  ))}
                </select>
                {errors.dataType && touched.dataType && (
                  <div className="input-feedback">{errors.dataType}</div>
                )}
                {errors.api && <div className="input-feedback">{errors.api}</div>}
                {values.dataType && (
                  <>
                    <div className="weather-data-inner">
                      <div className="section-heading">Year</div>
                      <Tooltip content="Field is required. Please choose an option from the list below" />
                    </div>
                    <select
                      name="year"
                      value={values.year || 'Select Year'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`${
                        (errors.api && 'input-error') ||
                        (errors.year && touched.year && 'input-error')
                      }`}
                    >
                      <option value="Select Year" disabled>
                        Select Year
                      </option>
                      {DATA_TYPES[values?.dataType]?.years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    {errors.year && touched.year && (
                      <div className="input-feedback">{errors.year}</div>
                    )}
                    {errors.api && <div className="input-feedback">{errors.api}</div>}
                  </>
                )}
                {DATA_TYPES[values.dataType] === DATA_TYPES.PSMV3_SUBHOURLY && (
                  <>
                    <div className="weather-data-inner">
                      <div className="section-heading">Interval</div>
                      <Tooltip content="Field is required. Please choose an option from the list below" />
                    </div>
                    <select
                      name="interval"
                      value={values.interval || 'Select Interval'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`${
                        (errors.api && 'input-error') ||
                        (errors.interval && touched.interval && 'input-error')
                      }`}
                    >
                      <option value="Select Interval" disabled>
                        Select Interval
                      </option>
                      <option value="30">30 Minutes</option>
                      <option value="15">15 Minutes</option>
                      <option value="5">5 Minutes</option>
                    </select>
                    {errors.interval && touched.interval && (
                      <div className="input-feedback">{errors.interval}</div>
                    )}
                    {errors.api && <div className="input-feedback">{errors.api}</div>}
                  </>
                )}
              </div>
            )}

            <EPMtab
              modules={workspaceData?.components?.modulesDropDownList}
              inverters={workspaceData?.components?.invertersDropDownList}
              scenes={workspaceData?.components?.scenesDropDownList}
              selectedScene={selectedScene}
              setSelectedInverter={setSelectedInverter}
              setSelectedModule={setSelectedModule}
              setSelectedScene={setSelectedScene}
              setSimulated={setSimulated}
              setBlocks={setBlocks}
              id={0}
              blocks={blocks}
            />
            <button
              type="button"
              className="success-btn"
              disabled={
                JSON.stringify(errors) !== '{}' ||
                Object.keys(touched).length === 0 ||
                JSON.stringify(blocks[0]?.errors) !== '{}' ||
                ((blocks[0]?.numberOfInverters === '' || blocks[0]?.acohmicLoss === '') &&
                  Object.keys(touched).length === 1)
              }
              onClick={() => sendAssumptions(values)}
            >
              Send Assumptions
            </button>
          </div>
        );
      }}
    </Formik>
  );
};
export default Assumptions;

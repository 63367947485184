import * as Yup from 'yup';

export const EPMtabInitialValues = {
  farShading: false,
  arrayType: '',
  gCR: '',
  azimuth: '',
  axisTilt: '',
  maxAngle: '',
  stringLength: '',
  dcOhmic: '',
  moduleQuality: '',
  mismatch: '',
  lId: '',
  stringVoltageMismatch: '',
  acLoss: '',
  invAuxLoss: false,
  valueUc: 29,
  valueUv: 0,
  backtrack: false,
  bifacial: false,
  trackerHeight: '',
  rackingShading: '',
  irradianceMismatchLoss: '',
  moduleTransparency: '',
  electricalImpact: false,
  transverseDiodes: '',
  shadingModel: '',
  generalAlbedo: '',
  albedo: {
    January: '',
    February: '',
    March: '',
    April: '',
    May: '',
    June: '',
    July: '',
    August: '',
    September: '',
    October: '',
    November: '',
    December: '',
  },
};

export const EPMtabValidationSchema = Yup.object().shape({
  arrayType: Yup.string().when('shadingModel', {
    is: '3D',
    then: Yup.string(),
    otherwise: Yup.string().required('Please select Array Type'),
  }),

  shadingModel: Yup.string().required('Please select Shading Model'),
  transverseDiodes: Yup.number().when('electricalImpact', {
    is: true,
    then: Yup.number()
      .required('No Transverse Diodes provided.')
      .min(0, 'Transverse Diodes should be from 0 to 10.')
      .max(10, 'Transverse Diodes should be from 0 to 10.'),
    otherwise: Yup.number(),
  }),

  gCR: Yup.number().when('shadingModel', {
    is: '3D',
    then: Yup.number(),
    otherwise: Yup.number()
      .required('No GCR provided.')
      .min(0.1, 'GCR should be from 0.1 to 0.85.')
      .max(0.85, 'GCR should be from 0.1 to 0.85.'),
  }),

  backtrack: Yup.boolean(),

  maxAngle: Yup.number().when(['shadingModel', 'arrayType'], {
    is: (shadingModel, arrayType) => shadingModel === '2D' && arrayType === 'tracker',
    then: Yup.number()
      .required('No Max Angle provided.')
      .min(0, 'Max Angle should be from 0 to 90.')
      .max(90, 'Max Angle should be from 0 to 90.'),
    otherwise: Yup.number(),
  }),

  azimuth: Yup.number().when('shadingModel', {
    is: '3D',
    then: Yup.number(),
    otherwise: Yup.number()
      .required('No Azimuth provided.')
      .min(0, 'Azimuth should be from 0 to 360.')
      .max(360, 'Azimuth should be from 0 to 360.'),
  }),

  axisTilt: Yup.number().when('shadingModel', {
    is: '3D',
    then: Yup.number(),
    otherwise: Yup.number()
      .required('No Axis Tilt provided.')
      .min(0, 'Axis Tilt should be from 0 to 30.')
      .max(30, 'Axis Tilt should be from 0 to 30.'),
  }),

  tilt: Yup.number().when(['shadingModel', 'arrayType'], {
    is: (shadingModel, arrayType) => shadingModel === '2D' && arrayType === 'fixed',
    then: Yup.number()
      .required('No Tilt provided.')
      .min(0, 'Tilt should be from 0 to 90.')
      .max(90, 'Tilt should be from 0 to 90.'),
    otherwise: Yup.number(),
  }),

  stringLength: Yup.number()
    .required('No String Length provided.')
    .min(1, 'String Length should be from 1 to 40.')
    .max(40, 'String Length should be from 1 to 40.'),
  acohmicLoss: Yup.number()
    .required('No AC Loss provided.')
    .min(0, 'AC Loss should be from 0 to 100.')
    .max(100, 'AC Loss should be from 0 to 100.'),
  numberOfInverters: Yup.number()
    .required('No Number of Inverters provided.')
    .min(1, 'Number of Inverters should be from 1 to 1000.')
    .max(1000, 'Number of Inverters should be from 1 to 1000.'),
  stringCount: Yup.number()
    .required('No String Count provided.')
    .min(1, 'String Count should be from 1 to 2000.')
    .max(2000, 'String Count should be from 1 to 2000.'),
  dcOhmic: Yup.number()
    .required('No DC Ohmic provided.')
    .min(-100, 'DC Ohmic should be from -100 to 100.')
    .max(100, 'DC Ohmic should be from -100 to 100.'),
  moduleQuality: Yup.number()
    .required('No Module Quality provided.')
    .min(-100, 'Module Quality should be from -100 to 100.')
    .max(100, 'Module Quality should be from -100 to 100.'),
  mismatch: Yup.number()
    .required('No Mismatch provided.')
    .min(-100, 'Mismatch should be from -100 to 100.')
    .max(100, 'Mismatch should be from -100 to 100.'),
  lId: Yup.number()
    .required('No LID provided.')
    .min(-100, 'LID should be from -100 to 100.')
    .max(100, 'LID should be from -100 to 100.'),
  stringVoltageMismatch: Yup.number()
    .required('No String Voltage Mismatch provided.')
    .min(-100, 'String Voltage Mismatch should be from -100 to 100.')
    .max(100, 'String Voltage Mismatch should be from -100 to 100.'),
  invAuxLoss: Yup.boolean(),
  valueUc: Yup.number()
    .required('No UC provided.')
    .min(0, 'UC should be from 0 to 100.')
    .max(100, 'UC should be from 0 to 100.'),
  valueUv: Yup.number()
    .required('No UV provided.')
    .min(0, 'UV should be from 0 to 100.')
    .max(100, 'UV should be from 0 to 100.'),

  bifacial: Yup.boolean(),

  trackerHeight: Yup.number().when('bifacial', {
    is: true,
    then: Yup.number()
      .required('No Tracker Height provided.')
      .min(0, 'Tracker Height should be from 0 to 8.')
      .max(8, 'Tracker Height should be from 0 to 8.'),
    otherwise: Yup.number(),
  }),

  rackingShading: Yup.number().when('bifacial', {
    is: true,
    then: Yup.number()
      .required('No Racking Shading provided.')
      .min(0, 'Racking Shading should be from 0 to 100.')
      .max(100, 'Racking Shading should be from 0 to 100.'),
    otherwise: Yup.number(),
  }),

  irradianceMismatchLoss: Yup.number().when('bifacial', {
    is: true,
    then: Yup.number()
      .required('No Irradiance Mismatch Loss provided.')
      .min(0, 'Irradiance Mismatch Loss should be from 0 to 100.')
      .max(100, 'Irradiance Mismatch Loss should be from 0 to 100.'),
    otherwise: Yup.number(),
  }),

  moduleTransparency: Yup.number().when('bifacial', {
    is: true,
    then: Yup.number()
      .required('No Module Transparency provided.')
      .min(0, 'Module Transparency should be from 0 to 100.')
      .max(100, 'Module Transparency should be from 0 to 100.'),
    otherwise: Yup.number(),
  }),

  generalAlbedo: Yup.number()
    .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
    .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),

  albedo: Yup.object().shape({
    January: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
    February: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
    March: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
    April: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
    May: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
    June: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
    July: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
    August: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
    September: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
    October: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
    November: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
    December: Yup.number()
      .required('This field is required.')
      .min(0, 'Albedo is incorrect. It should be from 0 to 1.')
      .max(1, 'Albedo is incorrect. It should be from 0 to 1.'),
  }),
});

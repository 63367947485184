import React from 'react';
import UploadButton from '../Upload Button';

import './searchBar.css';

const SearchBar = (props) => {
  const { searchOption, setSearchOption, uploadName, header } = props;

  return (
    <div className="search-section">
      <h1>{header}</h1>
      <div>
        <input
          type="text"
          value={searchOption}
          onChange={(e) => setSearchOption(e.target.value)}
          placeholder="&#xF002; Search"
          className="search-input"
        />
        <UploadButton name={uploadName} />
      </div>
    </div>
  );
};

export default SearchBar;

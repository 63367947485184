import axiosConfig from '../utils/axiosConfig';

class Service {
  static async deleteEquipment(id, type) {
    this.id = id;
    this.type = type;
    return axiosConfig
      .delete(`/api/${type}/${id}`)
      .then((res) => res)
      .catch((err) => new Error(err));
  }
}

export default Service;

// const handleDelete = async (id, type, dropDownName, event) => {
//   event.stopPropagation();
//   // eslint-disable-next-line no-restricted-globals,no-alert
//   const confirmation = confirm(`Are you sure to delete ${dropDownName}?`);
//   if (confirmation) {
//     await setLoading(true);
//     await axiosConfig
//       .delete(`/api/${type}/${id}`)
//       .then((response) => {
//         if (response) {
//           setCurrentList(response.data);
//           setLoading(false);
//         }
//       })
//       .catch((err) => {
//         setLoading(false);
//         throw new Error(err);
//       });
//   }
// };

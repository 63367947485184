import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import axiosConfig from '../../utils/axiosConfig';
import { ShadingSceneInitialValues, ShadingSceneValidationSchema } from './ShadingSceneSchema';
import Loader from '../../Components/Shared/Loader';
import Error from '../../Components/Shared/Error';
import InputField from '../../Components/Shared/InputField';
import Tooltip from '../../Components/Shared/Tooltip';

import './shadingSceneUploadPage.css';

const ShadingSceneUploadPage = () => {
  const history = useHistory();
  const [file] = useState(new FormData());
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => () => setLoading(false), []);

  const handleChangeFile = (e) => {
    setFileName(e.target.value.split(/(\\|\/)/g).pop());
    file.append('file', e.target.files[0]);
  };

  const handleUploadShadingScene = async (values) => {
    file.append('dropDownName', values.shadingSceneName.trim());
    file.append('arrayType', values.arrayType);
    file.append('gcr', values.gCR);
    file.append('azimuth', values.azimuth);
    file.append('axisTilt', values.axisTilt);
    file.append('maxAngle', values.maxAngle);
    file.append('terrainAware', values.terrainTracking);
    file.append('backTracking', values.backTracking);
    await setLoading(true);
    await axiosConfig
      .post(`/api/shading/upload`, file)
      .then((response) =>
        response.status !== 200
          ? setLoading(true)
          : history.push('/shading', { from: '/shading-upload' })
      )
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
    await setLoading(false);
  };

  if (error) return <Error />;

  return loading ? (
    <Loader />
  ) : (
    <Formik
      initialValues={ShadingSceneInitialValues}
      validationSchema={ShadingSceneValidationSchema}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleBlur } = props;
        return (
          <div id="upload-shading-scene-page">
            <div id="upload-box">
              <h2 className="title">New Shading Scene</h2>
              <div className="upload-shading-details">
                <form className="upload-shading-form">
                  <input
                    type="file"
                    id="upload"
                    accept={['.pvc', '.json']}
                    hidden
                    onChange={handleChangeFile}
                  />
                  <InputField
                    type="text"
                    name="shadingSceneName"
                    id="file-name"
                    label="Shading Scene Name"
                    placeholder="Enter shading scene name here"
                    tooltipContent="Field is required, length should be up to 50 chars"
                    formikData={props}
                  />
                  <div className="shading-array-type">
                    <div className="shading-section-heading">Array Type</div>
                    <Tooltip content="Field is required. Please choose an option from the list below" />
                  </div>
                  <select
                    name="arrayType"
                    defaultValue={values.arrayType ? values.arrayType : 'Select Array Type'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`array-type ${
                      (errors.api && 'input-error') ||
                      (errors.arrayType && touched.arrayType && 'input-error')
                    }`}
                  >
                    <option value="Select Array Type" disabled>
                      Select Array Type
                    </option>
                    <option value="tracker">Single Axis Tracker</option>
                    <option value="fixed">Fixed Tilt</option>
                  </select>
                  {errors.arrayType && touched.arrayType && (
                    <div className="input-feedback">{errors.arrayType}</div>
                  )}
                  {errors.api && <div className="input-feedback">{errors.api}</div>}
                  {values.arrayType === 'tracker' && (
                    <>
                      <div className="shading-inputs-heading">Tracking Algorithm Inputs</div>
                      <div className="shading-inputs">
                        <div className="input-container">
                          <InputField
                            name="gCR"
                            type="number"
                            label="GCR"
                            tooltipContent="Field is required, should be a number from 0.1 to 0.85"
                            tooltipDirection="top"
                            formikData={props}
                          />
                        </div>
                        <div className="input-container">
                          <InputField
                            name="azimuth"
                            type="number"
                            label="Azimuth"
                            tooltipContent="Field is required, should be a number from 0 to 360"
                            tooltipDirection="top"
                            disabled
                            formikData={props}
                          />
                        </div>
                        <div className="input-container">
                          <InputField
                            name="axisTilt"
                            type="number"
                            label="Axis Tilt"
                            tooltipContent="Field is required, should be a number from 0 to 30"
                            tooltipDirection="top"
                            formikData={props}
                          />
                        </div>
                        <div className="input-container wide-label">
                          <InputField
                            name="maxAngle"
                            type="number"
                            label="Max Tracking Angle"
                            tooltipContent="Field should be a number from 0 to 90"
                            formikData={props}
                          />
                        </div>
                      </div>
                      <div className="tracking-containers">
                        <div className="tracking-container">
                          <span>Terrain Aware Tracking</span>
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <label className="switch">
                            <input
                              name="terrainTracking"
                              type="checkbox"
                              checked={values.terrainTracking}
                              onChange={handleChange}
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="tracking-container">
                          <span>Backtracking</span>
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <label className="switch">
                            <input
                              name="backTracking"
                              type="checkbox"
                              checked={values.backTracking}
                              onChange={handleChange}
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="select-section">
                    <button
                      className="select-button"
                      type="button"
                      onClick={() => document.getElementById('upload').click()}
                    >
                      + Select Shading Scene File
                    </button>
                    <input
                      type="text"
                      className="file-name"
                      hidden={!fileName}
                      value={fileName}
                      disabled
                    />
                  </div>
                  <div className="shading-manage">
                    <button
                      className="upload-button"
                      disabled={
                        !fileName || !values.shadingSceneName || Object.keys(errors).length !== 0
                      }
                      type="button"
                      onClick={() => handleUploadShadingScene(values)}
                    >
                      Create New Shading Scene
                    </button>
                    <button
                      className="cancel-button"
                      type="button"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default ShadingSceneUploadPage;

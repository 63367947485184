import * as Yup from 'yup';

export const EPMInitialValues = {
  name: '',
};

export const EPMValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('No Simulation Name provided.')
    .min(1, 'Simulation Name is too short - should be 1 char minimum.')
    .max(50, 'Simulation Name is too long - should be 50 chars maximum.')
    .matches(/^\S/, 'Simulation Name should not start from space.'),
  farShading: Yup.boolean(),
  electricalImpact: Yup.boolean(),
});

import React from 'react';
import { useParams } from 'react-router-dom';

import { square, formatNumber } from '../../utils/helpers';
import { chartConfig } from '../../utils/chartConfig';
import { getLocationsChartData } from '../../utils/adapters/chartAdapter';
import Loader from '../../Components/Shared/Loader';
import Error from '../../Components/Shared/Error';
import DataTable from '../../Components/Shared/DataTable';
import Chart from '../../Components/Shared/Charts';
import LocationMap from '../../Components/Shared/Maps';
import { useWeatherData } from '../../hooks/useWeatherData';

import './locationsPage.css';

const LocationsPage = (props) => {
  const { id } = useParams();
  const { id: propsId } = props;

  const { data, isLoading, error } = useWeatherData(id || propsId);
  const { weatherData, details } = data || {};
  const { elevation, weatherDataSource, latitude, longitude, name, localId } = details || {};
  const { weatherData: tableData, soiling, totalGhiByMonths, weatherStatistic } = weatherData || [];
  const { averageDuringDaylightHours, summary } = weatherStatistic || {};

  const {
    totalGHIChartData,
    ghiChartData,
    dhiChartData,
    dniChartData,
    temperatureChartData,
    soilingChartData,
    elevationAnglesChartData,
  } = getLocationsChartData(weatherData);

  if (error) return <Error />;
  return isLoading ? (
    <Loader />
  ) : (
    <div id="location-page">
      <div className="data-and-map w-100">
        <div className="properties-section">
          <div className="heading">
            <img src="/Icons/Location-Box.svg" alt="Location" />
            <h1>{name}</h1>
          </div>
          <div>
            <div className="weather-item-key">Latitude:</div>
            <div>{latitude} deg</div>
          </div>
          <div>
            <div className="weather-item-key">Longitude:</div>
            <div>{longitude} deg</div>
          </div>
          <div>
            <div className="weather-item-key">Elevation:</div>
            <div>{elevation} ft</div>
          </div>
          <div>
            <div className="weather-item-key">Weather Data Source:</div>
            <div className="weather-item-value">{weatherDataSource}</div>
          </div>
          <div>
            <div className="weather-item-key">Location ID:</div>
            <div className="weather-item-value">{localId}</div>
          </div>
          <hr className="divider" />
          <h3>Irradiance Summary</h3>
          {summary &&
            Object.entries(summary).map(([key, value]) => (
              <div key={key} className="weather-item">
                <div className="weather-item-key">
                  {key.includes('total')
                    ? `${key.split(/(?=[A-Z])/)[0]} ${key.split(/(?=[A-Z])/)[1].toUpperCase()}`
                    : key?.split(/(?=[A-Z])/).map((part) => `${part} `)}
                  :
                </div>
                <div className="weather-item-value">{square(formatNumber(value))}</div>
              </div>
            ))}
          <hr className="divider" />
          <h3>Irradiance Averages</h3>
          {averageDuringDaylightHours &&
            Object.entries(averageDuringDaylightHours).map(([key, value]) => (
              <div key={key}>
                <div className="weather-item-key capitalize">
                  {key === 'ghi' || key === 'dhi' || key === 'dni'
                    ? key.toUpperCase()
                    : key?.split(/(?=[A-Z])/).map((part) => `${part} `)}
                  :
                </div>
                <div>{square(formatNumber(value))}</div>
              </div>
            ))}
        </div>
        <section className="map-section w-100">
          {!propsId && (
            <div className="top-map">
              <LocationMap
                options={{
                  center: {
                    lat: latitude || 0,
                    lng: longitude || 0,
                  },
                }}
                data={[
                  {
                    id: 1,
                    dropDownName: name,
                    latitude,
                    longitude,
                    elevation,
                  },
                ]}
                infoWindowContent={['name', 'elevation']}
              />
            </div>
          )}
          <div className="shading-chart w-100">
            <Chart
              chartData={{
                ...chartConfig,
                series: [
                  {
                    data: elevationAnglesChartData,
                    type: 'spline',
                    color: '#FFA22C',
                  },
                ],
                legend: false,
                title: {
                  text: 'Far shading elevation angles',
                },
                yAxis: {
                  title: {
                    text: 'Elevation Angles (degrees)',
                  },
                },
                xAxis: {
                  title: {
                    text: 'Azimuth (degrees)',
                  },
                  reversed: true,
                },
                tooltip: {
                  headerFormat: '<span class="location-chart-tooltip"/>',
                  pointFormat:
                    '<span class="location-chart-tooltip">Azimuth {point.category} °; Elevation angle {point.y}</span>',
                  valueSuffix: ' °',
                  useHTML: true,
                },
              }}
            />
          </div>
        </section>
      </div>
      <div className="location-map">
        <section className="location-chart-section">
          <div className="location-chart-content">
            <div className="location-chart-wider">
              <Chart
                chartData={{
                  ...chartConfig,
                  series: [
                    {
                      data: totalGHIChartData && totalGHIChartData,
                    },
                  ],
                  plotOptions: {
                    series: {
                      pointWidth: 20,
                      borderRadius: 10,
                      color: '#ffab40',
                    },
                  },
                  chart: {
                    type: 'column',
                  },
                  title: {
                    text: 'Total GHI (kW/m<sup>2</sup>/Year)',
                    useHTML: true,
                  },
                  legend: false,
                  yAxis: {
                    title: {
                      text: 'Total (kW/m<sup>2</sup>/Year)',
                      useHTML: true,
                    },
                  },
                  xAxis: {
                    categories:
                      totalGhiByMonths && Object.values(totalGhiByMonths).map(({ month }) => month),
                  },
                  tooltip: {
                    headerFormat: '<span class="location-chart-tooltip"/>',
                    pointFormat:
                      '<span class="location-chart-tooltip">{point.category}: <b>{point.y}</b></span>',
                    valueSuffix: ' W/m<sup>2</sup>',
                    useHTML: true,
                  },
                }}
              />
            </div>
            <div className="location-chart-wide">
              <Chart
                chartData={{
                  ...chartConfig,
                  series: [
                    {
                      data: soilingChartData && soilingChartData,
                    },
                  ],
                  plotOptions: {
                    series: {
                      pointWidth: 20,
                      borderRadius: 10,
                      color: '#ffab40',
                    },
                  },
                  chart: {
                    type: 'column',
                  },
                  title: {
                    text: 'Soiling Rate',
                  },
                  legend: false,
                  yAxis: {
                    title: {
                      text: 'Soiling Rate (%)',
                    },
                    labels: {
                      format: '{value}%',
                    },
                  },
                  xAxis: {
                    categories: soiling && Object.values(soiling).map(({ month }) => month),
                  },
                  tooltip: {
                    headerFormat: '<span class="location-chart-tooltip"/>',
                    pointFormat:
                      '<span class="location-chart-tooltip">{point.category}: <b>{point.y}</b></span>',
                    valueSuffix: ' %',
                  },
                }}
              />
            </div>
          </div>
          <div className="location-charts">
            <div className="location-chart">
              <Chart
                chartData={{
                  ...chartConfig,
                  chart: {
                    type: 'spline',
                  },
                  series: dhiChartData && dhiChartData,
                  legend: false,
                  title: {
                    text: 'Average DHI (W/m<sup>2</sup>) by Month and Hour',
                    useHTML: true,
                  },

                  yAxis: {
                    title: {
                      text: 'Average DHI (W/m<sup>2</sup>)',
                      useHTML: true,
                    },
                  },
                  xAxis: {
                    title: {
                      text: 'Hour starting',
                    },
                  },
                  tooltip: {
                    headerFormat: '<span class="location-chart-tooltip"/>',
                    valueSuffix: ' W/m<sup>2</sup>',
                    useHTML: true,
                  },
                }}
              />
            </div>
            <div className="location-chart">
              <Chart
                chartData={{
                  ...chartConfig,
                  chart: {
                    type: 'spline',
                  },
                  series: dniChartData && dniChartData,
                  legend: false,
                  title: {
                    text: 'Average DNI (W/m<sup>2</sup>) by Month and Hour',
                    useHTML: true,
                  },

                  yAxis: {
                    title: {
                      text: 'Average DNI (W/m<sup>2</sup> )',
                      useHTML: true,
                    },
                  },
                  xAxis: {
                    title: {
                      text: 'Hour starting',
                    },
                  },
                  tooltip: {
                    headerFormat: '<span class="location-chart-tooltip"/>',
                    valueSuffix: ' W/m<sup>2</sup>',
                    useHTML: true,
                  },
                }}
              />
            </div>
          </div>
          <div className="location-charts">
            <div className="location-chart">
              <Chart
                chartData={{
                  ...chartConfig,
                  chart: {
                    type: 'spline',
                  },
                  series: temperatureChartData && temperatureChartData,
                  legend: false,
                  title: {
                    text: 'Average Temperature (°C) by Month and Hour',
                  },

                  yAxis: {
                    title: {
                      text: 'Average Temperature (°C) by Month and Hour',
                    },
                  },
                  xAxis: {
                    title: {
                      text: 'Hour starting',
                    },
                  },
                  tooltip: {
                    headerFormat: '<span class="location-chart-tooltip"/>',
                    valueSuffix: ' °C',
                  },
                }}
              />
            </div>
            <div className="location-chart">
              <Chart
                chartData={{
                  ...chartConfig,
                  chart: {
                    type: 'spline',
                  },
                  series: ghiChartData && ghiChartData,
                  legend: false,
                  title: {
                    text: 'Average GHI (W/m<sup>2</sup>) by Month and Hour',
                    useHTML: true,
                  },

                  yAxis: {
                    title: {
                      text: 'Average GHI (W/m<sup>2</sup> )',
                      useHTML: true,
                    },
                  },
                  xAxis: {
                    title: {
                      text: 'Hour starting',
                    },
                  },
                  tooltip: {
                    headerFormat: '<span class="location-chart-tooltip"/>',
                    valueSuffix: ' W/m<sup>2</sup>',
                    useHTML: true,
                  },
                }}
              />
            </div>
          </div>
        </section>
        <section className="table-section">
          <DataTable weatherData={tableData} />
        </section>
      </div>
    </div>
  );
};
export default LocationsPage;

import React, { useState } from 'react';

import './tooltip.css';

const Tooltip = ({ children, direction, content, delay }) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div className="tooltip-wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      <img src="/Icons/Info.svg" className="tooltip-icon" width="18px" alt="logo" />
      {active && <div className={`tooltip-tip ${direction || 'right'}`}>{content}</div>}
    </div>
  );
};

export default Tooltip;

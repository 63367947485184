import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { routes } from './constants';
import { AuthContext } from '../context/authContext';
import withDashboard from '../Components/Shared/Dashboard';
import LoginPage from '../Pages/LoginPage';
import SignUp from '../Pages/SignUpPage';
import ForgotPasswordPage from '../Pages/ForgotPasswordPage';

export const RootRouter = () => {
  const history = useHistory();

  const { isAuthenticated } = useContext(AuthContext);

  return !isAuthenticated ? (
    <Router>
      <Switch>
        <Route path="/login" component={LoginPage} exact />
        <Route path="/sign-up" component={SignUp} exact />
        <Route path="/sign-up/:params" render={() => <SignUp />} exact />
        <Route path="/forgot/:params" render={() => <ForgotPasswordPage />} exact />
        <Route path="/forgot" component={ForgotPasswordPage} exact />
        <Redirect to="/login" />
      </Switch>
    </Router>
  ) : (
    <Router history={history}>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route key={path} path={path} exact component={() => withDashboard(component)} />
        ))}
        <Redirect from="/" to="/home" />
      </Switch>
    </Router>
  );
};
export default RootRouter;

import React from 'react';

import VirtualScroller from '../VirtualScroller';
import './dataTable.css';

const DataTable = ({ weatherData }) => {
  return (
    <table className="fixed_header">
      <thead>
        <tr className="table-head">
          <th colSpan={10}>
            <h3>Weather Data Viewer</h3>
          </th>
        </tr>
      </thead>
      <thead className="data-table-head">
        <tr className="data-table-row">
          <th>Date</th>
          <th>
            DHI
            <div className="subtitle">
              W/m<sup>2</sup>
            </div>
          </th>
          <th>
            DNI
            <div className="subtitle">
              W/m<sup>2</sup>
            </div>
          </th>
          <th>
            GHI
            <div className="subtitle">
              W/m<sup>2</sup>
            </div>
          </th>
          <th>
            Wind Speed
            <div className="subtitle">m/s</div>
          </th>
          <th>
            Temperature
            <div className="subtitle">°C</div>
          </th>
        </tr>
      </thead>
      <VirtualScroller data={weatherData} />
    </table>
  );
};

export default DataTable;

import React, { useState } from 'react';
import './VirtualScroller.css';

const VirtualScroller = ({ data }) => {
  const rowHeight = 35;
  const visibleRows = 15;

  const [start, setStart] = useState(0);

  const getTopHeight = () => {
    return rowHeight * start;
  };

  const getBottomHeight = () => {
    if (start + visibleRows >= data?.length) return 0;
    return data?.length ? rowHeight * (data?.length - (start + visibleRows)) : 0;
  };

  const onScroll = (e) => {
    setStart(Math.floor(e.target.scrollTop / rowHeight));
  };

  return (
    <tbody
      className="scroller-body"
      onScroll={onScroll}
      style={{ height: rowHeight * visibleRows }}
    >
      {getTopHeight() !== 0 && (
        <tr>
          <td className="invisible-cell" style={{ height: getTopHeight() }} />
        </tr>
      )}
      {data?.length &&
        data
          ?.slice(start, start + visibleRows + 1)
          .map(({ date, dhi, dni, ghi, id, temperature, windSpeed }) => (
            <tr key={id} style={{ height: rowHeight }} className="data-table-row">
              <td>
                {new Date(date * 1000).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  timeZone: 'UTC',
                })}
              </td>
              <td>{dhi}</td>
              <td>{dni}</td>
              <td>{ghi}</td>
              <td>{windSpeed}</td>
              <td>{temperature}</td>
            </tr>
          ))}
      {getBottomHeight() !== 0 && (
        <tr>
          <td className="invisible-cell" style={{ height: getBottomHeight() }} />
        </tr>
      )}
    </tbody>
  );
};

export default VirtualScroller;

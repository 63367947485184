export const chartConfig = {
  credits: {
    enabled: false,
  },
  colors: [
    '#7F7F7F',
    '#D9D9D9',
    '#FDDAAB',
    '#FFC000',
    '#FF9B15',
    '#F58C4B',
    '#D75607',
    '#EB6603',
    '#FFAB40',
    '#FFCC89',
    '#BFBFBF',
    '#595959',
  ],
  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    y: 30,
    itemMarginBottom: 15,
    useHTML: true,
  },
  yAxis: {
    min: 0,
    allowDecimals: true,
    tickmarkPlacement: 'on',
    gridLineWidth: 1,
    startOnTick: true,
    endOnTick: true,
  },
  xAxis: {
    min: 0,
    allowDecimals: true,
    tickmarkPlacement: 'on',
    gridLineWidth: 1,
    startOnTick: true,
    endOnTick: true,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
};

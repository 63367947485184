import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Confirm from '../Confirm';
import Service from '../../../Services/Service';
import { UserContext } from '../../../context/userContext';
import { square, formatNumber } from '../../../utils/helpers';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { TOAST_TYPES } from '../../../utils/constants';

import './card.css';

const Card = ({ data, type, setList, setLoading, showToast }) => {
  const history = useHistory();
  const confirmRef = useRef();
  const [isOpen, setOpen] = useState(false);
  const { userInfo } = useContext(UserContext);
  useOnClickOutside(confirmRef, () => setOpen(false));
  const {
    id,
    dateRun,
    dropDownName,
    type: equipmentType,
    arrayType,
    tableCount,
    user,
    attributes,
    results,
    inProgress,
    localId,
  } = data;
  // eslint-disable-next-line no-shadow
  const handleDelete = async (id, type) => {
    await setLoading(true);
    const response = await Service.deleteEquipment(id, type);
    if (response.status === 200) {
      await setList((prev) => prev.filter((item) => item?.id !== id));
    } else {
      showToast('Please try again', TOAST_TYPES.error);
    }
    await setLoading(false);
  };
  return (
    <>
      {isOpen && (
        <Confirm
          onAccept={() => handleDelete(id, type)}
          onDecline={() => {
            setOpen(false);
          }}
          message={`Confirm deleting ${dropDownName}?`}
          confirmRef={confirmRef}
        />
      )}
      <div
        className={`card ${!inProgress ? 'card-active' : 'card-disabled'}`}
        role="presentation"
        onClick={!inProgress ? () => history.push(`${type}/${id}`) : null}
        key={id}
        title={dropDownName}
      >
        <div className="card-inner">
          <div className="card-header">
            <div>
              <img src={`/Icons/${equipmentType}-Box.svg`} alt={equipmentType} />
            </div>
            <div className="card-info-section">
              <h1>{dropDownName}</h1>
              <div className="card-user-info-section">
                <div>{user}</div>
                <div>
                  {new Date(dateRun * 1000).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })}
                </div>
                <div>
                  {(type.charAt(0).toUpperCase() + type.slice(1)).slice(0, -1)} ID: {localId}
                </div>
              </div>
            </div>
          </div>
          {!inProgress && equipmentType === 'Octavia' && (
            <div>
              <h3>Shading Scene Overview:</h3>
              <div className="card-results">
                <div className="shading-data">
                  {arrayType[0].toUpperCase() + arrayType.slice(1)}
                </div>
                <div className="shading-data">{formatNumber(tableCount)} Tables</div>
              </div>
            </div>
          )}
          {!inProgress && equipmentType === 'EnergyModel' && (
            <div>
              <h3>Results Overview:</h3>
              <div className="card-results">
                <div>{formatNumber(results?.specificYield)}</div>
                <div>{formatNumber(results?.wh)}</div>
              </div>
            </div>
          )}
          <hr className="divider" />

          <div className="card-details">
            {!inProgress ? (
              Object.keys(attributes).map((title) => (
                <div key={title} className="card-details-info">
                  <div className="card-details-inner">
                    <h3 className="card-details-heading">
                      {title !== 'generalParameters'
                        ? title
                            .toString()
                            .replace('_', '')
                            .split(/(?=[A-Z])/)
                            .reduce(
                              (a, b) =>
                                a[a.length - 1] !== a[a.length - 1]?.toUpperCase() ||
                                (a[a.length - 1] === a[a.length - 1]?.toUpperCase() &&
                                  b[1] !== b[1]?.toUpperCase())
                                  ? `${a} ${b}`
                                  : a + b,
                              ''
                            )
                            .concat(':')
                        : false}
                    </h3>
                  </div>
                  {Object.entries(attributes[title]).map(([key, value]) => (
                    <div className="card-details-item" key={key}>
                      <div className="card-details-item-inner">
                        <div className="card-details-key">
                          {key
                            .replace('_', '')
                            .split(/(?=[A-Z])/)
                            .reduce(
                              (a, b) =>
                                a[a.length - 1] !== a[a.length - 1]?.toUpperCase() ||
                                (a[a.length - 1] === a[a.length - 1]?.toUpperCase() &&
                                  b[1] !== b[1]?.toUpperCase())
                                  ? `${a} ${b}`
                                  : a + b,
                              ''
                            )}
                          :
                        </div>
                        <div
                          style={{
                            color: key === 'Bifaciality' ? 'orange' : 'black',
                          }}
                        >
                          {value &&
                          key !== 'Module' &&
                          key !== 'Inverter' &&
                          key !== 'Manufacturer' &&
                          key !== 'Model'
                            ? square(formatNumber(value))
                            : String(value)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <div className="card-text">
                {type !== 'shading'
                  ? type[0].toUpperCase() + type.slice(1, type.length - 1)
                  : 'Octavia'}{' '}
                is in progress. It may take up to 5 minutes
              </div>
            )}
          </div>
        </div>
        {userInfo?.workspaceRole !== 'USER' && (
          <button
            type="button"
            className="cancel-button"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          >
            Delete
          </button>
        )}
      </div>
    </>
  );
};

export default Card;

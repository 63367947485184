export const mapConfig = {
  zoom: 9,
  defaultMapTypeId: 'hybrid',
  defaultCenter: {
    lat: 0,
    lng: 0,
  },
  options: {
    mapId: 'baebf4a80866824e',
    fullscreenControl: false,
    navigationControl: false,
    markerAttribs: ['autofocus'],
  },
};

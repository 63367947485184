import React from 'react';

import Home from '../Pages/HomePage';
import LocationsListPage from '../Pages/LocationsListPage';
import ModulesPage from '../Pages/ModulesPage';
import ProductionModelUploadPage from '../Pages/ProductionModelUploadPage';
import InvertersPage from '../Pages/InvertersPage';
import LocationsPage from '../Pages/LocationsPage';
import LocationsUploadPage from '../Pages/LocationsUploadPage/LocationsUploadPage';
import ModulesListPage from '../Pages/ModulesListPage';
import ModulesUploadPage from '../Pages/ModulesUploadPage';
import UserSettingsPage from '../Pages/UserSettingsPage';
import ProductionModelListPage from '../Pages/ProductionModelListPage';
import InvertersListPage from '../Pages/InvertersListPage/InvertersListPage';
import InvertersUploadPage from '../Pages/InvertersUploadPage';
import DashboardPage from '../Pages/DashboardPage';
import EnergyModelPage from '../Pages/EnergyModelPage';
import ShadingSceneUploadPage from '../Pages/ShadingSceneUploadPage';
import ShadingSceneListPage from '../Pages/ShadingSceneListPage';
import ShadingScenePage from '../Pages/ShadingScenePage';

// export const API_URL = `https://pdapi-250221.appspot.com`;
// export const API_URL_DEV = `https://dev.dalyenergy.com`;
export const HASH_MAP_KEY = `AIzaSyBN58SsxIwN3D1zrzzBOgCtVX3xxLbt7w4`;
export const MAP_ID = 'baebf4a80866824e';
export const MAP_LIBRARIES = ['places', 'geometry', 'drawing'];

export const persistentMenuItems = [
  {
    eventKey: '/energyModels',
    path: '/energyModels',
    title: 'Energy Model',
    image: 'Model.svg',
  },
  {
    eventKey: '/locations',
    path: '/locations',
    title: 'Locations',
    image: 'Location.svg',
  },
  {
    eventKey: '/modules',
    path: '/modules',
    title: 'Modules',
    image: 'Module.svg',
  },
  {
    eventKey: '/inverters',
    path: '/inverters',
    title: 'Inverters',
    image: 'Inverter.svg',
  },
  {
    eventKey: 'shading',
    path: '/shading',
    title: 'Shading Scene',
    image: 'Octavia.svg',
  },
];

export const routes = [
  {
    path: '/home',
    component: <Home />,
  },
  {
    path: '/dashboard',
    component: <DashboardPage />,
  },
  {
    path: '/locations',
    component: <LocationsListPage />,
  },
  {
    path: '/locations/:id',
    component: <LocationsPage />,
  },
  {
    path: '/locations-upload',
    component: <LocationsUploadPage />,
  },
  {
    path: '/modules',
    component: <ModulesListPage />,
  },
  {
    path: '/modules/:id',
    component: <ModulesPage />,
  },
  {
    path: '/modules-upload',
    component: <ModulesUploadPage />,
  },
  {
    path: '/inverters',
    component: <InvertersListPage />,
  },
  {
    path: '/inverters/:id',
    component: <InvertersPage />,
  },
  {
    path: '/inverters-upload',
    component: <InvertersUploadPage />,
  },
  {
    path: '/energyModels',
    component: <ProductionModelListPage />,
  },
  {
    path: '/energyModels/:id',
    component: <EnergyModelPage />,
  },
  {
    path: '/energyModels-upload',
    component: <ProductionModelUploadPage />,
  },
  {
    path: '/settings',
    component: <UserSettingsPage />,
  },
  {
    path: '/shading',
    component: <ShadingSceneListPage />,
  },
  {
    path: '/shading-upload',
    component: <ShadingSceneUploadPage />,
  },
  {
    path: '/shading/:id',
    component: <ShadingScenePage />,
  },
];

export const TOAST_TYPES = {
  error: 'error',
  warning: 'warning',
  success: 'success',
  info: 'info',
};
export const UPLOAD_TYPES = {
  FILE: 'provided',
  SOLAR_ANYWHERE: 'solaranywhere',
  NSRDB: 'nsrdb',
};
export const DATA_TYPES = {
  TGY: {
    title: 'TGY',
    years: [2017, 2018, 2019, 2020],
  },
  TDY: {
    title: 'TDY',
    years: [2017, 2018, 2019, 2020],
  },
  PSMV3: {
    title: 'Physical Solar Model V3',
    years: [
      1998, 1999, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013,
      2014, 2015, 2016, 2017, 2018, 2019, 2020,
    ],
  },
  PSMV3_SUBHOURLY: {
    title: 'Subhourly Physical Solar Model V3',
    years: [2018, 2019, 2020],
  },
};
export const initialCoords = {
  latitude: 37.916677,
  longitude: -122.556979,
};

import * as Yup from 'yup';
import { DATA_TYPES } from '../../../utils/constants';

export const assumptionInitialValues = {
  uploadType: '',
  dataType: '',
  year: '',
  interval: '',
};

export const assumptionsSchema = Yup.object().shape({
  uploadType: Yup.string().required('Please select Upload Type'),

  dataType: Yup.string().when('uploadType', {
    is: 'nsrdb',
    then: Yup.string().required('Please select Data Type'),
    otherwise: Yup.string(),
  }),
  year: Yup.number().when('uploadType', {
    is: 'nsrdb',
    then: Yup.number().required('Please select the Year'),
    otherwise: Yup.number(),
  }),
  interval: Yup.number().when(['uploadType', 'dataType'], {
    is: (uploadType, dataType) =>
      uploadType === 'nsrdb' && DATA_TYPES[dataType] === DATA_TYPES.PSMV3_SUBHOURLY,
    then: Yup.number().required('Please select the Interval'),
    otherwise: Yup.number(),
  }),
});

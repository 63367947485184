import React from 'react';

import Tooltip from '../Tooltip';
import './inputField.css';

const InputField = ({
  value,
  label,
  name,
  id,
  placeholder,
  type,
  isPercentage,
  formikData,
  className,
  tooltipContent,
  tooltipDirection,
  onChange,
  disabled,
}) => {
  const { values, touched, errors, handleChange, handleBlur } = formikData;
  const inputClassName =
    className !== undefined
      ? className
      : `${
          (errors.api && 'input-error') || (errors[name] && touched[name] && 'input-error') || ''
        }`;

  return (
    <>
      {label && (
        <div className="label-wrapper">
          <label className="label" htmlFor="input-field">
            {label}
          </label>
          <Tooltip content={tooltipContent} direction={tooltipDirection} />
        </div>
      )}
      <div className="input-wrapper">
        <input
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          value={value !== undefined ? value : values[name]}
          className={isPercentage ? `${inputClassName} pr-20` : inputClassName}
          onChange={(e) => (onChange ? onChange(e) : handleChange(e))}
          onBlur={handleBlur}
          disabled={disabled}
        />
        {isPercentage && <span className="percentage">%</span>}
      </div>
      {errors && errors[name] && touched && touched[name] && (
        <div className="input-feedback">{errors[name]}</div>
      )}
      {errors?.api && <div className="input-feedback">{errors?.api}</div>}
    </>
  );
};

export default InputField;

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import axiosConfig from '../../utils/axiosConfig';

import Loader from '../../Components/Shared/Loader';
import Error from '../../Components/Shared/Error';
import InputField from '../../Components/Shared/InputField';

import './invertersUploadPage.css';

const InvertersUploadPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [file] = useState(new FormData());
  const [fileName, setFileName] = useState('');

  useEffect(() => () => setLoading(false), []);

  const handleChangeFile = (e) => {
    setFileName(e.target.value.split(/(\\|\/)/g).pop());
    file.append('file', e.target.files[0]);
  };

  const handleUploadInverter = async (inverterName) => {
    file.append('name', inverterName.trim());
    await setLoading(true);
    await axiosConfig
      .post(`/api/equipment/inverters/upload`, file)
      .then((response) =>
        response.status !== 200 ? setLoading(true) : history.push(`/inverters/${response.data}`)
      )
      .catch((err) => {
        setError(err.type !== undefined ? err : err);
        setLoading(false);
      });
    await setLoading(false);
  };
  if (error) return <Error />;
  return loading ? (
    <Loader />
  ) : (
    <Formik
      initialValues={{ inverterName: '' }}
      validationSchema={Yup.object().shape({
        inverterName: Yup.string()
          .required('No Inverter Name provided.')
          .min(1, 'Inverter Name is too short - should be 1 char minimum.')
          .max(50, 'Inverter Name is too long - should be 50 chars maximum.')
          .matches(/^\S/, 'Inverter Name should not start from space.'),
      })}
    >
      {(props) => {
        const { values, errors } = props;
        return (
          <div id="upload-inverters-page">
            <div id="upload-box">
              <h2 className="title">New Inverter</h2>
              <div className="upload-details">
                <form>
                  <input
                    type="file"
                    id="upload"
                    accept={['.OND', '.txt']}
                    hidden
                    onChange={handleChangeFile}
                  />
                  <InputField
                    type="text"
                    name="inverterName"
                    id="file-name"
                    label="Inverter Name"
                    placeholder="Enter inverter name here"
                    tooltipContent="Field is required, length should be up to 50 chars"
                    formikData={props}
                  />
                  <div>
                    <button
                      className="select-button"
                      type="button"
                      onClick={() => document.getElementById('upload').click()}
                    >
                      + Select .OND or .TXT File
                    </button>
                  </div>
                  <input
                    type="text"
                    className="file-name"
                    hidden={!fileName}
                    value={fileName}
                    disabled
                  />
                  <div className="inverters-manage">
                    <button
                      className="upload-button"
                      disabled={!fileName || !values.inverterName || errors.inverterName}
                      type="button"
                      onClick={() => handleUploadInverter(values.inverterName)}
                    >
                      Create new Inverter
                    </button>
                    <button
                      className="cancel-button"
                      type="button"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default InvertersUploadPage;

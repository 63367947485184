import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '../../Components/Shared/Loader';
import Error from '../../Components/Shared/Error';
import { useShadingSceneData } from '../../hooks/useShadingSceneData';
import { formatNumber, capitalizeFirstLetter } from '../../utils/helpers';
import { UserContext } from '../../context/userContext';

import './shadingScenePage.css';

const ShadingScenePage = (props) => {
  const { id } = useParams();
  const { id: propsId } = props;
  const { userInfo } = useContext(UserContext);
  const { userKey, workspaceKey } = userInfo || {};

  const { data: shadingSceneData, isLoading, error } = useShadingSceneData(propsId || id);
  const { tables, details, dropDownName } = shadingSceneData || {};
  const { diffuseShadingFactorsTable, directShadingFactorsTable } = tables || {};
  const { elevations: diffuseShading } = diffuseShadingFactorsTable || {};
  const { elevations: directShading } = directShadingFactorsTable || {};
  const {
    sceneSource,
    numberOfTables,
    numberOfObstruction,
    gcr,
    azimuth,
    backtracking,
    terrainAwareTracking,
    terrainAwareDiodeThreshold,
    maxAngle,
    arrayType,
  } = details || {};

  if (error) return <Error />;

  return isLoading ? (
    <Loader />
  ) : (
    <div className="shading-content">
      <div className="shading-content-description">
        <div className="properties-section">
          <div className="properties-section-title">
            <div
              className="equipment-img"
              style={{ backgroundImage: 'url(/Icons/Octavia-Box.svg)' }}
            />
            <div>{dropDownName}</div>
          </div>
          <hr className="divider" />
          <div>
            <div>Scene Source</div> <div>{sceneSource}</div>
          </div>
          <br />
          <div className="properties-section-heading">
            <h3>Scene Properties</h3>
          </div>
          <div>
            <div>Number of Tables</div>
            <div>{formatNumber(numberOfTables)}</div>
          </div>
          <div>
            <div>Number of Obstructions</div>
            <div>{formatNumber(numberOfObstruction)}</div>
          </div>
          <br />
          {arrayType === 'Tracker' && (
            <>
              <div className="properties-section-heading">
                <h3>Tracking Parameters</h3>
              </div>
              <div>
                <div>GCR</div>
                <div>{gcr}</div>
              </div>
              <div>
                <div>Azimuth</div>
                <div>{azimuth}°</div>
              </div>
              <div>
                <div>Backtracking</div>
                <div>{capitalizeFirstLetter(backtracking.toString())}</div>
              </div>
              <div>
                <div>Max Angle</div>
                <div>{maxAngle}°</div>
              </div>
              <div>
                <div>Terrain Aware Tracking</div>
                <div>{capitalizeFirstLetter(terrainAwareTracking?.toString())}</div>
              </div>
              {terrainAwareDiodeThreshold !== undefined && (
                <div>
                  <div>Terrain Aware Diode Threshold</div>
                  <div>{capitalizeFirstLetter(terrainAwareDiodeThreshold?.toString())}</div>
                </div>
              )}
            </>
          )}
        </div>
        <a
          className="w-100"
          href={`https://api.dalyenergy.com/octavia/sceneviewer?shadingsceneid=${id}&workspace=${workspaceKey}&user=${userKey}&frontend=true`}
          type="button"
          target="_blank"
          rel="noreferrer"
        >
          View Shading Scene
        </a>
      </div>
      <div className="w-100">
        <div className="properties-section shading-properties-inner">
          <div className="properties-section-heading">
            <h3>Diffuse Sky Shading Fraction</h3>
          </div>
          <div className="table-container">
            <table>
              <tbody>
                <tr>
                  <td className="cell-title">Sun Elevation:</td>
                </tr>
                {diffuseShading
                  ?.sort((a, b) => b.elevation - a.elevation)
                  .map(({ elevation, azimuths }) => (
                    <tr key={elevation}>
                      <td>{elevation}</td>
                      {azimuths.map((azimuthValue) => (
                        <td key={Math.random() * 10000}>
                          {Math.round(azimuthValue.percentage * 1000) / 1000}
                        </td>
                      ))}
                    </tr>
                  ))}
                <tr>
                  <td className="cell-title">Sun Azimuth:</td>
                  {diffuseShading &&
                    diffuseShading[0].azimuths.map(({ azimuth: diffuseAzimuth }) => {
                      return <td key={diffuseAzimuth}>{diffuseAzimuth}</td>;
                    })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="properties-section shading-properties-inner">
          <div className="properties-section-heading">
            <h3>Direct (Beam) Shading Fraction</h3>
          </div>
          <div className="table-container">
            <table>
              <tbody>
                <tr>
                  <td className="cell-title">Sun Elevation:</td>
                </tr>
                {directShading
                  ?.sort((a, b) => b.elevation - a.elevation)
                  .map(({ elevation, azimuths }) => (
                    <tr key={elevation}>
                      <td>{elevation}</td>
                      {azimuths.map((azimuthValue) => (
                        <td key={Math.random() * 10000}>
                          {Math.round(azimuthValue.percentage * 1000) / 1000}
                        </td>
                      ))}
                    </tr>
                  ))}
                <tr>
                  <td className="cell-title">Sun Azimuth:</td>
                  {directShading &&
                    directShading[0].azimuths.map(({ azimuth: directAzimuth }) => {
                      return <td key={directAzimuth}>{directAzimuth}</td>;
                    })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShadingScenePage;

import React, { useState } from 'react';
import { GoogleMap, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';

import { mapConfig } from '../../../utils/mapConfig';
import { HASH_MAP_KEY, MAP_ID, MAP_LIBRARIES } from '../../../utils/constants';
import Loader from '../Loader';
import MarkerWithInfoWindow from '../MarkerWithInfoWindow';

import './maps.css';

const LocationMap = (props) => {
  const [map, setMap] = useState(null);
  const { options, data, infoWindowContent, handleSelectLocation, handleChangeZoom } = props || {};
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: HASH_MAP_KEY,
    mapIds: [MAP_ID],
    libraries: MAP_LIBRARIES,
    language: 'en',
  });
  if (isLoaded) {
    return (
      <GoogleMap
        /* eslint-disable react/jsx-props-no-spreading */
        {...mapConfig}
        {...options}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={options.center}
        onLoad={(currentMap) => setMap(currentMap)}
        onZoomChanged={() => {
          if (handleChangeZoom && map?.getZoom) {
            handleChangeZoom(map?.getZoom());
          }
        }}
      >
        <MarkerClusterer
          averageCenter
          maxZoom={7}
          styles={[
            {
              url: '/Icons/Cluster.svg',
              height: 50,
              width: 50,
              textColor: '#000',
            },
          ]}
        >
          {(clusterer) => {
            return data?.length
              ? data?.map((marker) => {
                  const {
                    dropDownName,
                    locationName,
                    id,
                    latitude,
                    longitude,
                    dateRun,
                    totalGhi,
                    elevation,
                    onClickFn,
                  } = marker || {};

                  return (
                    <MarkerWithInfoWindow
                      key={id || dropDownName || locationName}
                      clusterer={clusterer}
                      position={{
                        lat: parseFloat(latitude),
                        lng: parseFloat(longitude),
                      }}
                      icon="https://maps.google.com/mapfiles/ms/icons/orange-dot.png"
                      // label={dropDownName || locationName}
                      onClick={
                        onClickFn || handleSelectLocation
                          ? () => handleSelectLocation(marker)
                          : null
                      }
                      content={
                        <div className="infoWindow">
                          {infoWindowContent?.includes('name') && (
                            <div>{dropDownName || locationName || 'New Location'}</div>
                          )}
                          {infoWindowContent?.includes('date') && (
                            <div>
                              {new Date(dateRun * 1000).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                              })}
                            </div>
                          )}
                          {infoWindowContent?.includes('GHI') && (
                            <div>
                              Total GHI: {totalGhi} kWh/m<sup>2</sup>
                            </div>
                          )}
                          {infoWindowContent?.includes('elevation') && (
                            <div>Elevation: {parseFloat(elevation)}</div>
                          )}
                        </div>
                      }
                    />
                  );
                })
              : null;
          }}
        </MarkerClusterer>
      </GoogleMap>
    );
  }
  return <Loader />;
};

export default LocationMap;

import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { useOptionsList } from '../../hooks/useOptionsList';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { UserContext } from '../../context/userContext';
import axiosConfig from '../../utils/axiosConfig';
import { DATA_TYPES, TOAST_TYPES, UPLOAD_TYPES } from '../../utils/constants';
import useToast from '../../hooks/useToast';

import Error from '../../Components/Shared/Error';
import Loader from '../../Components/Shared/Loader';
import Confirm from '../../Components/Shared/Confirm';
import InputField from '../../Components/Shared/InputField';
import Toast from '../../Components/Shared/Toast';

import './userSettingsPage.css';
import Assumptions from '../../Components/Shared/Assumptions';

const UserSettingsPage = () => {
  const { userInfo, setUserInfo } = useContext(UserContext);
  const { toastList, showToast } = useToast();
  const selectRef = useRef();
  const confirmRef = useRef();

  const {
    data: workspaceData,
    isLoading: loadingWorkspace,
    error: workspaceError,
  } = useOptionsList('/workspaces');

  const { firstName, lastName, imageUrl, email, currentWorkspace } = userInfo || {};
  const [workspaceList, setWorkspaceList] = useState([]);
  const [inviteList, setInviteList] = useState([]);
  const [userCurrentWorkspace, setUserCurrentWorkspace] = useState(currentWorkspace);
  const [inviteWorkspaceId, setInviteWorkspaceId] = useState('');
  const [userId, setUserId] = useState('');
  const [searchOption, setSearchOption] = useState('');
  const [loading, setLoading] = useState(loadingWorkspace);
  const [currentWorkspaceName, setCurrentWorkspaceName] = useState();
  const [currentMembersList, setCurrentMembersList] = useState([]);
  const [isLeaveWorkspaceOpen, setLeaveWorkspaceOpen] = useState(false);
  const [isDeclineInvitationOpen, setDeclineInvitationOpen] = useState(false);
  const [isRemoveMemberOpen, setRemoveMemberOpen] = useState(false);
  const [isRemoveWorkspaceOpen, setRemoveWorkspaceOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useOnClickOutside(confirmRef, () => {
    setLeaveWorkspaceOpen(false);
    setDeclineInvitationOpen(false);
    setRemoveMemberOpen(false);
    setRemoveWorkspaceOpen(false);
  });

  useEffect(() => {
    if (workspaceData?.usersList?.length) {
      setCurrentMembersList(workspaceData?.usersList);
      setCurrentWorkspaceName(workspaceData?.workspaceName);
    }
    setWorkspaceList(workspaceData?.workspaceList);
    setInviteList(workspaceData?.inviteList);
  }, [workspaceData]);

  useEffect(() => {
    setCurrentMembersList(
      workspaceData?.usersList?.filter(({ fullName }) =>
        fullName.toUpperCase().includes(searchOption.toString().toUpperCase())
      )
    );
  }, [searchOption]);

  useEffect(() => {
    setUserCurrentWorkspace(currentWorkspace);
  }, [currentWorkspace]);

  const updateWorkspaceName = async (workspaceName) => {
    try {
      await setLoading(true);
      const response = await axiosConfig.patch('api/workspaces/name', {
        name: workspaceName,
      });
      if (response?.status === 200) {
        await setCurrentWorkspaceName(response?.data?.name);
        await setWorkspaceList((prev) =>
          prev?.map((workspace) => {
            if (workspace.id === response?.data?.id) {
              return { ...workspace, name: response?.data?.name };
            }
            return workspace;
          })
        );
      }
      await setLoading(false);
    } catch (err) {
      await setLoading(false);
      showToast('Failed to update workspace name. Please try again', TOAST_TYPES.error);
    }
  };

  const removeUser = async (id) => {
    try {
      await setLoading(true);
      await setRemoveMemberOpen(false);
      const response = await axiosConfig.delete('api/workspaces/removeUser', {
        params: { id },
      });
      if (response?.status === 200) {
        await setCurrentMembersList((prev) => prev?.filter((member) => member.id !== id));
      }
      await setLoading(false);
    } catch (err) {
      await setLoading(false);
      showToast('Failed to remove user. Please try again', TOAST_TYPES.error);
    }
  };

  const handleChangeRole = async (id, e) => {
    try {
      await setLoading(true);
      const response = await axiosConfig.patch('api/workspaces/role', {
        userId: id,
        role: e.target.value,
      });
      if (response?.status === 200) {
        await setCurrentMembersList((prev) =>
          prev?.map((member) => {
            if (member.id === id) {
              return { ...member, role: e.target.value };
            }
            return member;
          })
        );
      }
      await setLoading(false);
    } catch (err) {
      await setLoading(false);
      showToast('Failed to change role. Please try again', TOAST_TYPES.error);
    }
  };

  const deleteWorkspace = async () => {
    try {
      await setLoading(true);
      await setRemoveWorkspaceOpen(false);
      await axiosConfig.delete('api/workspaces/remove');
      await setUserInfo({ ...userInfo, currentWorkspace: 'null' });
      await setLoading(false);
    } catch (err) {
      await setLoading(false);
      showToast('Failed to delete workspace. Please try again', TOAST_TYPES.error);
    }
  };

  const createWorkspace = async (workspaceName) => {
    await setLoading(true);
    const response = await axiosConfig
      .post('/api/workspaces/create', { name: workspaceName.trim() })
      .catch(() => {
        showToast('Failed to create workspace. Please try again', TOAST_TYPES.error);
        setLoading(false);
      });
    if (response?.status === 200) {
      await setWorkspaceList(response?.data);
      showToast('Workspace has been created', TOAST_TYPES.success);
    }
    await setLoading(false);
  };
  const leaveWorkspace = async () => {
    await setLoading(true);
    const response = await axiosConfig.delete('/api/workspaces/leave').catch(() => {
      showToast('Failed to leave workspace. Please try again', TOAST_TYPES.error);
      setLoading(false);
    });
    if (response?.status === 200) {
      await setWorkspaceList(response?.data?.workspaceList);
      await setUserInfo({ ...userInfo, currentWorkspace: 'null' });
    }
    await setLoading(false);
    await setLeaveWorkspaceOpen(false);
  };
  const sendInvite = async (inviteEmail) => {
    await setLoading(true);
    const response = await axiosConfig
      .post('/api/smtp/sendInvitation', { email: inviteEmail })
      .catch(() => {
        showToast('Failed to send invitation link. Please try again', TOAST_TYPES.error);
        setLoading(false);
      });
    if (response?.status === 200) {
      showToast('Invitation link has been sent', TOAST_TYPES.success);
    } else if (response?.status === 208) {
      showToast('Invitation link has been sent previously', TOAST_TYPES.info);
    } else {
      showToast('Failed to send invitation link. Please try again', TOAST_TYPES.error);
    }
    await setLoading(false);
  };

  const changeCurrentWorkspace = async () => {
    await setLoading(true);
    const response = await axiosConfig
      .put('/api/user/setCurrentWorkspace', { id: +userCurrentWorkspace })
      .catch(() => {
        showToast('Failed to change workspace. Please try again', TOAST_TYPES.error);
        setLoading(false);
      });
    if (response?.status === 200) {
      await setUserInfo(response?.data);
    }
    await setLoading(false);
  };

  const resolveInvite = async (answer, workspaceId) => {
    await setLoading(true);
    const response = await axiosConfig
      .post('/api/workspaces', {
        workspaceId,
        option: answer,
      })
      .catch(() => {
        showToast(
          `Failed to ${answer === false ? 'decline' : 'accept'} invitation. Please try again`,
          TOAST_TYPES.error
        );
        setLoading(false);
      });
    if (response?.status === 200) {
      await setInviteList(response?.data?.inviteList);
      await setWorkspaceList(response?.data?.workspaceList);
      showToast(`Invitation ${answer === false ? 'declined' : 'accepted'}`, TOAST_TYPES.success);
    }
    await setDeclineInvitationOpen(false);
    await setLoading(false);
  };

  if (loading || loadingWorkspace) {
    return <Loader />;
  }
  return (
    <Formik
      initialValues={{
        inviteEmail: '',
        workspaceName: '',
        newWorkspaceName: currentWorkspaceName || workspaceData?.workspaceName,
      }}
      validationSchema={Yup.object().shape({
        inviteEmail: Yup.string()
          .required('No email provided.')
          .min(3, 'Email is too short - should be 3 chars minimum.')
          .max(50, 'Email is too long - should be 50 chars maximum.')
          .matches(
            /^([0-9a-z]([+\-_.]*[0-9a-z]){4,49})+@(([0-9a-z][-\w]*[0-9a-z]*\.)+[a-z0-9]{2,17})$/,
            'Invalid email address'
          ),
        workspaceName: Yup.string()
          .required('No Workspace Name provided.')
          .min(1, 'Workspace Name is too short - should be 1 char minimum.')
          .max(50, 'Workspace Name is too long - should be 50 chars maximum.')
          .matches(/^\S/, 'Workspace Name should not start from space.'),
        newWorkspaceName: Yup.string()
          .required('No Workspace Name provided.')
          .min(1, 'Workspace Name is too short - should be 1 char minimum.')
          .max(50, 'Workspace Name is too long - should be 50 chars maximum.')
          .matches(/^\S/, 'Workspace Name should not start from space.'),
      })}
    >
      {(props) => {
        const { values, touched, errors, resetForm } = props;

        return workspaceError ? (
          <Error />
        ) : (
          <>
            {isLeaveWorkspaceOpen && (
              <Confirm
                onAccept={leaveWorkspace}
                onDecline={() => {
                  setLeaveWorkspaceOpen(false);
                }}
                message={`Confirm leaving ${
                  workspaceList?.filter((el) => +el.id === +currentWorkspace)[0].name
                }?`}
                confirmRef={confirmRef}
              />
            )}
            {isDeclineInvitationOpen && (
              <Confirm
                onAccept={() => resolveInvite(false, inviteWorkspaceId)}
                onDecline={() => {
                  setDeclineInvitationOpen(false);
                }}
                message={`Confirm declining invitation to ${
                  inviteList?.filter((el) => +el.workspaceId === +inviteWorkspaceId)[0]
                    .workspaceName
                }?`}
                confirmRef={confirmRef}
              />
            )}
            {isRemoveWorkspaceOpen && (
              <Confirm
                onAccept={deleteWorkspace}
                onDecline={() => {
                  setRemoveWorkspaceOpen(false);
                }}
                message={`Confirm deleting ${
                  workspaceList?.filter((el) => +el.id === +currentWorkspace)[0].name
                }?`}
                confirmRef={confirmRef}
              />
            )}
            {isRemoveMemberOpen && (
              <Confirm
                onAccept={() => removeUser(userId)}
                onDecline={() => {
                  setRemoveMemberOpen(false);
                }}
                message={`Confirm removing ${
                  currentMembersList.filter((el) => +el.id === +userId)[0].fullName
                } from ${workspaceList?.filter((el) => +el.id === +currentWorkspace)[0].name}?`}
                confirmRef={confirmRef}
              />
            )}
            <Toast toastList={toastList} />
            <div id="user-settings-page">
              <div className="left-side">
                <div className="box">
                  <h2>Workspace Settings</h2>
                  <div className="user-photo">
                    {imageUrl ? (
                      <img src={imageUrl} alt={`${firstName} ${lastName}`} />
                    ) : (
                      <div className="user-mock">
                        {userInfo && firstName[0].toUpperCase() + lastName[0].toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="info-box">
                    <div className="info">
                      <div>Email:</div>
                      <input type="text" className="input-field" disabled value={email} />
                    </div>
                    <div className="info">
                      <div>Full Name:</div>
                      <input
                        type="text"
                        className="input-field"
                        disabled
                        value={`${firstName} ${lastName}`}
                      />
                    </div>
                  </div>
                  <div className="info-workspace">
                    <h2>Current Workspace:</h2>
                    <select
                      ref={selectRef}
                      value={userCurrentWorkspace || undefined}
                      className="workspace-select"
                      disabled={!workspaceList?.length}
                      onChange={(e) => setUserCurrentWorkspace(+e.target.value)}
                    >
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                      <option hidden={userCurrentWorkspace} />
                      {workspaceList?.map(({ id, name: workspaceOptionName }) => (
                        <option key={id} value={id} name={workspaceOptionName}>
                          {workspaceOptionName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="user-buttons">
                    <button
                      type="button"
                      disabled={
                        +currentWorkspace === +userCurrentWorkspace ||
                        userCurrentWorkspace === 'null'
                      }
                      onClick={changeCurrentWorkspace}
                    >
                      Change Workspace
                    </button>
                    <button
                      type="button"
                      className="cancel-button"
                      disabled={userInfo?.currentWorkspace === 'null'}
                      onClick={() => setLeaveWorkspaceOpen(true)}
                    >
                      Leave Current Workspace
                    </button>
                  </div>
                </div>
                <hr className="divider" />
                {!!Number(currentWorkspace) && (
                  <>
                    <section className="workspace-table-section">
                      <table className="fixed_header">
                        <thead>
                          <tr className="table-head">
                            <th>
                              <h2>Current Workspace Users:</h2>
                            </th>
                          </tr>
                        </thead>
                        <thead>
                          <tr className="table-head">
                            <th>
                              <input
                                type="text"
                                value={searchOption}
                                onChange={(e) => setSearchOption(e.target.value)}
                                placeholder="&#xF002; Search"
                                className="search-input"
                              />
                            </th>
                          </tr>
                        </thead>
                        {currentMembersList?.length ? (
                          <>
                            <thead>
                              <tr className="workspace-items">
                                <th className="w-10">ID</th>
                                <th className="workspace-email">Email</th>
                                <th>Full Name</th>
                                <th className="w-100">Role</th>
                                {userInfo?.workspaceRole !== 'USER' && <th>Actions</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {currentMembersList?.map(
                                ({ fullName, email: userEmail, id, role }) => (
                                  <tr key={id} className="workspace-row">
                                    <td className="w-10">{id}</td>
                                    <td className="workspace-email">{userEmail}</td>
                                    <td>{fullName}</td>
                                    <td className="w-100">
                                      {role === 'OWNER' || userInfo?.workspaceRole !== 'OWNER' ? (
                                        role
                                      ) : (
                                        <select
                                          name="role"
                                          id="role"
                                          defaultValue={role === 'ADMIN' ? 'ADMIN' : 'USER'}
                                          disabled={userInfo?.workspaceRole === 'USER'}
                                          onChange={(e) => handleChangeRole(id, e)}
                                        >
                                          <option value="ADMIN">ADMIN</option>
                                          <option value="USER">USER</option>
                                        </select>
                                      )}
                                    </td>
                                    {userInfo?.workspaceRole !== 'USER' && (
                                      <td>
                                        {userInfo?.workspaceRole !== role && role !== 'OWNER' && (
                                          <button
                                            type="button"
                                            className="cancel-button"
                                            onClick={() => {
                                              setRemoveMemberOpen(true);
                                              setUserId(id);
                                            }}
                                          >
                                            Remove
                                          </button>
                                        )}
                                      </td>
                                    )}
                                  </tr>
                                )
                              )}
                            </tbody>
                          </>
                        ) : (
                          <tbody>
                            <tr>
                              <td className="workspace-list-empty">No members found</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </section>
                    <div>
                      {userInfo?.workspaceRole === 'OWNER' && (
                        <button
                          type="button"
                          className="cancel-button"
                          onClick={() => setRemoveWorkspaceOpen(true)}
                        >
                          Delete Workspace
                        </button>
                      )}
                    </div>
                    {userInfo?.workspaceRole === 'OWNER' && (
                      <>
                        <hr className="divider" />
                        <div className="workspace-name">
                          <form
                            className="workspace-form"
                            onSubmit={() => updateWorkspaceName(values.newWorkspaceName)}
                          >
                            <h2>Update Workspace Name:</h2>
                            <InputField
                              type="text"
                              name="newWorkspaceName"
                              label="Workspace Name:"
                              tooltipContent="Field is required, length should be up to 50 chars, should not start with a space"
                              formikData={props}
                              placeholder="Enter workspace name here:"
                              className={`input-field ${
                                (errors.api && 'input-error') ||
                                (errors.newWorkspaceName &&
                                  touched.newWorkspaceName &&
                                  'input-error')
                              }`}
                            />
                            <button
                              type="submit"
                              disabled={
                                values.newWorkspaceName === currentWorkspaceName ||
                                errors.newWorkspaceName
                              }
                            >
                              Update
                            </button>
                          </form>
                        </div>
                      </>
                    )}
                    <hr className="divider" />
                  </>
                )}
                <form
                  className="user-form"
                  onSubmit={() => {
                    createWorkspace(values.workspaceName).then(() => resetForm());
                  }}
                >
                  <div className="box">
                    <h2>Create New Workspace:</h2>
                    <div className="info-box">
                      <InputField
                        name="workspaceName"
                        type="text"
                        label="Workspace Name:"
                        tooltipContent="Field is required, length should be up to 50 chars, should not start with a space"
                        placeholder="Enter workspace name here:"
                        formikData={props}
                        className={`user-input ${
                          (errors.api && 'input-error') ||
                          (errors.workspaceName && touched.workspaceName && 'input-error')
                        }`}
                      />
                    </div>
                    <button disabled={errors.workspaceName || !values.workspaceName} type="submit">
                      Create workspace
                    </button>
                  </div>
                </form>
                <hr className="divider" />
                {userInfo?.currentWorkspace !== 'null' && userInfo?.workspaceRole !== 'USER' && (
                  <form
                    className="user-form"
                    onSubmit={() => sendInvite(values.inviteEmail).then(() => resetForm())}
                  >
                    <div className="box">
                      <h2>Invite to current Workspace:</h2>
                      <div className="info-box">
                        <InputField
                          name="inviteEmail"
                          label="Send invitation to email:"
                          tooltipContent="Should be a valid email address"
                          type="text"
                          placeholder="Enter email here:"
                          formikData={props}
                          className={`user-input ${
                            (errors.api && 'input-error') ||
                            (errors.inviteEmail && touched.inviteEmail && 'input-error')
                          }`}
                        />
                      </div>
                      <button disabled={errors.inviteEmail || !values.inviteEmail} type="submit">
                        Send Invite
                      </button>
                    </div>
                    <hr className="divider" />
                  </form>
                )}
                <div className="box w-70">
                  <h2>Invitation List:</h2>
                  {inviteList?.length ? (
                    <div className="info-box">
                      {inviteList.map(
                        ({ workspaceName: name, fromUser, workspaceId, expireTime }) => (
                          <div key={workspaceId} className="invite">
                            <div className="invite-title">
                              <h4>{name}</h4>
                              <span>from</span>
                              <span className="user-invitation-from">{fromUser}</span>
                            </div>
                            <div>
                              Expire at:{' '}
                              {new Date(expireTime).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                              })}
                            </div>
                            <div>
                              <button
                                type="button"
                                onClick={() => resolveInvite(true, workspaceId)}
                              >
                                Accept
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setDeclineInvitationOpen(true);
                                  setInviteWorkspaceId(workspaceId);
                                }}
                                className="cancel-button"
                              >
                                Decline
                              </button>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <span>No invites yet...</span>
                  )}
                </div>
              </div>
              {isError && <Error />}
              {isLoading && <Loader />}
              {!isLoading && !isError && (
                <Assumptions
                  workspaceData={workspaceData}
                  setIsLoading={setIsLoading}
                  setIsError={setIsError}
                />
              )}
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default UserSettingsPage;

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { TOAST_TYPES } from '../../../utils/constants';

import './toast.css';

const Toast = ({ toastList, position = 'top-right', autoDelete = true, autoDeleteTime = 5000 }) => {
  const [list, setList] = useState(toastList);

  const deleteToast = (id) => {
    const toastListItem = toastList.findIndex((value) => value.id === id);
    toastList.splice(toastListItem, 1);
    const newList = list.filter((value) => {
      return value.id !== id;
    });
    setList([...newList]);
  };

  useEffect(() => {
    const newList = toastList.map((toast) => {
      switch (toast.type) {
        case TOAST_TYPES.error:
          return { ...toast, backgroundColor: '#d9534f' };
        case TOAST_TYPES.success:
          return { ...toast, backgroundColor: '#5cb85c' };
        case TOAST_TYPES.warning:
          return { ...toast, backgroundColor: '#f0ad4e' };
        default:
          return { ...toast, backgroundColor: '#5bc0de' };
      }
    });
    setList(newList);
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(list[0].id);
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDelete, autoDeleteTime, list]);

  return ReactDOM.createPortal(
    <div className={`notification-container ${position}`}>
      {list?.map((toast) => (
        <div
          key={toast.id}
          className={`notification toast ${position}`}
          style={{ backgroundColor: toast.backgroundColor }}
        >
          <button type="button" onClick={() => deleteToast(toast.id)}>
            X
          </button>
          <div className="notification-content">
            <div className="notification-image">
              <img
                src={`/Icons/${toast.type[0].toUpperCase() + toast.type.slice(1)}.svg`}
                alt="Toast icon"
              />
            </div>
            <div>
              <p className="notification-title">
                {toast.type[0].toUpperCase() + toast.type.slice(1)}
              </p>
              <p className="notification-message">{toast.message}</p>
            </div>
          </div>
        </div>
      ))}
    </div>,
    document.getElementById('app-modal')
  );
};

export default Toast;

import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from './context/userContext';
import { AuthContext } from './context/authContext';
import axiosConfig from './utils/axiosConfig';

import RootRouter from './utils/router';
import Loader from './Components/Shared/Loader';

import './index.css';

const App = () => {
  const { setUserInfo } = useContext(UserContext);
  const { isAuthenticated } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (isAuthenticated) {
      try {
        await setLoading(true);
        const response = await axiosConfig.get(`/api/getCurrentUser`);
        await setUserInfo(response.data);
        await setLoading(false);
      } catch (err) {
        setLoading(false);
        throw new Error(err);
      }
    }
  }, []);
  return <div className="App">{loading ? <Loader /> : <RootRouter />}</div>;
};

export default App;

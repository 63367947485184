import React, { createContext, useState } from 'react';

export const AuthContext = createContext(false);

const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(localStorage.getItem('isAuthenticated'));
  return (
    <AuthContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={{
        isAuthenticated,
        setAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

import React from 'react';
import { useHistory } from 'react-router-dom';

import Logout from '../Logout';
import './userBox.css';

const UserBox = ({ user }) => {
  const { firstName, lastName, imageUrl } = user || {};
  const history = useHistory();
  return (
    <div className="user-box">
      {user && (
        <>
          <div className="user-box-inner">
            {imageUrl ? (
              <img className="profile-photo" src={imageUrl} alt={`${firstName} ${lastName}`} />
            ) : (
              <div className="user-mock">
                {user && firstName[0]?.toUpperCase() + lastName[0]?.toUpperCase()}
              </div>
            )}
          </div>
          <div className="user-name">{`${firstName} ${lastName}`}</div>
        </>
      )}
      <img
        role="presentation"
        className="cog"
        src="/Icons/Cog.svg"
        alt="settings"
        onClick={() => history.push('/settings')}
      />
      <Logout />
    </div>
  );
};

export default UserBox;

import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import Accordion from '../Accordion';
import Tooltip from '../Tooltip';
import InputField from '../InputField';
import ModulesPage from '../../../Pages/ModulesPage';
import InvertersPage from '../../../Pages/InvertersPage';
import {
  EPMtabInitialValues,
  EPMtabValidationSchema,
} from '../../../Pages/ProductionModelUploadPage/EPMtabSchema';
import './epmTab.css';

const EPMtab = ({
  modules,
  inverters,
  scenes,
  selectedScene,
  setSelectedInverter,
  setSelectedModule,
  setSelectedScene,
  setSimulated,
  setBlocks,
  id,
  blocks,
}) => {
  const [setedValue, setAllValue] = useState('');
  const [isAllSetted, setAllSetted] = useState(false);
  const [innerModule, setInnerModule] = useState();
  const selectRef = useRef();
  const [innerInverter, setInnerInverter] = useState();

  return (
    <Formik
      initialValues={EPMtabInitialValues}
      validationSchema={EPMtabValidationSchema}
      enableReinitialize={false}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleBlur, initialValues, setFieldValue } =
          props;
        const handleSetAll = (value, albedo) => {
          Object.keys(albedo).forEach((key) => {
            setFieldValue(`albedo.${key}`, parseFloat(Number(value).toFixed(4)));
          });
        };

        useEffect(() => {
          setBlocks({
            ...blocks,
            [id]: {
              ...values,
              id,
              errors,
              touched,
              selectedModule: +innerModule,
              selectedInverter: +innerInverter,
              DCvalue:
                (modules?.find((mod) => mod.id === +innerModule)?.pnom *
                  values.stringLength *
                  values.stringCount *
                  values.numberOfInverters) /
                1000,
              ACvalue:
                inverters?.find((inv) => inv.id === +innerInverter)?.pnomConv *
                values.numberOfInverters,
            },
          });
        }, [values, errors]);

        return (
          <form>
            <section className="accordion-section">
              <div className="accordion-wrapper">
                <Accordion
                  label="General Inputs"
                  content={
                    <div>
                      <div>
                        <div className={`set-all-container ${errors.generalAlbedo ? 'mb-40' : ''}`}>
                          <h4>Albedo by Months:</h4>
                          <div className="albedo-tooltip">
                            <Tooltip
                              content="Fields are required, should be a number from 0 to 1"
                              direction="top"
                            />
                          </div>
                          <div>
                            <div className="general-albedo">
                              <InputField
                                name="generalAlbedo"
                                type="number"
                                value={setedValue}
                                placeholder="Set all values (0-1)"
                                onChange={(e) => {
                                  setAllValue(e.target.value);
                                  handleChange(e);
                                }}
                                formikData={props}
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                handleSetAll(setedValue, values?.albedo, initialValues);
                                setAllSetted(true);
                              }}
                              disabled={setedValue > 1 || setedValue < 0 || !setedValue}
                            >
                              Set all
                            </button>
                          </div>
                        </div>
                        <div className="albedo-container">
                          {Object.keys(values.albedo).map((label) => (
                            <div key={label}>
                              <input
                                id="albedo"
                                name={`albedo.${label}`}
                                type="number"
                                pattern="\d{4}"
                                value={
                                  values.albedo[label] !== ''
                                    ? parseFloat(+values.albedo[label].toFixed(4))
                                    : values.albedo[label]
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                  setAllSetted(false);
                                }}
                                onBlur={handleBlur}
                                className={`${
                                  (errors.api && !isAllSetted && 'input-error') ||
                                  (errors?.albedo &&
                                    errors?.albedo[label] &&
                                    !isAllSetted &&
                                    touched?.albedo &&
                                    'input-error')
                                }`}
                              />
                              <div>{label}</div>
                              {errors.api && !isAllSetted && (
                                <div className="input-feedback">{errors.api}</div>
                              )}
                              {errors?.albedo && !isAllSetted && touched?.albedo && (
                                <div className="input-feedback">{errors.albedo[label]}</div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  }
                />
                <Accordion
                  label="Layout"
                  content={
                    <div className="array-section">
                      <div className="array-section-inner">
                        <div className="section-heading">Shading Model</div>
                        <Tooltip content="Field is required. Please choose an option from the list below" />
                      </div>
                      <select
                        name="shadingModel"
                        defaultValue={
                          values.shadingModel ? values.shadingModel : 'Select Shading Model'
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setSimulated(false);
                        }}
                        onBlur={handleBlur}
                        className={`${
                          (errors.api && 'input-error') ||
                          (errors.shadingModel && touched.shadingModel && 'input-error')
                        }`}
                      >
                        <option value="Select Shading Model" disabled>
                          Select Shading Model
                        </option>
                        <option value="2D">2D</option>
                        <option value="3D">3D (Octavia)</option>
                      </select>
                      {errors.shadingModel && touched.shadingModel && (
                        <div className="input-feedback">{errors.shadingModel}</div>
                      )}
                      {errors.api && <div className="input-feedback">{errors.api}</div>}

                      <div className="shading-switch">
                        <span>Use horizon for far shading</span>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="switch">
                          <input
                            name="farShading"
                            type="checkbox"
                            checked={values.farShading}
                            onChange={(e) => {
                              handleChange(e);
                              setSimulated(false);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                        {values.farShading && (
                          <i className="shading-activated">Far Shading Activated</i>
                        )}
                      </div>
                      <div className="shading-impact">
                        <span>Electrical Impact</span>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="switch">
                          <input
                            name="electricalImpact"
                            type="checkbox"
                            checked={values.electricalImpact}
                            onChange={(e) => {
                              handleChange(e);
                              setSimulated(false);
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                      {selectRef?.current?.value === 'tracker' && (
                        <div
                          className={
                            values?.shadingModel === '3D'
                              ? 'layout-backtracking hidden'
                              : 'layout-backtracking'
                          }
                        >
                          <span>Backtracking</span>
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <label className="switch">
                            <input
                              name="backtrack"
                              checked={values.backtrack}
                              type="checkbox"
                              onChange={(e) => {
                                handleChange(e);
                                setSimulated(false);
                              }}
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                      )}
                      <div className="shading-octavia-id">
                        {values.electricalImpact && (
                          <div className="input-container">
                            <InputField
                              type="number"
                              name="transverseDiodes"
                              label="Transverse Diodes"
                              tooltipContent="Field should be a number from 0 to 10"
                              onChange={(e) => {
                                handleChange(e);
                                setSimulated(false);
                              }}
                              className={`diodes-input ${
                                (errors.api && 'input-error') ||
                                (errors.transverseDiodes &&
                                  touched.transverseDiodes &&
                                  'input-error')
                              }`}
                              formikData={props}
                            />
                          </div>
                        )}
                      </div>

                      {values?.shadingModel === '3D' && (
                        <div className="array-section">
                          <div className="array-section-inner">
                            <div className="section-heading">Shading Scene</div>
                            <Tooltip content="Field is required. Please choose an option from the list below" />
                          </div>
                          <select
                            name="shadingScene"
                            required
                            defaultValue={selectedScene || 'Select Shading Scene'}
                            onChange={(e) => {
                              setSelectedScene(e.target.value);
                              setSimulated(false);
                            }}
                          >
                            <option value="Select Shading Scene" disabled>
                              Select Shading Scene
                            </option>
                            {scenes?.map((scene) => (
                              <option key={scene?.id} value={scene.id}>
                                {scene?.dropDownName}
                              </option>
                            ))}
                          </select>
                          {errors.shadingScene && touched.shadingScene && (
                            <div className="input-feedback">{errors.shadingScene}</div>
                          )}
                          {errors.api && <div className="input-feedback">{errors.api}</div>}
                        </div>
                      )}

                      {values?.shadingModel === '2D' && (
                        <div>
                          <div className="array-section-inner">
                            <div className="section-heading">Array Type</div>
                            <Tooltip content="Field is required. Please choose an option from the list below" />
                          </div>
                          <select
                            ref={selectRef}
                            name="arrayType"
                            defaultValue={values.arrayType ? values.arrayType : 'Select Array Type'}
                            onChange={(e) => {
                              handleChange(e);
                              setSimulated(false);
                            }}
                            onBlur={handleBlur}
                            className={`${
                              (errors.api && 'input-error') ||
                              (errors.arrayType && touched.arrayType && 'input-error')
                            }`}
                          >
                            <option value="Select Array Type" disabled>
                              Select Array Type
                            </option>
                            <option value="tracker">Single Axis Tracker</option>
                            <option value="fixed">Fixed Tilt</option>
                          </select>
                          {errors.arrayType && touched.arrayType && (
                            <div className="input-feedback">{errors.arrayType}</div>
                          )}
                          {errors.api && <div className="input-feedback">{errors.api}</div>}
                          <div className="layout-inputs">
                            <div
                              className={
                                values?.shadingModel === '3D'
                                  ? 'input-container hidden'
                                  : 'input-container'
                              }
                            >
                              <InputField
                                name="gCR"
                                type="number"
                                label="GCR"
                                tooltipContent="Field is required, should be a number from 0.1 to 0.85"
                                tooltipDirection={values.arrayType ? 'top' : 'right'}
                                onChange={(e) => {
                                  handleChange(e);
                                  setSimulated(false);
                                }}
                                formikData={props}
                              />
                            </div>
                            <div
                              className={
                                values?.shadingModel === '3D'
                                  ? 'input-container hidden'
                                  : 'input-container'
                              }
                            >
                              <InputField
                                name="azimuth"
                                type="number"
                                label="Azimuth"
                                tooltipContent="Field is required, should be a number from 0 to 360"
                                tooltipDirection={values.arrayType ? 'top' : 'right'}
                                onChange={(e) => {
                                  handleChange(e);
                                  setSimulated(false);
                                }}
                                formikData={props}
                              />
                            </div>
                            <div
                              className={
                                values?.shadingModel === '3D'
                                  ? 'input-container hidden'
                                  : 'input-container'
                              }
                            >
                              <InputField
                                name="axisTilt"
                                type="number"
                                label="Axis Tilt"
                                tooltipContent="Field is required, should be a number from 0 to 30"
                                tooltipDirection={values.arrayType ? 'top' : 'right'}
                                onChange={(e) => {
                                  handleChange(e);
                                  setSimulated(false);
                                }}
                                formikData={props}
                              />
                            </div>
                            {selectRef?.current?.value === 'tracker' && (
                              <div
                                className={
                                  values?.shadingModel === '3D'
                                    ? 'input-container hidden'
                                    : 'input-container'
                                }
                              >
                                <InputField
                                  name="maxAngle"
                                  type="number"
                                  label="Max Tracking Angle"
                                  tooltipContent="Field should be a number from 0 to 90"
                                  tooltipDirection={values.arrayType ? 'top' : 'right'}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setSimulated(false);
                                  }}
                                  formikData={props}
                                />
                              </div>
                            )}
                            {selectRef?.current?.value === 'fixed' && (
                              <div className="input-container">
                                <InputField
                                  name="tilt"
                                  label="Tilt"
                                  type="number"
                                  tooltipContent="Field should be a number from 0 to 90"
                                  tooltipDirection={values.arrayType ? 'top' : 'right'}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setSimulated(false);
                                  }}
                                  formikData={props}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  }
                />
                <Accordion
                  label={
                    <div>
                      <span>Module</span>
                      <span className="accordion-title-data">
                        {innerModule
                          ? `${
                              modules?.find((module) => module.id === +innerModule)?.dropDownName
                            } ${
                              modules?.find((module) => module.id === +innerModule)?.manufacturer
                            } ${modules?.find((module) => module.id === +innerModule)?.modelName}`
                          : ''}
                      </span>
                    </div>
                  }
                  content={
                    <div>
                      <select
                        name="module"
                        required
                        defaultValue={innerModule || 'Select Module'}
                        onChange={(e) => {
                          setSelectedModule(e.target.value);
                          setInnerModule(e.target.value);
                          setSimulated(false);
                        }}
                      >
                        <option value="Select Module" disabled>
                          Select Module
                        </option>
                        {modules?.map((module) => (
                          <option key={module?.id} value={module.id}>
                            {module?.dropDownName}
                          </option>
                        ))}
                      </select>
                      {innerModule && <ModulesPage id={innerModule} />}
                    </div>
                  }
                />
                <Accordion
                  label={
                    <div>
                      <span>Inverter</span>
                      <span className="accordion-title-data">
                        {innerInverter
                          ? `${
                              inverters?.find((inverter) => inverter.id === +innerInverter)
                                ?.dropDownName
                            } ${
                              inverters?.find((inverter) => inverter.id === +innerInverter)
                                ?.manufacturer
                            } ${
                              inverters?.find((inverter) => inverter.id === +innerInverter)
                                ?.modelName
                            }`
                          : ''}
                      </span>
                    </div>
                  }
                  content={
                    <div>
                      <select
                        name="inverter"
                        defaultValue={innerInverter || 'Select Inverter'}
                        required
                        onChange={(e) => {
                          setSelectedInverter(e.target.value);
                          setInnerInverter(e.target.value);
                          setSimulated(false);
                        }}
                      >
                        <option value="Select Inverter" disabled>
                          Select Inverter
                        </option>
                        {inverters?.map((inverter) => (
                          <option key={inverter?.id} value={inverter.id}>
                            {inverter?.dropDownName}
                          </option>
                        ))}
                      </select>
                      {innerInverter && <InvertersPage id={innerInverter} />}
                    </div>
                  }
                />
                {innerModule && innerInverter && (
                  <Accordion
                    label="Array"
                    content={
                      <div className="properties-section array-acc w-100">
                        <div className="properties-section-item">
                          <span className="section-heading">Architecture</span>
                          <Tooltip content="Fields are required, Modules per String should be a number from 0 to 40, String Count - from 0 to 2000" />
                        </div>
                        <hr className="divider" />
                        <div>
                          <div>Modules per String</div>
                          <div className="array-value">
                            <InputField
                              type="number"
                              name="stringLength"
                              onChange={(e) => {
                                handleChange(e);
                                setSimulated(false);
                              }}
                              formikData={props}
                            />
                          </div>
                        </div>
                        <div>
                          <div>String Count</div>
                          <div className="array-value">
                            <InputField
                              type="number"
                              name="stringCount"
                              onChange={(e) => {
                                handleChange(e);
                                setSimulated(false);
                              }}
                              formikData={props}
                            />
                          </div>
                        </div>
                        <br />
                        <div className="properties-section-item">
                          <span className="section-heading">Losses</span>
                          <Tooltip content="Fields are required, should be a number from -100 to 100" />
                        </div>
                        <hr className="divider" />
                        <div>
                          <div>DC Ohmic</div>
                          <div className="array-value">
                            <InputField
                              type="number"
                              name="dcOhmic"
                              isPercentage
                              onChange={(e) => {
                                handleChange(e);
                                setSimulated(false);
                              }}
                              formikData={props}
                            />
                          </div>
                        </div>
                        <div>
                          <div>Module Quality</div>
                          <div className="array-value">
                            <InputField
                              type="number"
                              name="moduleQuality"
                              isPercentage
                              onChange={(e) => {
                                handleChange(e);
                                setSimulated(false);
                              }}
                              formikData={props}
                            />
                          </div>
                        </div>
                        <div>
                          <div>Mismatch</div>
                          <div className="array-value">
                            <InputField
                              type="number"
                              name="mismatch"
                              isPercentage
                              onChange={(e) => {
                                handleChange(e);
                                setSimulated(false);
                              }}
                              formikData={props}
                            />
                          </div>
                        </div>
                        <div>
                          <div>LID</div>
                          <div className="array-value">
                            <InputField
                              type="number"
                              name="lId"
                              isPercentage
                              onChange={(e) => {
                                handleChange(e);
                                setSimulated(false);
                              }}
                              formikData={props}
                            />
                          </div>
                        </div>
                        <div>
                          <div>String Voltage Mismatch</div>
                          <div className="array-value">
                            <InputField
                              type="number"
                              name="stringVoltageMismatch"
                              isPercentage
                              onChange={(e) => {
                                handleChange(e);
                                setSimulated(false);
                              }}
                              formikData={props}
                            />
                          </div>
                        </div>
                        <div>
                          <div>Inverter Auxiliary Losses</div>
                          <div className="inverter-looses">
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label className="switch">
                              <input
                                type="checkbox"
                                name="invAuxLoss"
                                checked={values.invAuxLoss}
                                onChange={(e) => {
                                  handleChange(e);
                                  setSimulated(false);
                                }}
                              />
                              <span className="slider round" />
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="properties-section-item">
                          <span className="section-heading">Thermal Assumptions</span>
                          <Tooltip content="Fields are required, should be a number from 0 to 100" />
                        </div>
                        <hr className="divider" />
                        <div>
                          <div>Uc</div>
                          <div className="array-value">
                            <InputField
                              type="number"
                              name="valueUc"
                              onChange={(e) => {
                                handleChange(e);
                                setSimulated(false);
                              }}
                              formikData={props}
                            />
                          </div>
                        </div>
                        <div>
                          <div>Uv</div>
                          <div className="array-value">
                            <InputField
                              type="number"
                              name="valueUv"
                              onChange={(e) => {
                                handleChange(e);
                                setSimulated(false);
                              }}
                              formikData={props}
                            />
                          </div>
                        </div>
                        {modules
                          ?.find((module) => module?.id === +innerModule)
                          // eslint-disable-next-line no-prototype-builtins
                          ?.hasOwnProperty('bifacialityFactor') && (
                          <>
                            <div className="bifacial">
                              <span>Bifacial</span>
                              <div className="bifacial-input">
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label className="switch">
                                  <input
                                    name="bifacial"
                                    checked={values.bifacial}
                                    type="checkbox"
                                    onChange={(e) => {
                                      handleChange(e);
                                      setSimulated(false);
                                    }}
                                  />
                                  <span className="slider round" />
                                </label>
                              </div>
                            </div>
                            {values.bifacial && (
                              <>
                                <div className="properties-section-item">
                                  <span className="section-heading">Bifacial Assumptions</span>
                                  <Tooltip content="Tracker Height should be a number from 0 to 8, all other fields - from 0 to 100" />
                                </div>
                                <hr className="divider" />
                                <div>
                                  <div>Tracker Height (m)</div>
                                  <div className="array-value">
                                    <InputField
                                      type="number"
                                      name="trackerHeight"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setSimulated(false);
                                      }}
                                      formikData={props}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div>Racking Shading Loss</div>
                                  <div className="array-value">
                                    <InputField
                                      type="number"
                                      name="rackingShading"
                                      isPercentage
                                      onChange={(e) => {
                                        handleChange(e);
                                        setSimulated(false);
                                      }}
                                      formikData={props}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div>Irradiance Mismatch Loss</div>
                                  <div className="array-value">
                                    <InputField
                                      type="number"
                                      name="irradianceMismatchLoss"
                                      isPercentage
                                      onChange={(e) => {
                                        handleChange(e);
                                        setSimulated(false);
                                      }}
                                      formikData={props}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div>Module Transparency</div>
                                  <div className="array-value">
                                    <InputField
                                      type="number"
                                      name="moduleTransparency"
                                      isPercentage
                                      formikData={props}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    }
                  />
                )}
                {innerInverter && innerModule && (
                  <Accordion
                    label="AC Subsystem"
                    content={
                      <div className="array-section">
                        <div className="array-section-inner">
                          <div className="layout-inputs">
                            <div className="input-container">
                              <InputField
                                name="acohmicLoss"
                                type="number"
                                label="AC Loss"
                                tooltipContent="Field is required, should be a number from 0 to 100"
                                tooltipDirection={values.arrayType ? 'top' : 'right'}
                                isPercentage
                                onChange={(e) => {
                                  handleChange(e);
                                  setSimulated(false);
                                }}
                                formikData={props}
                              />
                            </div>
                            <div className="input-container">
                              <InputField
                                name="numberOfInverters"
                                type="number"
                                label="Number of Inverters"
                                tooltipContent="Field is required, should be a number from 1 to 1000"
                                tooltipDirection={values.arrayType ? 'top' : 'right'}
                                onChange={(e) => {
                                  handleChange(e);
                                  setSimulated(false);
                                }}
                                formikData={props}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                )}
              </div>
            </section>
          </form>
        );
      }}
    </Formik>
  );
};

export default EPMtab;

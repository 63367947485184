import React, { useState } from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';

import './markerWithInfoWindow.css';

const MarkerWithInfoWindow = ({ id, position, label, clusterer, content, onClick }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Marker
      key={id}
      position={position}
      clusterer={clusterer}
      icon="https://maps.google.com/mapfiles/ms/icons/orange-dot.png"
      label={{
        text: label || ' ',
        className: 'marker-label',
      }}
      title={label}
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(false)}
      onClick={onClick}
    >
      {isOpen && (
        <InfoWindow onCloseClick={() => setOpen(!isOpen)}>
          <div>{content}</div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default MarkerWithInfoWindow;

import { useState } from 'react';

const useToast = () => {
  const [toastList, setToastList] = useState([]);
  const showToast = (message, type) => {
    if (toastList.some((toast) => toast.message === message && toast.type === type)) {
      setToastList([...toastList]);
    } else {
      setToastList([...toastList, { message, type, id: Math.floor(Math.random() * 1000000) }]);
    }
  };
  return {
    toastList,
    showToast,
  };
};

export default useToast;

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import './uploadButton.css';

const UploadButton = ({ name }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <button className="upload-btn" type="button" onClick={() => history.push(`${pathname}-upload`)}>
      {`+ ${name}`}
    </button>
  );
};

export default UploadButton;

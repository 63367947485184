import * as Yup from 'yup';

export const ShadingSceneInitialValues = {
  shadingSceneName: '',
  arrayType: '',
  gCR: '',
  azimuth: 180,
  axisTilt: '',
  maxAngle: '',
  terrainTracking: false,
  backTracking: false,
};

export const ShadingSceneValidationSchema = Yup.object().shape({
  shadingSceneName: Yup.string()
    .required('No Shading Scene Name provided.')
    .matches(/^\S/, 'Shading Scene Name should not start from space.')
    .min(3, 'Shading Scene Name is too short - should be 3 char minimum.')
    .max(50, 'Shading Scene Name is too long - should be 50 chars maximum.'),
  arrayType: Yup.string().required('Please select Array Type'),
  gCR: Yup.number()
    .min(0.1, 'GCR should be from 0.1 to 0.85.')
    .max(0.85, 'GCR should be from 0.1 to 0.85.')
    .when('arrayType', {
      is: 'tracker',
      then: Yup.number().required('No GCR provided.'),
    }),
  azimuth: Yup.number()
    .min(0, 'Azimuth should be from 0 to 360.')
    .max(360, 'Azimuth should be from 0 to 360.')
    .when('arrayType', {
      is: 'tracker',
      then: Yup.number().required('No Azimuth provided.'),
    }),
  axisTilt: Yup.number()
    .min(0, 'Axis Tilt should be from 0 to 30.')
    .max(30, 'Axis Tilt should be from 0 to 30.')
    .when('arrayType', {
      is: 'tracker',
      then: Yup.number().required('No Axis Tilt provided.'),
    }),
  maxAngle: Yup.number()
    .min(0, 'Max Tracking Angle should be from 0 to 90.')
    .max(90, 'Max Tracking Angle should be from 0 to 90.')
    .when('arrayType', {
      is: 'tracker',
      then: Yup.number().required('No Max Tracking Angle provided.'),
    }),
  terrainTracking: Yup.boolean(),
  backTracking: Yup.boolean(),
});

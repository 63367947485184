import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Error from '../../Components/Shared/Error';
import Loader from '../../Components/Shared/Loader';
import Card from '../../Components/Shared/Card';
import SearchBar from '../../Components/Shared/SearchBar';
import Toast from '../../Components/Shared/Toast';
import { useOptionsList } from '../../hooks/useOptionsList';
import useToast from '../../hooks/useToast';
import { TOAST_TYPES } from '../../utils/constants';

import './shadingSceneListPage.css';

const ShadingSceneListPage = () => {
  const history = useHistory();
  const [currentList, setCurrentList] = useState([]);
  const [searchOption, setSearchOption] = useState('');
  const { toastList, showToast } = useToast();
  const { pathname } = useLocation();
  const {
    data: shadingScenesOptionsList,
    setData: setShadingScenesOptionsList,
    isLoading,
    setLoading,
    error,
  } = useOptionsList(pathname);

  useEffect(() => {
    setCurrentList(shadingScenesOptionsList);
  }, [shadingScenesOptionsList]);

  useEffect(() => {
    setCurrentList(
      shadingScenesOptionsList.filter(({ dropDownName }) =>
        dropDownName.toUpperCase().includes(searchOption.toString().toUpperCase())
      )
    );
  }, [searchOption]);

  useEffect(() => {
    if (history.location.state?.from === '/shading-upload') {
      history.replace({ ...history.location, state: undefined });
      showToast('Octavia scene being processed', TOAST_TYPES.success);
    }
  }, []);

  const renderShadingScenesList = () => {
    if (error) {
      return <Error />;
    }
    if (isLoading) {
      return <Loader />;
    }
    if (!isLoading && !currentList.length) {
      return <div className="no-data">No Shading Scenes in Workspace</div>;
    }
    return currentList?.map((data) => (
      <Card
        type="shading"
        key={data.id}
        data={data}
        setList={setShadingScenesOptionsList}
        setLoading={setLoading}
        showToast={showToast}
      />
    ));
  };

  return (
    <>
      <Toast toastList={toastList} />
      <div className="shading-list-page">
        <SearchBar
          searchOption={searchOption}
          setSearchOption={setSearchOption}
          uploadName="Create New Shading Scene"
          header="Shading Scene"
        />
        <div className="list-section">{renderShadingScenesList()}</div>
      </div>
    </>
  );
};

export default ShadingSceneListPage;

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import axiosConfig from '../../utils/axiosConfig';
import { UPLOAD_TYPES, DATA_TYPES } from '../../utils/constants';
import { useGeolocationCoords } from '../../hooks/useGeolocationCoords';
import Loader from '../../Components/Shared/Loader';
import Error from '../../Components/Shared/Error';
import InputField from '../../Components/Shared/InputField';
import Tooltip from '../../Components/Shared/Tooltip';
import LocationMap from '../../Components/Shared/Maps';

import './locationsUploadPage.css';

const LocationsUploadPage = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [file] = useState(new FormData());
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [elevation, setElevation] = useState();
  const { defaultCoords } = useGeolocationCoords();
  const [fileName, setFileName] = useState('');
  const [isAllSetted, setAllSetted] = useState(false);

  useEffect(() => {
    return () => {
      setLatitude(null);
      setLongitude(null);
      setElevation(null);
    };
  }, []);

  const handleChangeFile = async (e) => {
    await setLoading(true);
    await file.append('data', e.target.files[0]);
    setFileName(e.target.value.split(/(\\|\/)/g).pop());
    await axiosConfig
      .post(`/api/locations/getCoordinates`, file)
      .then((response) => {
        if (response) {
          setLatitude(response.data.lat);
          setLongitude(response.data.lng);
          setElevation(response.data.elevation);
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(err.type === undefined ? err : err);
        setLoading(false);
      });
  };

  const handleUploadLocation = async (values) => {
    await setLoading(true);
    await file.append('name', values.locationName.trim());
    await file.append('lat', latitude || values.latitude);
    await file.append('lng', longitude || values.longitude);
    // await file.append('elevation', elevation);
    await file.append('source', values.uploadType);
    // await file.append('temp', values.minTemperature);
    await file.append('soiling', JSON.stringify(values.soiling));
    if (values.uploadType === UPLOAD_TYPES.NSRDB) {
      await file.append('type', values.dataType.toLowerCase());
      await file.append('year', values.year);
      await file.append('interval', values.interval);
    }

    await axiosConfig
      .post(`/api/locations/upload`, file)
      .then((response) =>
        response.status !== 200
          ? setLoading(true)
          : history.push('/locations', { from: '/locations-upload' })
      )
      .catch((err) => {
        setError(err.type !== undefined ? err : err);
        setLoading(false);
      });

    await setLoading(false);
  };

  if (error) return <Error />;
  return loading ? (
    <Loader />
  ) : (
    <Formik
      initialValues={{
        locationName: '',
        // minTemperature: 0,
        generalSoiling: '',
        latitude: '',
        longitude: '',
        uploadType: UPLOAD_TYPES.FILE,
        dataType: '',
        year: '',
        interval: '',
        soiling: {
          January: 0,
          February: 0,
          March: 0,
          April: 0,
          May: 0,
          June: 0,
          July: 0,
          August: 0,
          September: 0,
          October: 0,
          November: 0,
          December: 0,
        },
      }}
      validationSchema={Yup.object().shape({
        locationName: Yup.string()
          .required('No Location Name provided.')
          .matches(/^\S/, 'Location Name should not start from space.')
          .min(3, 'Location Name is too short - should be 3 char minimum.')
          .max(50, 'Location Name is too long - should be 50 chars maximum.'),
        latitude: Yup.number()
          .required('No Latitude provided.')
          .min(-90, 'Latitude is incorrect. It should be a number from -90 to 90.')
          .max(90, 'Latitude is incorrect. It should be a number from -90 to 90.'),
        longitude: Yup.number()
          .required('No Longitude provided.')
          .min(-180, 'Longitude is incorrect. It should be a number from -180 to 180.')
          .max(180, 'Longitude is incorrect. It should be a number from -180 to 180.'),
        uploadType: Yup.string(),
        dataType: Yup.string().when('uploadType', {
          is: UPLOAD_TYPES.NSRDB,
          then: Yup.string().required('Please select Data Type'),
          otherwise: Yup.string(),
        }),
        year: Yup.number().when('uploadType', {
          is: UPLOAD_TYPES.NSRDB,
          then: Yup.number().required('Please select the Year'),
          otherwise: Yup.number(),
        }),
        interval: Yup.number().when(['uploadType', 'dataType'], {
          is: (uploadType, dataType) =>
            uploadType === UPLOAD_TYPES.NSRDB &&
            DATA_TYPES[dataType] === DATA_TYPES.PSMV3_SUBHOURLY,
          then: Yup.number().required('Please select the Interval'),
          otherwise: Yup.number(),
        }),
        // minTemperature: Yup.number()
        //   .required('No temperature provided.')
        //   .min(-50, 'Temperature is incorrect. It should be from -50 to 100.')
        //   .max(100, 'Temperature is incorrect. It should be from -50 to 100.'),
        generalSoiling: Yup.number()
          .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
          .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
        soiling: Yup.object().shape({
          January: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
          February: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
          March: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
          April: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
          May: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
          June: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
          July: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
          August: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
          September: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
          October: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
          November: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
          December: Yup.number()
            .required('This field is required.')
            .min(0, 'Soiling is incorrect. It should be from 0 to 100.')
            .max(100, 'Soiling is incorrect. It should be from 0 to 100.'),
        }),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleReset,
          handleChange,
          handleBlur,
        } = props;
        const handleSetAll = (value, soiling) => {
          Object.keys(soiling).forEach((key) => {
            setFieldValue(`soiling.${key}`, parseFloat(Number(value).toFixed(2)));
          });
        };
        return (
          <div id="upload-locations-page">
            <div id="upload-box">
              <h2 className="title">New Location</h2>
              <div className="upload-details">
                <form onSubmit={() => handleUploadLocation(values)}>
                  <div hidden={values.uploadType === UPLOAD_TYPES.FILE && !fileName}>
                    <div>
                      <InputField
                        id="file-name"
                        label="Location Name"
                        name="locationName"
                        type="text"
                        placeholder="Enter location name here"
                        tooltipContent="Field is required, length should be from 3 to 50 chars"
                        formikData={props}
                      />
                    </div>
                    <div className="location-upload-coords">
                      <div className="w-50">
                        <InputField
                          name="latitude"
                          label="Latitude"
                          type="number"
                          placeholder="Enter Latitude"
                          value={
                            values.uploadType === UPLOAD_TYPES.FILE
                              ? latitude || ''
                              : values.latitude
                          }
                          tooltipContent="Field is required, should be a number from -90 to 90."
                          disabled={values.uploadType === UPLOAD_TYPES.FILE && fileName}
                          formikData={props}
                        />
                      </div>
                      <div className="w-50">
                        <InputField
                          name="longitude"
                          label="Longitude"
                          type="number"
                          placeholder="Enter Longitude"
                          value={
                            values.uploadType === UPLOAD_TYPES.FILE
                              ? longitude || ''
                              : values.longitude
                          }
                          tooltipContent="Field is required, should be a number from -180 to 180."
                          formikData={props}
                          disabled={values.uploadType === UPLOAD_TYPES.FILE && fileName}
                        />
                      </div>
                    </div>
                    {/* <div>Elevation: {elevation}ft</div> */}
                    {/* <div className="location-upload-description"> */}
                    {/*   <InputField */}
                    {/*     id="min-temperature" */}
                    {/*     type="number" */}
                    {/*     name="minTemperature" */}
                    {/*     label={`ASHRAE Minimum Design Temperature: ${values.minTemperature} °C`} */}
                    {/*     placeholder="0" */}
                    {/*     tooltipContent="Field is required, should be a number from -50 to 100" */}
                    {/*     tooltipDirection="top" */}
                    {/*     value={values.minTemperature.toString()} */}
                    {/*     onChange={handleChange} */}
                    {/*     onBlur={handleBlur} */}
                    {/*     className={`${ */}
                    {/*       (errors.api && 'input-error') || */}
                    {/*       (errors.minTemperature && touched.minTemperature && 'input-error') */}
                    {/*     }`} */}
                    {/*     errors={errors} */}
                    {/*     touched={touched.minTemperature} */}
                    {/*   /> */}
                    {/* </div> */}
                    <div className="location-upload-map">
                      {latitude && longitude && values.uploadType === UPLOAD_TYPES.FILE && (
                        <LocationMap
                          options={{
                            center: {
                              lat: latitude || 0,
                              lng: longitude || 0,
                            },
                            zoom: 6,
                          }}
                          data={[
                            {
                              id: 1,
                              dropDownName: values.locationName,
                              latitude,
                              longitude,
                              elevation,
                            },
                          ]}
                          infoWindowContent={['name', 'elevation']}
                        />
                      )}
                      {!!values.latitude &&
                        !!values.longitude &&
                        !errors.latitude &&
                        !errors.longitude &&
                        values.uploadType !== UPLOAD_TYPES.FILE && (
                          <LocationMap
                            options={{
                              center: {
                                lat: values.latitude || 0,
                                lng: values.longitude || 0,
                              },
                              zoom: 6,
                            }}
                            data={[
                              {
                                id: 1,
                                dropDownName: values.locationName,
                                latitude: values.latitude,
                                longitude: values.longitude,
                                // elevation,
                              },
                            ]}
                            infoWindowContent={['name']}
                          />
                        )}
                      {(!values.latitude ||
                        !values.longitude ||
                        errors.latitude ||
                        errors.longitude) &&
                        values.uploadType !== UPLOAD_TYPES.FILE && (
                          <LocationMap
                            options={{
                              center: {
                                lat: defaultCoords.latitude || 0,
                                lng: defaultCoords.longitude || 0,
                              },
                              zoom: 6,
                            }}
                            data={undefined}
                            infoWindowContent={['name']}
                          />
                        )}
                    </div>
                    {values.uploadType === UPLOAD_TYPES.NSRDB && (
                      <div className="weather-data-info">
                        <h4 className="weather-data-header">Weather Data:</h4>
                        <div className="weather-data-inner">
                          <div className="section-heading">Data Type</div>
                          <Tooltip content="Field is required. Please choose an option from the list below" />
                        </div>
                        <select
                          name="dataType"
                          defaultValue="Select Data Type"
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue('year', '');
                            setFieldValue('interval', '');
                            setFieldTouched('year', false);
                            setFieldTouched('interval', false);
                          }}
                          onBlur={handleBlur}
                          className={`${
                            (errors.api && 'input-error') ||
                            (errors.dataType && touched.dataType && 'input-error')
                          }`}
                        >
                          <option value="Select Data Type" disabled>
                            Select Data Type
                          </option>
                          {Object.keys(DATA_TYPES).map((key) => (
                            <option key={key} value={key}>
                              {DATA_TYPES[key].title}
                            </option>
                          ))}
                        </select>
                        {errors.dataType && touched.dataType && (
                          <div className="input-feedback">{errors.dataType}</div>
                        )}
                        {errors.api && <div className="input-feedback">{errors.api}</div>}
                        {values.dataType && (
                          <>
                            <div className="weather-data-inner">
                              <div className="section-heading">Year</div>
                              <Tooltip content="Field is required. Please choose an option from the list below" />
                            </div>
                            <select
                              name="year"
                              value={values.year || 'Select Year'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`${
                                (errors.api && 'input-error') ||
                                (errors.year && touched.year && 'input-error')
                              }`}
                            >
                              <option value="Select Year" disabled>
                                Select Year
                              </option>
                              {DATA_TYPES[values?.dataType]?.years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                            {errors.year && touched.year && (
                              <div className="input-feedback">{errors.year}</div>
                            )}
                            {errors.api && <div className="input-feedback">{errors.api}</div>}
                          </>
                        )}
                        {DATA_TYPES[values.dataType] === DATA_TYPES.PSMV3_SUBHOURLY && (
                          <>
                            <div className="weather-data-inner">
                              <div className="section-heading">Interval</div>
                              <Tooltip content="Field is required. Please choose an option from the list below" />
                            </div>
                            <select
                              name="interval"
                              value={values.interval || 'Select Interval'}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`${
                                (errors.api && 'input-error') ||
                                (errors.interval && touched.interval && 'input-error')
                              }`}
                            >
                              <option value="Select Interval" disabled>
                                Select Interval
                              </option>
                              <option value="30">30 Minutes</option>
                              <option value="15">15 Minutes</option>
                              <option value="5">5 Minutes</option>
                            </select>
                            {errors.interval && touched.interval && (
                              <div className="input-feedback">{errors.interval}</div>
                            )}
                            {errors.api && <div className="input-feedback">{errors.api}</div>}
                          </>
                        )}
                      </div>
                    )}
                    <div className="soiling-block">
                      <div className="soiling-data">
                        <div className="soiling-data-inner">
                          <h4 className="soiling-data-heading">Soiling by Month:</h4>
                          <div>
                            <Tooltip content="Fields are required, should be a number from 0 to 100" />
                          </div>
                        </div>
                        <div className="general-soiling-data">
                          <div className="general-soiling-container">
                            <div className="general-soiling">
                              <InputField
                                name="generalSoiling"
                                type="number"
                                isPercentage
                                value={
                                  values.generalSoiling !== ''
                                    ? parseFloat(+values.generalSoiling.toFixed(2))
                                    : values.generalSoiling
                                }
                                placeholder="Set all (0-100)"
                                formikData={props}
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                handleSetAll(values?.generalSoiling, values?.soiling);
                                setAllSetted(true);
                              }}
                              disabled={
                                values.generalSoiling === '' ||
                                values.generalSoiling > 100 ||
                                values.generalSoiling < 0
                              }
                            >
                              Set all
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="soiling-container">
                        {Object.keys(values.soiling).map((label) => (
                          <div key={label}>
                            <InputField
                              name={`soiling.${label}`}
                              type="number"
                              isPercentage
                              value={values.soiling[label]}
                              onChange={(e) => {
                                setFieldValue(
                                  `soiling.${label}`,
                                  e.target.value !== ''
                                    ? parseFloat(Number(e.target.value).toFixed(2))
                                    : ''
                                );
                                setAllSetted(false);
                              }}
                              className={`${
                                (errors.api && !isAllSetted && 'input-error') ||
                                (errors?.soiling &&
                                  errors.soiling[label] &&
                                  !isAllSetted &&
                                  touched?.soiling &&
                                  'input-error')
                              }`}
                              formikData={props}
                            />
                            <div>{label.slice(0, 3)}</div>
                            {errors.api && !isAllSetted && (
                              <div className="input-feedback">{errors.api}</div>
                            )}
                            {errors?.soiling && !isAllSetted && touched?.soiling && (
                              <div className="input-feedback">{errors.soiling[label]}</div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="upload-details-block">
                    <input
                      type="file"
                      id="upload"
                      accept={['.xlsx', '.xlsm', '.csv']}
                      hidden
                      onChange={(e) => handleChangeFile(e)}
                    />
                    <button
                      hidden={fileName || values.uploadType !== UPLOAD_TYPES.FILE}
                      className="select-button"
                      type="button"
                      onClick={() => document.getElementById('upload').click()}
                    >
                      + Select weather data
                    </button>
                  </div>
                  <input
                    type="text"
                    className="file-name"
                    hidden={!fileName}
                    value={fileName}
                    disabled
                  />
                  <div className="buttons">
                    {!fileName &&
                      values.uploadType === UPLOAD_TYPES.FILE &&
                      Object.keys(errors).length > 2}
                    <button
                      className="upload-button"
                      disabled={
                        !values.locationName ||
                        (Object.keys(errors).length !== 0 &&
                          values.uploadType !== UPLOAD_TYPES.FILE) ||
                        (fileName &&
                          values.uploadType === UPLOAD_TYPES.FILE &&
                          Object.keys(errors).length > 2) ||
                        (values.dataType && !values.year)
                      }
                      type="submit"
                    >
                      Create new Location
                    </button>
                    <button
                      className="cancel-button"
                      type="button"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
              <div className="location-upload-menu">
                <button
                  className={`select-button ${
                    values.uploadType === UPLOAD_TYPES.FILE ? 'upload-selected' : ''
                  }`}
                  type="button"
                  onClick={() => setFieldValue('uploadType', UPLOAD_TYPES.FILE)}
                >
                  File Upload
                </button>
                <button
                  className={`select-button solar-button ${
                    values.uploadType === UPLOAD_TYPES.SOLAR_ANYWHERE ? 'upload-selected' : ''
                  }`}
                  type="button"
                  onClick={(e) => {
                    handleReset(e);
                    setFieldValue('uploadType', UPLOAD_TYPES.SOLAR_ANYWHERE);
                    setFileName('');
                  }}
                >
                  <img src="/Icons/SolarAnywhere.svg" alt="SolarAnywhere Icon" width="140px" />
                </button>
                <button
                  className={`select-button ${
                    values.uploadType === UPLOAD_TYPES.NSRDB ? 'upload-selected' : ''
                  }`}
                  type="button"
                  onClick={(e) => {
                    handleReset(e);
                    setFieldValue('uploadType', UPLOAD_TYPES.NSRDB);
                    setFileName('');
                  }}
                >
                  NSRDB
                </button>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default LocationsUploadPage;

import React, { useContext } from 'react';
import { AuthContext } from '../../../context/authContext';
import { UserContext } from '../../../context/userContext';
import axiosConfig from '../../../utils/axiosConfig';

const Logout = () => {
  const { setAuthenticated } = useContext(AuthContext);
  const { setUserInfo } = useContext(UserContext);

  const handleLogout = async () => {
    await setAuthenticated(false);
    await localStorage.clear();
    await setUserInfo();
    await axiosConfig.post('/api/auth/logout');
  };

  return (
    <img
      role="presentation"
      className="logout"
      src="/Icons/Logout.svg"
      alt="logout"
      onClick={() => handleLogout()}
    />
  );
};

export default Logout;

import { useEffect, useState } from 'react';
import axiosConfig from '../utils/axiosConfig';

export const useWeatherData = (id) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      await setLoading(true);
      await axiosConfig
        .get(`/api/locations/`, {
          params: {
            id,
          },
        })
        .then((response) => {
          if (response) {
            setData(response.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          setError(err.type !== undefined ? err : err);
          setLoading(false);
        });
    })();
  }, [id]);

  return {
    data,
    isLoading,
    error,
  };
};

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useOptionsList } from '../../hooks/useOptionsList';
import useToast from '../../hooks/useToast';
import Error from '../../Components/Shared/Error';
import Loader from '../../Components/Shared/Loader';
import Card from '../../Components/Shared/Card';
import SearchBar from '../../Components/Shared/SearchBar';
import Toast from '../../Components/Shared/Toast';

const InvertersListPage = () => {
  const { pathname } = useLocation();
  const {
    data: invertersOptionsList,
    setData: setInvertersOptionsList,
    isLoading: loadInvertersList,
    setLoading: setLoadInvertersList,
    error: errorOptionsList,
  } = useOptionsList(pathname);

  const [currentList, setCurrentList] = useState([]);
  const [searchOption, setSearchOption] = useState('');
  const { toastList, showToast } = useToast();

  useEffect(() => {
    setCurrentList(invertersOptionsList);
  }, [invertersOptionsList]);

  useEffect(() => {
    setCurrentList(
      invertersOptionsList.filter(({ dropDownName }) =>
        dropDownName.toUpperCase().includes(searchOption.toString().toUpperCase())
      )
    );
  }, [searchOption]);

  const renderInvertersList = () => {
    if (errorOptionsList) {
      return <Error />;
    }
    if (loadInvertersList) {
      return <Loader />;
    }
    if (!loadInvertersList && !currentList.length) {
      return <div className="no-data">No Inverters in Workspace</div>;
    }
    return currentList?.map((data) => (
      <Card
        type="inverters"
        key={data.id}
        data={data}
        setList={setInvertersOptionsList}
        setLoading={setLoadInvertersList}
        showToast={showToast}
      />
    ));
  };
  return (
    <>
      <Toast toastList={toastList} />
      <SearchBar
        searchOption={searchOption}
        setSearchOption={setSearchOption}
        uploadName="Create New Inverter"
        header="Inverters"
      />
      <div className="list-section">{renderInvertersList()}</div>
    </>
  );
};

export default InvertersListPage;

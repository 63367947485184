import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { GoogleLogin } from 'react-google-login';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import axiosConfig from '../../utils/axiosConfig';
import { TOAST_TYPES } from '../../utils/constants';
import { UserContext } from '../../context/userContext';
import { AuthContext } from '../../context/authContext';
import useToast from '../../hooks/useToast';

import Loader from '../../Components/Shared/Loader';
import Toast from '../../Components/Shared/Toast';
import InputField from '../../Components/Shared/InputField';

import './login.css';

const LoginPage = () => {
  // const refreshTokenSetup = (res) => {
  //     let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
  //     const refreshToken = async () => {
  //         const newAuthRes = await res.reloadAuthResponse();
  //         refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
  //         setTimeout(refreshToken, refreshTiming);
  //     };
  //     setTimeout(refreshToken, refreshTiming);
  // };
  // const onSuccess = async (res) => {
  //     await setAuthenticated(true);
  //     await setUserInfo(res.profileObj);
  //     await localStorage.setItem('isAuthenticated', JSON.stringify(true));
  //     await localStorage.setItem('userInfo', JSON.stringify(res.profileObj));
  //     await refreshTokenSetup(res);
  // };
  // const onFailure = () => {
  //     localStorage.clear();
  // };
  const { setAuthenticated } = useContext(AuthContext);
  const { setUserInfo } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const { toastList, showToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    localStorage.clear();
    if (history?.previousPath?.from === '/login') {
      delete history?.previousPath;
      showToast('User account has been created', TOAST_TYPES.success);
    }
  }, []);

  return (
    <Formik
      initialValues={{ email: '', password: '', rememberMe: false }}
      onSubmit={async (values, { setErrors }) => {
        try {
          await setLoading(true);
          const response = await axiosConfig.post(`/api/auth/signIn`, values);
          await setUserInfo(response.data);
          await localStorage.setItem('isAuthenticated', JSON.stringify(true));
          await setLoading(false);
          await setAuthenticated(true);
        } catch (err) {
          setLoading(false);
          setErrors({
            api: err.response.data.message,
          });
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('No email provided.'),
        password: Yup.string().required('No password provided.'),
        rememberMe: Yup.bool(),
      })}
    >
      {(props) => {
        const { handleSubmit } = props;
        return (
          <div id="login-page">
            {loading ? (
              <Loader />
            ) : (
              <>
                <Toast toastList={toastList} />
                <form onSubmit={handleSubmit}>
                  <div className="login-box">
                    <div className="logo">
                      <img src="/Icons/Logo.png" alt="Daly Energy" />
                    </div>
                    <div className="slogan">
                      <div>Next Generation Solar +</div>
                      <div>Storage Modeling Software</div>
                    </div>
                    <div className="login-inputs">
                      <InputField
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        formikData={props}
                      />
                      <InputField
                        type="password"
                        name="password"
                        placeholder="Enter Password"
                        formikData={props}
                      />
                      <div className="labels_block">
                        <div className="remember_me_block">
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <label htmlFor="rememberMe">
                            Remember me
                            <Field type="checkbox" name="rememberMe" id="rememberMe" />
                          </label>
                        </div>
                        <input
                          type="button"
                          className="forgot_btn"
                          value="Forgot password?"
                          id="forgot_password"
                          onClick={() => history.push('/forgot')}
                        />
                      </div>
                      <div className="buttons">
                        <button type="submit" id="loginButton">
                          Sign-In
                        </button>
                        <button
                          type="button"
                          id="regButton"
                          onClick={() => history.push('/sign-up')}
                        >
                          Sign-Up
                        </button>
                      </div>
                    </div>
                    {/* <hr className="divider" /> */}
                    {/* <GoogleLogin */}
                    {/*   disabled */}
                    {/*   clientId={process.env.REACT_APP_CLIENT_ID} */}
                    {/*   buttonText="Login with Google" */}
                    {/*   // onSuccess={onSuccess} */}
                    {/*   // onFailure={onFailure} */}
                    {/*   cookiePolicy="single_host_origin" */}
                    {/*   isSignedIn */}
                    {/* /> */}
                  </div>
                </form>
              </>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default LoginPage;

import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { persistentMenuItems } from '../../../utils/constants';
import { UserContext } from '../../../context/userContext';

import UserBox from '../UserBox';

import './dashboard.css';

const withDashboard = (children) => {
  const location = useLocation();
  const { userInfo } = useContext(UserContext);
  const [actualUserInfo, setActualUserInfo] = useState(userInfo);

  useEffect(() => {
    if (userInfo) {
      setActualUserInfo(userInfo);
    }
  }, [userInfo]);
  const { currentWorkspace } = actualUserInfo || {};

  return (
    <div>
      <nav className="nav-menu expand">
        <ul className="nav-menu-items">
          <li key="/home" className="nav-text">
            <Link to="/home">
              <img src="/Icons/Logo.png" alt="daly" />
            </Link>
          </li>
          {currentWorkspace && JSON.parse(currentWorkspace) ? (
            persistentMenuItems.map(({ eventKey, path, title, image }) => (
              <li
                key={eventKey}
                className={location.pathname.includes(eventKey) ? 'nav-text active' : 'nav-text'}
              >
                <Link to={path}>
                  <img src={`/Icons/${image}`} width="25px" height="25px" alt={title || 'daly'} />
                  <span>{title}</span>
                </Link>
              </li>
            ))
          ) : (
            <span className="warning">
              You did not set the current Workspace. Please choose one in your settings below!
            </span>
          )}
          {actualUserInfo?.role === 'ADMIN' && (
            <li
              key="/dashboard"
              className={location.pathname.includes('/dashboard') ? 'nav-text active' : 'nav-text'}
            >
              <Link to="/dashboard">
                <img src="/Icons/Dashboard.svg" width="25px" height="25px" alt="Dashboard" />
                <span>Dashboard</span>
              </Link>
            </li>
          )}
        </ul>

        <div className="user">
          <UserBox user={actualUserInfo} />
        </div>
      </nav>
      <main>{children}</main>
    </div>
  );
};

export default withDashboard;

import { useEffect, useState } from 'react';

import { initialCoords } from '../utils/constants';

export const useGeolocationCoords = () => {
  const [defaultCoords, setDefaultCoords] = useState(initialCoords);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      setDefaultCoords({ latitude: coords.latitude, longitude: coords.longitude });
    });
  }, []);

  return {
    defaultCoords,
  };
};

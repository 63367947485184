import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useEnergyModelData } from '../../hooks/useEnergyModelData';
import LocationMap from '../../Components/Shared/Maps';
import Error from '../../Components/Shared/Error';
import Loader from '../../Components/Shared/Loader';
import Chart from '../../Components/Shared/Charts';
import ProgressBar from '../../Components/Shared/Progress Bar';
import { chartConfig } from '../../utils/chartConfig';
import { square, formatNumber } from '../../utils/helpers';
import { getEPMChartData } from '../../utils/adapters/chartAdapter';

import './energyModelPage.css';

const EnergyModelPage = () => {
  const { id } = useParams();

  const { data: EPMData, isLoading: loadEPMData, error } = useEnergyModelData(id);
  const { mainContentResponse, sideBarResponse } = EPMData || {};
  const {
    plantSummary,
    monthlySummaryTable,
    energyProductionSummary,
    irradianceAndLocationSummary,
    yieldProbabilityGraph,
    yieldByMonthGraph,
    yieldByMonthAndHour,
  } = mainContentResponse || {};

  const { yieldByMonthChartData, yieldByMonthAndHourChartData } = getEPMChartData(
    (() => ({ yieldByMonthGraph, yieldByMonthAndHour }))(mainContentResponse || {})
  );

  const pdfContent = useRef(null);

  if (error) return <Error />;
  return loadEPMData ? (
    <Loader />
  ) : (
    <div id="energyModelPage" ref={pdfContent}>
      <div className="main-section">
        <div className="production-model-logo">
          <div
            className="equipment-img"
            style={{
              backgroundImage: 'url(/Icons/Location-Box.svg)',
            }}
          />
          <div>Location Name</div>
        </div>
        <h3>Plant Summary</h3>
        <div className="plant-items">
          <div className="plant-item">
            <div>
              {formatNumber(plantSummary?.acCapacity)} MW<span>AC</span>
            </div>
            <div>AC Capacity</div>
          </div>
          <div className="plant-item">
            <div>
              {formatNumber(plantSummary?.dcCapacity)} MW<span>DC</span>
            </div>
            <div>DC Capacity</div>
          </div>
          <div className="plant-item">
            <div>{formatNumber(plantSummary?.dcAcRation)}</div>
            <div>DC:AC Ratio</div>
          </div>
          <div className="plant-item">
            <div>{formatNumber(plantSummary?.totalNumberOfInverters)}</div>
            <div>Total Number of Inverters</div>
          </div>
          <div className="plant-item">
            <div>{formatNumber(plantSummary?.totalNumberOfModules)}</div>
            <div>Total Number of Modules</div>
          </div>
        </div>
        <div className="general-section">
          <div className="production-summary">
            <h3>Energy Production Summary</h3>
            <div className="production-summary-items">
              <div className="production-summary-item">
                <div>P50 Specific Yield - Year 1 (kWh/kWdc)</div>
                <div>{energyProductionSummary?.p50SpecificYield}</div>
              </div>
              <div className="production-summary-item">
                <div>P75 Specific Yield - Year 1 (kWh/kWdc)</div>
                <div>{energyProductionSummary?.p75SpecificYield}</div>
              </div>
              <div className="production-summary-item">
                <div>P90 Specific Yield - Year 1 (kWh/kWdc)</div>
                <div>{energyProductionSummary?.p90SpecificYield}</div>
              </div>
              <div className="production-summary-item">
                <div>P50 Yield - Year 1 (MWh)</div>
                <div>{energyProductionSummary?.p50Yield}</div>
              </div>
              <div className="production-summary-item">
                <div>P75 Yield - Year 1 (MWh)</div>
                <div>{energyProductionSummary?.p75Yield}</div>
              </div>
              <div className="production-summary-item">
                <div>P90 Yield - Year 1 (MWh)</div>
                <div>{energyProductionSummary?.p90Yield}</div>
              </div>
            </div>
          </div>
          <div className="location-chart">
            <Chart
              chartData={{
                ...chartConfig,
                series: [
                  {
                    data: yieldByMonthChartData && yieldByMonthChartData,
                  },
                ],
                plotOptions: {
                  series: {
                    pointWidth: 20,
                    borderRadius: 10,
                    color: '#ffab40',
                  },
                },
                chart: {
                  type: 'column',
                },
                title: {
                  text: 'Yield (MWh) by Month and Hour',
                },
                legend: false,
                yAxis: {
                  title: {
                    text: 'MWh',
                  },
                },
                xAxis: {
                  categories:
                    yieldByMonthGraph && Object.values(yieldByMonthGraph).map(({ month }) => month),
                },
                tooltip: {
                  headerFormat: '<span class="location-chart-tooltip"/>',
                  pointFormat:
                    '<span class="location-chart-tooltip">{point.category}: <b>{point.y}</b></span>',
                  valueSuffix: ' MWh',
                },
              }}
            />
          </div>
        </div>
        <table className="production-table">
          <thead>
            <tr className="production-table-heading">
              <th>Month</th>
              <th>GHI</th>
              <th>DHI</th>
              <th>Diffuse Fraction</th>
              <th>Incident Irradiance</th>
              <th>Effective Irradiance</th>
              <th>Energy Production</th>
              <th>PR</th>
              <th>Albedo</th>
              <th>Soiling</th>
            </tr>
            <tr>
              <th>&nbsp;</th>
              <th>
                kWh/m<sup>2</sup>
              </th>
              <th>
                kWh/m<sup>2</sup>
              </th>
              <th>%</th>
              <th>
                kWh/m<sup>2</sup>
              </th>
              <th>
                kWh/m<sup>2</sup>
              </th>
              <th>MWh</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            {monthlySummaryTable?.map((item) => (
              <tr key={item?.month}>
                {Object.entries(item)?.map(([key, value]) => (
                  <td key={item.month + key}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="location-charts">
          <div className="location-chart">
            <Chart
              chartData={{
                ...chartConfig,
                chart: {
                  type: 'spline',
                },
                series: yieldByMonthAndHourChartData && yieldByMonthAndHourChartData,
                legend: false,
                title: {
                  text: 'Yield (MWh) by Month and Hour',
                  useHTML: true,
                },

                yAxis: {
                  title: {
                    text: 'Average DHI (W/M<sup>2</sup>)',
                    useHTML: true,
                  },
                },
                xAxis: {
                  title: {
                    text: 'Hour starting',
                  },
                },
                tooltip: {
                  headerFormat: '<span class="location-chart-tooltip"/>',
                  valueSuffix: ' W/M<sup>2</sup>',
                  useHTML: true,
                },
              }}
            />
          </div>
          <div className="location-chart">
            <Chart
              chartData={{
                ...chartConfig,
                chart: {
                  type: 'spline',
                },
                series: [
                  {
                    data: yieldProbabilityGraph && yieldProbabilityGraph,
                    type: 'spline',
                    color: '#FFA22C',
                  },
                ],
                legend: false,
                title: {
                  text: 'Yield (MWh) Probability',
                },

                yAxis: {
                  title: {
                    text: 'Probability',
                  },
                },
                xAxis: {
                  title: {
                    text: 'AC Grid System Yield (MWh)',
                  },
                },
                tooltip: {
                  headerFormat: '<span class="location-chart-tooltip"/>',
                  pointFormat: '<span class="location-chart-tooltip"><b>{point.y}</b></span>',
                  valueSuffix: ' %',
                },
              }}
            />
          </div>
        </div>
        <h3>Location Summary</h3>
        <div className="location-summary">
          <div className="map-section model-map">
            {irradianceAndLocationSummary && (
              <LocationMap
                options={{
                  center: {
                    lat: parseFloat(irradianceAndLocationSummary?.latitude),
                    lng: parseFloat(irradianceAndLocationSummary?.longitude),
                  },
                  zoom: 10,
                }}
                data={[irradianceAndLocationSummary]}
                infoWindowContent={['name', 'date', 'elevation']}
              />
            )}
          </div>
          <div className="location-summary-data">
            <h4 className="location-summary-heading">Irradiance and Location Summary</h4>
            <div className="location-summary-item">
              <div>Location Name</div>
              <div>{irradianceAndLocationSummary?.locationName}</div>
            </div>
            <div className="location-summary-item">
              <div>Latitude (deg)</div>
              <div>{irradianceAndLocationSummary?.latitude}</div>
            </div>
            <div className="location-summary-item">
              <div>Longitude (deg)</div>
              <div>{irradianceAndLocationSummary?.longitude}</div>
            </div>
            <div className="location-summary-item">
              <div>Elevation</div>
              <div>{irradianceAndLocationSummary?.elevation}</div>
            </div>
            <div className="location-summary-item">
              <div>
                Total GHI (kW/m<sup>2</sup>)
              </div>
              <div>{irradianceAndLocationSummary?.totalGhi}</div>
            </div>
            <div className="location-summary-item">
              <div>
                Total DHI (kW/m<sup>2</sup>)
              </div>
              <div>{irradianceAndLocationSummary?.totalDhi}</div>
            </div>
            <div className="location-summary-item">
              <div>
                Total DNI (kW/m<sup>2</sup>)
              </div>
              <div>{irradianceAndLocationSummary?.totalDni}</div>
            </div>
            <div className="location-summary-item">
              <div>Number of Intervals</div>
              <div>{irradianceAndLocationSummary?.numberOfIntervals}</div>
            </div>
          </div>
        </div>
        {/* <h3>Block Summary</h3> */}
      </div>
      <div className="properties-section floating-section">
        <h1 className="summary-heading">Energy Production Summary</h1>
        <div className="summary-results">
          <div className="summary-results-item">
            <div className="summary-results-value">{sideBarResponse?.mwh}</div>
            <div className="summary-results-key">Year 1 Energy Generation</div>
          </div>
          <div className="summary-results-item">
            <div className="summary-results-value">{sideBarResponse?.specificYield}</div>
            <div className="summary-results-key">Year 1 Specific Yield</div>
          </div>
        </div>
        <h3>Simulation Details</h3>
        {sideBarResponse &&
          Object.entries(sideBarResponse?.simulationDetails).map(([key, value]) => (
            <div className="capitalize" key={key}>
              {key === 'circumsolar' && <div>Circumsolar Included in Direct Component</div>}
              {key === 'farShading' && <div>Far Shading Included</div>}
              {key === 'electricalImpact' && <div>Electrical Impact from Irradiance Mismatch </div>}
              {key !== 'circumsolar' && key !== 'farShading' && key !== 'electricalImpact' && (
                <div>{key?.split(/(?=[A-Z])/).map((part) => `${part} `)}:</div>
              )}
              <div>
                {key === 'dateCreated'
                  ? new Date(value)
                      .toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'numeric',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                      .replace(/,/g, '')
                  : value?.toString()}
              </div>
            </div>
          ))}
        <h3>Performance Summary</h3>
        {sideBarResponse &&
          Object.entries(sideBarResponse?.performanceSummary).map(([key, value]) => (
            <div className="capitalize withBandwidth" key={key}>
              <div
                className={
                  ((parseFloat(value?.displayValue) > 100 ||
                    value?.displayValue?.toString().split('').pop() !== '%') &&
                    'bold') ||
                  ''
                }
              >
                {key
                  ?.split(/(?=[A-Z])/)
                  .reduce(
                    (a, b) =>
                      (a[a.length - 1] !== a[a.length - 1]?.toUpperCase() ||
                        (a[a.length - 1] === a[a.length - 1]?.toUpperCase() &&
                          b[1] !== b[1]?.toUpperCase())) &&
                      a[a.length - 1] !== '-'
                        ? `${a} ${b}`
                        : a + b,
                    ''
                  )
                  .concat('')}
              </div>
              <div>
                <span
                  className={
                    ((parseFloat(value?.displayValue) > 100 ||
                      value?.displayValue?.toString().split('').pop() !== '%') &&
                      'bold') ||
                    ''
                  }
                >
                  {square(formatNumber(typeof value === 'object' ? value?.displayValue : value))}
                </span>
                {parseFloat(value?.displayValue) <= 100 &&
                  value?.displayValue?.toString().split('').pop() === '%' && (
                    <ProgressBar
                      value={parseFloat(value?.graphValue * 100).toString()}
                      status={parseFloat(value?.graphValue) > 0 ? 'positive' : 'negative'}
                    />
                  )}
              </div>
            </div>
          ))}
        {/* <button */}
        {/*  id="download" */}
        {/*  type="button" */}
        {/*  onClick={() => createPdf(setLoading, sideBarResponse, pdfContent)} */}
        {/* > */}
        {/*  Download Report */}
        {/* </button> */}
        <h3>Reports</h3>
        <div id="download">
          <button id="btn-PDF" type="button">
            <a
              href={`https://api.dalyenergy.com/epm/getreport?workspace=gqvwlaSj20Y&user=HVhVu9sDQsLpLfWP4W2S_Pte&id=${id}&format=pdf`}
              target="_blank"
              rel="noreferrer"
            >
              Download Report PDF
            </a>
          </button>
          <button id="btn-XLSX" type="button">
            <a
              href={`https://api.dalyenergy.com/epm/getreport?workspace=gqvwlaSj20Y&user=HVhVu9sDQsLpLfWP4W2S_Pte&id=${id}&format=xlsx`}
              target="_blank"
              rel="noreferrer"
            >
              Download Report XLSX
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnergyModelPage;

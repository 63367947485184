import React from 'react';
import ReactDOM from 'react-dom';
import Loader from '../Loader';

import './modal.css';

const Modal = ({ children, setOpen, isLoading, modalRef }) => {
  return ReactDOM.createPortal(
    <div className="modal">
      <div className="modal-content" ref={modalRef}>
        {!isLoading ? (
          <>
            <div>{children}</div>
            <button type="button" className="cancel-button" onClick={() => setOpen(false)}>
              Close
            </button>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>,
    document.getElementById('app-modal')
  );
};

export default Modal;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ref } from 'yup';

import axiosConfig from '../../utils/axiosConfig';

import Loader from '../../Components/Shared/Loader';
import InputField from '../../Components/Shared/InputField';

import './forgotPasswordPage.css';

const ForgotPasswordPage = () => {
  const url = new URLSearchParams(window.location.search);
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [fromLink, setFromLink] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (url?.get('email')) {
      setFromLink(true);
    }
  }, [history]);

  useEffect(() => {
    if (url.toString()) {
      const [, ...emailParts] = decodeURIComponent(url?.toString())
        ?.split('&')
        ?.filter((part) => part.startsWith('email'))[0]
        ?.split('=');
      setEmail(emailParts?.join(''));
    }
  }, [url]);

  return !fromLink ? (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async (values, { setErrors, setStatus }) => {
        try {
          await setLoading(true);
          await axiosConfig
            .post('api/forgotPassword', values)
            .then(() => setStatus('We sent link to your email. You can close this tab.'));
        } catch (err) {
          setLoading(false);
          setErrors({
            api: err.response.data.message,
          });
          await setLoading(false);
        }
        await setLoading(false);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required('No email provided.')
          .matches(
            /^([0-9a-z]([+\-_.]*[0-9a-z]){4,49})+@(([0-9a-z][-\w]*[0-9a-z]*\.)+[a-z0-9]{2,17})$/,
            'Invalid email address'
          ),
      })}
    >
      {(props) => {
        const { handleSubmit, status } = props;
        return (
          <div id="login-page">
            {loading ? (
              <Loader />
            ) : (
              <div className="login-box">
                <div className="logo">
                  <img src="/Icons/Logo.svg" alt="Daly Energy" />
                </div>
                <div className="slogan">
                  <div>Next Generation Solar</div>
                  <div>Storage Modeling Software</div>
                </div>
                <form className="forgot-password" onSubmit={handleSubmit}>
                  <div className="login-inputs">
                    <InputField
                      type="email"
                      name="email"
                      placeholder="Enter Your Email"
                      formikData={props}
                    />
                    {status && <div className="status">{status}</div>}
                    <div className="buttons">
                      <button type="submit">Send</button>
                      <button type="button" onClick={() => history.push('/login')}>
                        ← Go back
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        );
      }}
    </Formik>
  ) : (
    <Formik
      initialValues={{ password: '', rePassword: '' }}
      onSubmit={async (values, { setErrors }) => {
        try {
          await setLoading(true);
          // eslint-disable-next-line no-param-reassign
          await axiosConfig
            .patch(
              'api/newPassword',
              {
                email,
                password: values.password,
              },
              { params: { link: url?.get('link') } }
            )
            .then(() => history.push('/login'));
        } catch (err) {
          setLoading(false);
          setErrors({
            api: err.response.data.message,
          });
        }
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .required('No password provided.')
          .min(8, 'Password is too short - should be 8 chars minimum.')
          .max(50, 'Password is too long - should be 50 chars maximum.')
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]*)[A-Za-z\d@$!%*#?&]{8,50}$/,
            'Password must be at least 8 characters with one uppercase one lowercase and one number.'
          ),
        rePassword: Yup.string()
          .required('Need to confirm password.')
          .oneOf([ref('password')], 'Passwords do not match.'),
      })}
    >
      {(props) => {
        const { handleSubmit, values } = props;
        return (
          <div id="login-page">
            {loading ? (
              <Loader />
            ) : (
              <div className="login-box">
                <div className="logo">
                  <img src="/Icons/Logo.svg" alt="Daly Energy" />
                </div>
                <div className="slogan">
                  <div>Next Generation Solar</div>
                  <div>Storage Modeling Software</div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="login-inputs">
                    <InputField
                      name="password"
                      placeholder="Enter your Password"
                      type="password"
                      value={values.password || ''}
                      formikData={props}
                    />
                    <InputField
                      name="rePassword"
                      placeholder="Confirm Password"
                      type="password"
                      value={values.rePassword || ''}
                      formikData={props}
                    />
                    <div className="buttons">
                      <button type="submit">Accept</button>
                      <button type="button" onClick={() => history.push('/login')}>
                        ← Go back
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default ForgotPasswordPage;

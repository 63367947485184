import React from 'react';
import { useParams } from 'react-router-dom';

import { chartConfig } from '../../utils/chartConfig';

import Loader from '../../Components/Shared/Loader';
import Error from '../../Components/Shared/Error';
import Chart from '../../Components/Shared/Charts';
import { getModulesChartData } from '../../utils/adapters/chartAdapter';
import { formatNumber } from '../../utils/helpers';
import { useModulesData } from '../../hooks/useModulesData';

import './modules.css';

const ModulesPage = (props) => {
  const { id } = useParams();
  const { id: propsId } = props;

  const { data: moduleData, isLoading: loadModuleData, error } = useModulesData(propsId || id);
  const { ivCurvesChartData, aoiChartData } = getModulesChartData(moduleData);
  const { details, dropDownName } = moduleData || {};

  const {
    BifacialityFactor,
    Gamma,
    Height,
    Imp,
    Isc,
    Manufacturer,
    Model,
    NCelP,
    NCelS,
    NDiode,
    PNom,
    PNomTolLow,
    PNomTolUp,
    RSerie,
    RShunt,
    // eslint-disable-next-line camelcase
    Rp_0,
    Technol,
    Vmp,
    Voc,
    Width,
    muGamma,
    muISC,
    muPmpReq,
    muVocSpec,
  } = details || {};

  if (error) return <Error />;
  return loadModuleData ? (
    <Loader />
  ) : (
    <div className="main-content">
      <div className="properties-section">
        <div className="properties-section-title">
          <div
            className="equipment-img"
            style={{ backgroundImage: 'url(/Icons/Module-Box.svg)' }}
          />
          <div>{dropDownName}</div>
        </div>
        <hr className="divider" />
        <div>
          <div>Manufacturer:</div> <div>{Manufacturer}</div>
        </div>
        <div>
          <div>Model:</div>
          <div>{Model}</div>
        </div>
        {BifacialityFactor ? (
          <div className="module-info">
            <div>Bifaciality:</div>
            <div>{BifacialityFactor}</div>
          </div>
        ) : null}
        <div>
          <div>Technology:</div>
          <div>{Technol}</div>
        </div>
        <br />
        <div className="properties-section-heading">
          <h3>Performance at STC:</h3>
        </div>
        <div>
          <div>Voc:</div>
          <div>{formatNumber(Voc)} V</div>
        </div>
        <div>
          <div>Vmp:</div>
          <div>{formatNumber(Vmp)} V</div>
        </div>
        <div>
          <div>Isc:</div>
          <div>{formatNumber(Isc)} A</div>
        </div>
        <div>
          <div>Imp:</div>
          <div>{formatNumber(Imp)} A</div>
        </div>
        <div>
          <div>Pmp:</div>
          <div>{`${formatNumber(PNom)}W - ${formatNumber(PNomTolLow)}% / ${formatNumber(
            PNomTolUp
          )}%`}</div>
        </div>
        <br />
        <div className="properties-section-heading">
          <h3>Module Physical Characteristics:</h3>
        </div>
        <div>
          <div>Length:</div>
          <div>{formatNumber(Height)} m</div>
        </div>
        <div>
          <div>Width:</div> <div>{formatNumber(Width)} m</div>
        </div>
        <div>
          <div>Number of Cell:</div> <div>{formatNumber(NCelS)}</div>
        </div>
        <div>
          <div>Number of Cells in Parallel:</div> <div>{formatNumber(NCelP)}</div>
        </div>
        <div>
          <div>Number of Diodes:</div> <div>{formatNumber(NDiode)}</div>
        </div>
        <br />
        <div className="properties-section-heading">
          <h3>Module Performance Parameters:</h3>
        </div>
        <div>
          <div>MUisc:</div>
          <div>{muISC} °C</div>
        </div>
        <div>
          <div>MUvoc:</div>
          <div>{muVocSpec} °C</div>
        </div>
        <div>
          <div>MUpmp:</div> <div>{muPmpReq} °C</div>
        </div>
        <div>
          <div>RShunt:</div> <div>{formatNumber(RShunt)} Ω</div>
        </div>
        <div>
          {/* eslint-disable-next-line camelcase */}
          <div>Rp_0:</div> <div>{formatNumber(Rp_0)} Ω</div>
        </div>
        <div>
          <div>RSeries:</div>
          <div>{formatNumber(RSerie)} Ω</div>
        </div>
        <div>
          <div>Gamma:</div> <div>{formatNumber(Gamma)} Ω</div>
        </div>
        <div>
          <div>MUGamma:</div> <div>{formatNumber(muGamma)}</div>
        </div>
      </div>
      <div className="chart-section">
        <Chart
          chartData={{
            ...chartConfig,
            series: ivCurvesChartData,
            title: {
              text: 'IV Curves',
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Current (Amps)',
              },
            },
            xAxis: {
              min: 0,
              title: {
                text: 'Voltage (Volts)',
              },
            },
            tooltip: {
              headerFormat: '<span class="location-chart-tooltip"/>',
              pointFormat:
                '<span class="location-chart-tooltip">Voltage {point.category} Volts; Current {point.y}<br/>{series.name}</span>',
              valueSuffix: ' Amps',
              useHTML: true,
            },
          }}
        />
        <hr />
        <Chart
          chartData={{
            ...chartConfig,
            series: [
              {
                data: aoiChartData,
                type: 'spline',
                color: '#FFA22C',
              },
            ],
            legend: false,
            title: {
              text: 'AOI  vs Incident Angle',
            },

            yAxis: {
              title: {
                text: 'Fraction',
              },
            },
            xAxis: {
              title: {
                text: 'Angle of Incidence - AOI (degrees)',
              },
            },
            tooltip: {
              headerFormat: '<span class="location-chart-tooltip"/>',
              pointFormat:
                '<span class="location-chart-tooltip">AOI {point.category} °; Fraction {point.y}</span>',
              useHTML: true,
            },
          }}
        />
      </div>
    </div>
  );
};
export default ModulesPage;

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Loader from '../../Components/Shared/Loader';
import Card from '../../Components/Shared/Card';
import Error from '../../Components/Shared/Error';
import Toast from '../../Components/Shared/Toast';
import SearchBar from '../../Components/Shared/SearchBar';
import { useOptionsList } from '../../hooks/useOptionsList';
import useToast from '../../hooks/useToast';
import { TOAST_TYPES } from '../../utils/constants';

import './locationsListPage.css';

const LocationsListPage = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const {
    data: locationsOptionsList,
    setData: setLocationsOptionsList,
    isLoading: loadOptionsList,
    setLoading: setLoadOptionsList,
    error: errorOptionsList,
  } = useOptionsList(pathname);

  const [currentList, setCurrentList] = useState([]);
  const [searchOption, setSearchOption] = useState('');
  const { toastList, showToast } = useToast();

  useEffect(() => {
    setCurrentList(locationsOptionsList);
  }, [locationsOptionsList]);

  useEffect(() => {
    setCurrentList(
      locationsOptionsList.filter(({ dropDownName }) =>
        dropDownName.toUpperCase().includes(searchOption.toString().toUpperCase())
      )
    );
  }, [searchOption]);

  useEffect(() => {
    if (history.location.state?.from === '/locations-upload') {
      history.replace({ ...history.location, state: undefined });
      showToast('Location being processed', TOAST_TYPES.success);
    }
  }, []);

  const renderLocationsList = () => {
    if (errorOptionsList) {
      return <Error />;
    }
    if (loadOptionsList) {
      return <Loader />;
    }
    if (!loadOptionsList && !currentList.length) {
      return <div className="no-data">No Locations in Workspace</div>;
    }
    return currentList?.map((data) => (
      <Card
        type="locations"
        key={data.id}
        data={data}
        setList={setLocationsOptionsList}
        setLoading={setLoadOptionsList}
        showToast={showToast}
      />
    ));
  };
  return (
    <>
      <Toast toastList={toastList} />
      <SearchBar
        searchOption={searchOption}
        setSearchOption={setSearchOption}
        uploadName="Create New Location"
        header="Locations"
      />
      <div className="list-section">{renderLocationsList()}</div>
    </>
  );
};

export default LocationsListPage;

import { useEffect, useState } from 'react';

export const getLocationsChartData = (weatherData) => {
  const [totalGHIChartData, setTotalGHIChartData] = useState([]);
  const [ghiChartData, setGhiChartData] = useState([]);
  const [dhiChartData, setDhiChartData] = useState([]);
  const [dniChartData, setDniChartData] = useState([]);
  const [temperatureChartData, setTemperatureChartData] = useState([]);
  const [soilingChartData, setSoilingChartData] = useState([]);
  const [elevationAnglesChartData, setElevationAnglesChartData] = useState([]);

  const {
    dhi,
    dni,
    ghi,
    soiling,
    temperature: temperatureData,
    totalGhiByMonths,
    elevationAnglesGraph: elevationAngles,
  } = weatherData || [];

  useEffect(() => {
    if (totalGhiByMonths) {
      setTotalGHIChartData(Object.values(totalGhiByMonths).map(({ parameter }) => parameter));
    }
    if (dhi) {
      setDhiChartData(
        Object.keys(dhi)?.map((month) => ({
          name: month,
          color: month.color,
          data: dhi[month].map(({ hour, parameter }) => ({ x: hour, y: parameter })),
        }))
      );
    }
    if (dni) {
      setDniChartData(
        Object.keys(dni)?.map((month) => ({
          name: month,
          color: month.color,
          data: dni[month].map(({ hour, parameter }) => ({ x: hour, y: parameter })),
        }))
      );
    }
    if (ghi) {
      setGhiChartData(
        Object.keys(ghi)?.map((month) => ({
          name: month,
          data: ghi[month].map(({ hour, parameter }) => ({ x: hour, y: parameter })),
        }))
      );
    }
    if (temperatureData) {
      setTemperatureChartData(
        Object.keys(temperatureData)?.map((month) => ({
          name: month,
          color: month.color,
          data: temperatureData[month].map(({ hour, parameter }) => ({
            x: hour,
            y: parameter,
          })),
        }))
      );
    }
    if (soiling) {
      setSoilingChartData(Object.values(soiling).map(({ parameter }) => parameter));
    }
    if (elevationAngles) {
      const elevationAnglesData = [];
      for (let i = 0; i < elevationAngles?.azimuth?.length; i += 1) {
        elevationAnglesData.push({
          x: elevationAngles?.azimuth[i],
          y: Math.round(elevationAngles?.elevationAngles[i] * 100000) / 100000,
        });
      }
      setElevationAnglesChartData(elevationAnglesData);
    }
    return () => {
      setTotalGHIChartData([]);
      setGhiChartData([]);
      setDhiChartData([]);
      setDniChartData([]);
      setTemperatureChartData([]);
      setSoilingChartData([]);
      setElevationAnglesChartData([]);
    };
  }, [totalGhiByMonths, dhi, dni, ghi, temperatureData, soiling, elevationAngles]);

  return {
    totalGHIChartData,
    ghiChartData,
    dhiChartData,
    dniChartData,
    temperatureChartData,
    soilingChartData,
    elevationAnglesChartData,
  };
};

export const getEPMChartData = (EPMData) => {
  const [yieldByMonthChartData, setYieldByMonthChartData] = useState([]);
  const [yieldByMonthAndHourChartData, setYieldByMonthAndHourChartData] = useState([]);
  const { yieldByMonthGraph, yieldByMonthAndHour } = EPMData || {};

  useEffect(() => {
    if (yieldByMonthGraph) {
      setYieldByMonthChartData(Object.values(yieldByMonthGraph).map(({ parameter }) => parameter));
    }
    if (yieldByMonthAndHour) {
      setYieldByMonthAndHourChartData(
        Object.keys(yieldByMonthAndHour)?.map((month) => ({
          name: month,
          color: month.color,
          data: yieldByMonthAndHour[month].map(({ hour, parameter }) => ({
            x: hour,
            y: parameter,
          })),
        }))
      );
    }
    return () => {
      setYieldByMonthChartData([]);
      setYieldByMonthAndHourChartData([]);
    };
  }, [yieldByMonthGraph]);

  return { yieldByMonthChartData, yieldByMonthAndHourChartData };
};

export const getModulesChartData = (moduleData) => {
  const [ivCurvesChartData, setIvCurvesChartData] = useState([]);
  const [aoiChartData, setAoiChartData] = useState([]);
  const { graphs } = moduleData || {};
  const { aoi: aoiChart, ivCurves } = graphs || {};
  const { aoi, fraction } = aoiChart || {};

  useEffect(() => {
    const AOICurve = [];
    if (aoi && fraction) {
      for (let i = 0, k = 0; i < aoi.length && k < fraction.length; i += 1, k += 1) {
        AOICurve.push({
          x: aoi[i],
          y: fraction[k],
        });
      }
      setAoiChartData(AOICurve);
    }

    if (ivCurves) {
      const IVCurve = ivCurves.map(({ condition, curvedata }) => ({
        name: `${condition?.Irrad} W/m<sup>2</sup> | ${condition?.Temp} °C`,
        color: condition.Color,
        data: curvedata.map(({ irradiance, voltage }) => ({ x: voltage, y: irradiance })),
      }));
      setIvCurvesChartData(IVCurve);
    }
  }, [aoi, fraction, ivCurves]);

  return { ivCurvesChartData, aoiChartData };
};

export const getInvertersChartData = (inverterData) => {
  const [inverterChartData, setInverterChartData] = useState([]);

  const { graphs } = inverterData || {};
  const { efficiencyCurves } = graphs || {};

  useEffect(() => {
    const curveData = [];
    if (efficiencyCurves) {
      efficiencyCurves
        .map((efficiencyCurvesChartData) => efficiencyCurvesChartData)
        .map(({ parametersOfEfficiencyCurves }) =>
          parametersOfEfficiencyCurves.map(({ typeOfEfficiencyCurves, coordinates }) => ({
            name: typeOfEfficiencyCurves,
            type: 'spline',
            data: coordinates.map(({ efficiency, percent }) => ({
              x: Math.round(percent * 100000) / 100000,
              y: Math.round(efficiency * 100000) / 100000,
            })),
          }))
        )
        .map((data) => curveData.push(...data));
      setInverterChartData(curveData);
    }
  }, [efficiencyCurves]);

  return inverterChartData;
};

export const getTemperatureChartData = (inverterData) => {
  const [inverterChartData, setInverterChartData] = useState([]);

  const { inverterPowerTemperatureGraph } = inverterData || {};
  const { powerLevels, temperatures } = inverterPowerTemperatureGraph || {};

  useEffect(() => {
    const curveData = [];
    if (inverterPowerTemperatureGraph) {
      for (let i = 0, k = 0; i < powerLevels.length && k < temperatures.length; i += 1, k += 1) {
        curveData.push({
          y: powerLevels[i],
          x: temperatures[k],
        });
      }
      setInverterChartData(curveData);
    }
  }, [inverterPowerTemperatureGraph]);
  return inverterChartData;
};

import { useEffect, useState } from 'react';
import axiosConfig from '../utils/axiosConfig';

export const useLocationsData = () => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      await setLoading(true);
      await axiosConfig
        .get(`/api/locations/home`)
        .then((response) => {
          if (response) setData(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    })();
    return () => setData([]);
  }, []);

  return {
    data,
    isLoading,
    error,
  };
};

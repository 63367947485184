import React, { useEffect, useState } from 'react';
import './dashboardPage.css';
import { useOptionsList } from '../../hooks/useOptionsList';
import Error from '../../Components/Shared/Error';
import Loader from '../../Components/Shared/Loader';

const DashboardPage = () => {
  const { data: usersList, isLoading: usersLoading, error: usersError } = useOptionsList('/users');

  const [currentList, setCurrentList] = useState([]);
  const [searchOption, setSearchOption] = useState('');

  useEffect(() => {
    if (usersList.length) setCurrentList(usersList);
  }, [usersList]);

  useEffect(() => {
    setCurrentList(
      usersList.filter(({ fullName }) =>
        fullName.toUpperCase().includes(searchOption.toString().toUpperCase())
      )
    );
  }, [searchOption]);
  if (usersError) return <Error />;
  return usersLoading ? (
    <Loader />
  ) : (
    <div id="dashboard-page">
      <div className="search-section ml-0">
        <h1>Dashboard</h1>
      </div>
      <section className="table-section mt-100">
        <table className="fixed_header">
          <thead>
            <tr className="table-head">
              <th>
                <h3>Users</h3>
              </th>
            </tr>
          </thead>
          <thead>
            <tr className="table-head">
              <th>
                <input
                  type="text"
                  value={searchOption}
                  onChange={(e) => setSearchOption(e.target.value)}
                  placeholder="&#xF002; Search"
                  className="search-input"
                />
              </th>
            </tr>
          </thead>
          <thead>
            <tr className="dashboard-head-row">
              <th className="w-10">ID</th>
              <th>Email</th>
              <th className="w-20">Full Name</th>
              <th>Workspaces</th>
            </tr>
          </thead>
          <tbody>
            {currentList.map(({ fullName, email, id, workspaces }) => (
              <tr key={id} className="dashboard-body-row">
                <td className="w-10">{id}</td>
                <td>{email}</td>
                <td className="w-20">{fullName}</td>
                <td>
                  {workspaces.length ? (
                    workspaces.map(({ id: workspaceId, name: workspaceName }) => (
                      <span
                        role="presentation"
                        onClick={() => workspaces.splice(id)}
                        className="workspace-pill"
                        key={workspaceId}
                      >
                        {workspaceName}
                      </span>
                    ))
                  ) : (
                    <span>There are no workspaces yet...</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default DashboardPage;

import React, { useEffect, useRef, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import axiosConfig from '../../utils/axiosConfig';
import { formatNumber } from '../../utils/helpers';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useEPMComponents } from '../../hooks/useEPMComponents';

import Accordion from '../../Components/Shared/Accordion';
import DataTable from '../../Components/Shared/DataTable';
import Modal from '../../Components/Shared/Modal';
import LocationMap from '../../Components/Shared/Maps';
import Loader from '../../Components/Shared/Loader';
import Error from '../../Components/Shared/Error';
import InputField from '../../Components/Shared/InputField';
import EPMtab from '../../Components/Shared/EPMtab';
import LocationsPage from '../LocationsPage';

import { EPMInitialValues, EPMValidationSchema } from './EPMSchema';

import 'react-tabs/style/react-tabs.css';
import './ProductionModelUploadPage.css';

const ProductionModelUploadPage = () => {
  const selectRef = useRef();
  const modalRef = useRef();
  const history = useHistory();

  const [locations, setLocations] = useState();
  const [modules, setModules] = useState();
  const [inverters, setInverters] = useState();
  const [scenes, setScenes] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedModule, setSelectedModule] = useState();
  const [selectedInverter, setSelectedInverter] = useState();
  const [selectedScene, setSelectedScene] = useState();
  const [mapCenter, setMapCenter] = useState();
  const [weatherData, setWeatherData] = useState();
  const [simulationStatus, setSimulationStatus] = useState('Simulation not complete');
  const [loading, setLoading] = useState(false);
  const [loadingBox, setLoadingBox] = useState(false);
  const [simulated, setSimulated] = useState(false);
  const [loadError, setLoadError] = useState('');
  const [mapZoom, setMapZoom] = useState(5);
  const [searchOption, setSearchOption] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [tabsList, setTabsList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isRunSimulationDisabled, setRunSimulationDisabled] = useState(true);

  const [blocks, setBlocks] = useState({});

  useOnClickOutside(modalRef, () => setOpen(false));

  const { data, isLoading, error, setError, setLoading: setGlobalLoading } = useEPMComponents();
  const { invertersDropDownList, locationsList, modulesDropDownList, octaviaList } = data || {};

  const runSimulation = async (action, values) => {
    // eslint-disable-next-line no-param-reassign
    await setLoadingBox(true);
    await setSimulated(true);
    const simulationValues = {
      ...values,
      name: values.name,
      selectedLocation: +selectedLocation.globalId, // TODO replace with +selectedLocation.id
    };
    try {
      const response = await axiosConfig.post(`/api/energyModels/${action}`, {
        ...simulationValues,
        blocks: Object.values(blocks),
      });
      await setSimulationStatus(`${response?.data?.specificYield} kWh/kWp`);
      await setLoadingBox(false);
    } catch (e) {
      setError(true);
    }
  };

  // const saveEnergyModel = async (values) => {
  //   await setGlobalLoading(true);
  //   const simulationValues = {
  //     ...values,
  //     dcOhmic: values.dcOhmic / 100,
  //     acohmicLoss: values.acohmicLoss / 100,
  //     moduleQuality: values.moduleQuality / 100,
  //     mismatch: values.mismatch / 100,
  //     lId: values.lId / 100,
  //     stringVoltageMismatch: values.stringVoltageMismatch / 100,
  //     rackingShading: values.rackingShading ? values.rackingShading / 100 : null,
  //     irradianceMismatchLoss: values.irradianceMismatchLoss
  //       ? values.irradianceMismatchLoss / 100
  //       : null,
  //     moduleTransparency: values.moduleTransparency ? values.moduleTransparency / 100 : null,
  //     trackerHeight: values?.trackerHeight ? values.trackerHeight : null,
  //     selectedLocation: +selectedLocation.globalId,
  //     selectedInverter: +selectedInverter,
  //     selectedModule: +selectedModule,
  //     selectedScene: +selectedScene,
  //   };
  //   try {
  //     const response = await axiosConfig.post('/api/energyModels/save', simulationValues);
  //     await setGlobalLoading(false);
  //     await history.push(`energyModels/${response.data}`);
  //   } catch (e) {
  //     setError(true);
  //     setGlobalLoading(false);
  //   }
  // };

  useEffect(() => {
    if (locationsList?.length) {
      setLocations(locationsList);
      setMapCenter({
        lat: locationsList[0]?.latitude,
        lng: locationsList[0]?.longitude,
      });
    }
    if (modulesDropDownList?.length) setModules(modulesDropDownList);
    if (invertersDropDownList?.length) setInverters(invertersDropDownList);
    if (octaviaList?.length) setScenes(octaviaList);
    if (locationsList && invertersDropDownList && modulesDropDownList) {
      setSelectedTab(1);
      // eslint-disable-next-line no-shadow
      setTabsList((tabsList) => [...tabsList, { id: 1, title: 'Block Type 1' }]);
      // eslint-disable-next-line no-shadow
      setTabs((tabs) => [
        ...tabs,
        {
          id: 1,
        },
      ]);
    }
  }, [locationsList, invertersDropDownList, modulesDropDownList, octaviaList]);

  useEffect(() => {
    if (locationsList?.length) {
      setLocations(
        locationsList.filter(({ dropDownName }) =>
          dropDownName.toUpperCase().includes(searchOption.toString().toUpperCase())
        )
      );
    } else {
      setLocations([]);
    }
  }, [locationsList, searchOption]);

  const handleSelectLocation = (location) => {
    setSelectedLocation(location);
    if (location.id !== selectedLocation?.id) {
      setMapZoom(8);
    }
    setMapCenter({
      lat: location.latitude,
      lng: location.longitude,
    });
  };
  const handleAddBlockType = () => {
    setRunSimulationDisabled(true);
    const tabId = tabs[tabs.length - 1]?.id;
    // eslint-disable-next-line no-shadow
    setTabs((tabs) => [...tabs, { id: tabId + 1 }]);
    // eslint-disable-next-line no-shadow
    setTabsList((tabsList) => [...tabsList, { id: tabId + 1, title: `Block Type ${tabId + 1}` }]);
  };

  const deleteBlock = (id, e) => {
    e.preventDefault();
    // eslint-disable-next-line no-shadow
    setTabs((tabs) => tabs.filter((tab) => tab.id !== id));
    // eslint-disable-next-line no-shadow
    setTabsList((tabsList) => tabsList.filter((tab) => tab.id !== id));
    delete blocks[id];
  };

  if (error) return <Error />;
  // eslint-disable-next-line no-nested-ternary
  return isLoading ? (
    <Loader />
  ) : (
    <Formik
      initialValues={EPMInitialValues}
      validationSchema={EPMValidationSchema}
      enableReinitialize={false}
    >
      {(props) => {
        const { values, touched, errors, handleChange } = props;
        useEffect(() => {
          setRunSimulationDisabled(
            Object.keys(errors).length !== 0 ||
              values.name === '' ||
              !selectedLocation ||
              !selectedInverter ||
              !selectedModule ||
              (blocks[selectedTab].shadingModel === '3D' && !selectedScene) ||
              Object.values(blocks).some((block) => JSON.stringify(block?.errors) !== '{}') ||
              Object.values(blocks).some((block) => JSON.stringify(block?.touched) === '{}')
          );
        }, [
          errors,
          values,
          selectedLocation,
          selectedInverter,
          selectedModule,
          blocks[selectedTab]?.shadingModel,
          blocks[selectedTab]?.errors,
        ]);

        useEffect(() => {
          if (!isRunSimulationDisabled) {
            setSimulationStatus('Inputs Ready');
          } else {
            setSimulationStatus('Simulation not complete');
          }
        }, [isRunSimulationDisabled]);

        return (
          <div className="main-content">
            <div id="production-model-page">
              {isOpen && (
                <Modal setOpen={setOpen} isLoading={loading} modalRef={modalRef}>
                  {!loadError ? (
                    <DataTable weatherData={weatherData?.weatherData?.weatherData} />
                  ) : (
                    <p className="modal-error">Something went wrong... :(</p>
                  )}
                </Modal>
              )}
              <div className="w-70">
                <div className="name-input">
                  <h1>New Energy Production Model</h1>
                  <InputField
                    type="text"
                    name="name"
                    label="Simulation Name"
                    placeholder="Enter new simulation name here"
                    tooltipContent="Field is required, length should be up to 50 chars"
                    onChange={(e) => {
                      handleChange(e);
                      setSimulated(false);
                    }}
                    className={`ml-0 ${
                      (errors.api && 'input-error') ||
                      (errors.name && touched.name && 'input-error')
                    }`}
                    formikData={props}
                  />
                </div>
                <Accordion
                  label={
                    <div>
                      <span>Workspace Locations</span>
                      <span className="accordion-title-data">
                        {selectedLocation
                          ? `${selectedLocation.dropDownName} ${new Date(
                              selectedLocation?.dateRun * 1000
                            ).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })} ${selectedLocation.formattedAddress}`
                          : ''}
                      </span>
                    </div>
                  }
                  content={
                    <div className="locations-data">
                      <input
                        type="text"
                        className="locations-list-search"
                        placeholder="&#xF002; Search Location"
                        value={searchOption}
                        onChange={(e) => setSearchOption(e.target.value)}
                      />
                      {locations?.length !== 0 ? (
                        <div className="locations-list-wrapper">
                          <div className="locations-list">
                            {locations?.map((location) => (
                              <div
                                className={`location-item ${
                                  selectedLocation?.id === location.id ? 'active' : ''
                                }`}
                                key={location.dateRun * 1000}
                                role="presentation"
                                onClick={() => {
                                  handleSelectLocation(location);
                                  setSimulated(false);
                                }}
                              >
                                <div className="location-item-data">
                                  <img
                                    className="list-image"
                                    src="/Icons/Location.svg"
                                    alt="List item logo"
                                  />
                                  {location.dropDownName}
                                </div>
                                <div className="location-item-data">
                                  <img className="list-image" src="/Icons/Date.svg" alt="date" />
                                  {new Date(location.dateRun * 1000).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                  })}
                                </div>
                                <div className="location-item-data">
                                  {location.formattedAddress}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className="items-list-empty">
                          There are no Locations yet. Please create!
                        </div>
                      )}
                    </div>
                  }
                />
                <div className="map-section production-model-map">
                  <div className="production-model-map-inner">
                    <LocationMap
                      options={{
                        center: mapCenter,
                        zoom: mapZoom,
                      }}
                      data={locations}
                      infoWindowContent={['name', 'date', 'GHI']}
                      handleChangeZoom={setMapZoom}
                      handleSelectLocation={handleSelectLocation}
                    />
                  </div>
                </div>
              </div>

              <div className="w-70">
                {selectedLocation && (
                  <Accordion
                    label={
                      <div className="accordion-label">
                        <b>{selectedLocation ? selectedLocation?.dropDownName : 'Location'}</b>
                        {selectedLocation && (
                          <div className="accordion-label-date">
                            <img
                              className="workspace-locations-image"
                              src="/Icons/Date.svg"
                              alt="date"
                            />
                            {new Date(selectedLocation?.dateRun * 1000).toLocaleDateString(
                              'en-US',
                              {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                              }
                            )}
                          </div>
                        )}
                        {selectedLocation && (
                          <div className="accordion-label-date">
                            {selectedLocation && selectedLocation?.formattedAddress}
                          </div>
                        )}
                      </div>
                    }
                    content={<LocationsPage id={selectedLocation?.globalId} />}
                  />
                )}
              </div>

              {selectedLocation && (
                <Tabs forceRenderTabPanel className="w-70">
                  <TabList className="tabs-list">
                    {blocks &&
                      tabsList?.map(({ id, title }) => (
                        <Tab
                          key={new Date().getTime() + id}
                          onClick={() => setSelectedTab(id)}
                          className={
                            JSON.stringify(blocks[id]?.errors) !== '{}'
                              ? `react-tabs__tab tab-error`
                              : `react-tabs__tab`
                          }
                        >
                          {title}
                          {tabsList.length > 1 && (
                            <button
                              type="button"
                              className="delete-block"
                              onClick={(e) => deleteBlock(id, e)}
                            >
                              <span>X</span>
                            </button>
                          )}
                        </Tab>
                      ))}
                    {tabsList.length < 10 && (
                      <button
                        type="button"
                        className="add-tab"
                        onClick={() => handleAddBlockType()}
                      >
                        ＋
                      </button>
                    )}
                  </TabList>

                  {tabsList?.map(({ id }) => (
                    <TabPanel key={id}>
                      <EPMtab
                        modules={modulesDropDownList}
                        inverters={invertersDropDownList}
                        locations={locationsList}
                        scenes={scenes}
                        selectedLocation={selectedLocation}
                        selectedInverter={selectedInverter}
                        selectedModule={selectedModule}
                        selectedScene={selectedScene}
                        setSelectedInverter={setSelectedInverter}
                        setSelectedModule={setSelectedModule}
                        setSelectedScene={setSelectedScene}
                        selectRef={selectRef}
                        setLoadError={setLoadError}
                        setWeatherData={setWeatherData}
                        setSelectedLocation={setSelectedLocation}
                        setMapZoom={setMapZoom}
                        setMapCenter={setMapCenter}
                        searchOption={searchOption}
                        setSearchOption={setSearchOption}
                        setSimulated={setSimulated}
                        setLoading={setLoading}
                        setOpen={setOpen}
                        setBlocks={setBlocks}
                        id={id}
                        blocks={blocks}
                      />
                    </TabPanel>
                  ))}
                </Tabs>
              )}
              {selectedLocation && (
                <section className="prediction-section">
                  <div className="prediction-container">
                    {loadingBox ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="prediction-section-content">
                          <h3>EPM Input Summary</h3>
                          <span
                            className={simulationStatus === 'Inputs Ready' ? 'inputs-success' : ''}
                          >
                            {simulationStatus}
                          </span>

                          <div className="data-and-location">
                            <span>
                              <img
                                className="workspace-locations-image"
                                src="/Icons/Date.svg"
                                alt="date"
                              />
                              {new Date().toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                              })}
                            </span>
                            <span>
                              <img
                                className="workspace-locations-image"
                                src="/Icons/Location.svg"
                                alt="location"
                              />
                              {selectedLocation?.dropDownName}
                            </span>
                          </div>

                          <hr className="divider" />
                          <h3>
                            {tabsList.length !== 0 &&
                              tabsList.find(({ id }) => id === +selectedTab)?.title}
                          </h3>
                          <div className="orange">DC System:</div>
                          <div>
                            {blocks[selectedTab]?.arrayType &&
                              blocks[selectedTab]?.shadingModel === '2D' &&
                              `Array Type: ${blocks[selectedTab]?.arrayType}`}
                          </div>
                          <div>{values.gCR && `GCR: ${blocks[selectedTab]?.gCR}`}</div>
                          {selectedModule && (
                            <>
                              <div>
                                {`Module: ${
                                  modules?.find(({ id }) => id === +selectedModule).modelName
                                }`}
                              </div>
                              <div>
                                {`Module Wattage: ${formatNumber(
                                  modules?.find(({ id }) => id === +selectedModule).pnom
                                )} W`}
                              </div>
                              {blocks[selectedTab]?.stringLength && (
                                <div>{`String Length: ${formatNumber(
                                  blocks[selectedTab]?.stringLength
                                )}`}</div>
                              )}
                            </>
                          )}
                          {selectedModule &&
                            blocks[selectedTab]?.stringCount &&
                            blocks[selectedTab]?.stringLength && (
                              <div>
                                {`DC System Size: ${formatNumber(
                                  (modules?.find(({ id }) => id === +selectedModule).pnom *
                                    blocks[selectedTab]?.stringLength *
                                    blocks[selectedTab]?.stringCount) /
                                    1000
                                )}  kW`}
                              </div>
                            )}
                        </div>

                        <div className="orange">AC System:</div>
                        {selectedInverter && (
                          <>
                            <div>
                              {`Inverter: ${
                                inverters?.find(({ id }) => id === +selectedInverter).modelName
                              }`}
                            </div>
                            <div>
                              {`Inverter Nominal AC: ${formatNumber(
                                inverters?.find(({ id }) => id === +selectedInverter)?.pnomConv
                              )} kW`}
                            </div>
                            {blocks[selectedTab]?.stringCount && (
                              <div>{`String Count: ${formatNumber(
                                blocks[selectedTab]?.stringCount
                              )}`}</div>
                            )}
                            {selectedInverter && values.stringCount && (
                              <div>
                                {`DC/AC Ratio: ${(
                                  (modules?.find(({ id }) => id === +selectedModule).pnom *
                                    blocks[selectedTab]?.stringLength *
                                    blocks[selectedTab]?.stringCount) /
                                  (inverters?.find(({ id }) => id === +selectedInverter)?.pnomConv *
                                    1000)
                                ).toFixed(3)}`}
                              </div>
                            )}
                          </>
                        )}
                        <hr className="divider" />
                        <h3 className="center">Block Type Total</h3>

                        {selectedInverter &&
                          selectedModule &&
                          blocks[selectedTab]?.numberOfInverters && (
                            <>
                              <div>{`Number of Inverters: ${formatNumber(
                                blocks[selectedTab]?.numberOfInverters
                              )}`}</div>
                              {blocks[selectedTab]?.stringLength &&
                                blocks[selectedTab]?.stringCount && (
                                  <div>
                                    {`Total DC System Size: ${formatNumber(
                                      (modules?.find(({ id }) => id === +selectedModule).pnom *
                                        blocks[selectedTab]?.stringLength *
                                        blocks[selectedTab]?.stringCount *
                                        blocks[selectedTab].numberOfInverters) /
                                        1000
                                    )} kW`}
                                  </div>
                                )}
                              <div>
                                {`Total AC System Size: ${formatNumber(
                                  inverters?.find(({ id }) => id === +selectedInverter)?.pnomConv *
                                    blocks[selectedTab].numberOfInverters
                                )} kW`}
                              </div>
                            </>
                          )}
                        <hr className="divider" />
                        <h3 className="center blue">Total Plant</h3>

                        {selectedInverter &&
                          selectedModule &&
                          blocks[selectedTab]?.numberOfInverters && (
                            <>
                              <div>{`Number of Inverters: ${formatNumber(
                                Object.values(blocks).reduce(
                                  (acc, cur) => acc + cur.numberOfInverters,
                                  0
                                )
                              )}`}</div>
                              {blocks[selectedTab]?.stringLength &&
                                blocks[selectedTab]?.stringCount && (
                                  <div>
                                    {`Total DC System Size: ${formatNumber(
                                      Object.values(blocks).reduce(
                                        (acc, cur) => acc + cur.DCvalue,
                                        0
                                      )
                                    )} kW`}
                                  </div>
                                )}
                              <div>
                                {`Total AC System Size: ${formatNumber(
                                  Object.values(blocks).reduce((acc, cur) => acc + cur.ACvalue, 0)
                                )} kW`}
                              </div>
                            </>
                          )}
                        <div className="buttons">
                          {!simulated ? (
                            <button
                              type="button"
                              className="success-btn"
                              disabled={isRunSimulationDisabled}
                              onClick={() => runSimulation('run', values)}
                            >
                              Run Simulation
                            </button>
                          ) : (
                            <>
                              <button type="button" onClick={() => runSimulation('save', values)}>
                                Save
                              </button>
                              <a
                                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                  JSON.stringify({ ...values, blocks }, undefined, 2)
                                )}`}
                                download={`${values?.name}.json`}
                                className="download-button"
                              >
                                Download
                              </a>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </section>
              )}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default ProductionModelUploadPage;

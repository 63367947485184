import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import LocationMap from '../../Components/Shared/Maps';
import ItemsList from '../../Components/Shared/ItemsList';
import Chart from '../../Components/Shared/Charts';
import Loader from '../../Components/Shared/Loader';
import Error from '../../Components/Shared/Error';
import { useLocationsData } from '../../hooks/useLocationsData';
import { useGeolocationCoords } from '../../hooks/useGeolocationCoords';
import { UserContext } from '../../context/userContext';
import axiosConfig from '../../utils/axiosConfig';
import { formatNumber, square } from '../../utils/helpers';
import { chartConfig } from '../../utils/chartConfig';

import './home.css';

const Home = () => {
  const [mapCenter, setMapCenter] = useState();
  const { defaultCoords } = useGeolocationCoords();
  const [locations, setLocations] = useState();
  const { userInfo } = useContext(UserContext);
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedEnergyModel, setSelectedEnergyModel] = useState();
  const [energyModelData, setEnergyModelData] = useState();
  const [energyModelList, setEnergyModelList] = useState([]);
  const [isModelListLoading, setModelListLoading] = useState(false);
  const [isModelDataLoading, setModelDataLoading] = useState(false);
  const [modelListError, setModelListError] = useState(false);
  const [modelDataError, setModelDataError] = useState(false);
  const [mapZoom, setMapZoom] = useState(5);
  const [scrollHeight, setScrollHeight] = useState(document.documentElement.scrollHeight);

  const {
    data: locationsData,
    isLoading: isLocationDataLoading,
    error: locationListError,
  } = useLocationsData();
  const { locationList, workspaceName } = locationsData || {};

  useEffect(() => {
    if (locationList?.length) {
      setLocations(locationList);
      setMapCenter({
        lat: locationList[0]?.latitude,
        lng: locationList[0]?.longitude,
      });
    }
  }, [locationList]);

  useLayoutEffect(() => {
    setScrollHeight(document.body.scrollHeight);
  }, [isModelDataLoading, isModelListLoading]);

  const handleSelectEnergyModel = async (model) => {
    await setSelectedEnergyModel(model);
    if (model.id !== selectedEnergyModel?.id) {
      await setModelDataLoading(true);
      await setModelDataError(null);
      await axiosConfig
        .get(`/api/energyModels/home/card`, {
          params: {
            id: model.id,
          },
        })
        .then((response) => {
          if (response) setEnergyModelData(response.data);
          setModelDataLoading(false);
        })
        .catch((err) => {
          setEnergyModelData(null);
          setModelDataError(err);
          setModelDataLoading(false);
        });
    }
  };

  const handleSelectLocation = async (location) => {
    await setSelectedLocation(location);
    await setModelDataError(null);
    await setEnergyModelData(null);
    await setMapCenter({
      lat: location.latitude,
      lng: location.longitude,
    });
    if (location.id !== selectedLocation?.id) {
      await setMapZoom(8);
      await setSelectedEnergyModel(null);
      await setModelListLoading(true);
      await axiosConfig
        .get('/api/energyModels/home', {
          params: {
            id: location.id,
          },
        })
        .then((response) => {
          if (response) setEnergyModelList(response.data);
          setModelListLoading(false);
        })
        .catch((err) => {
          setModelListError(err);
          setModelListLoading(false);
        });
    }
  };

  if (locationListError)
    return (
      <div className="tips">
        <h1>
          Welcome to Daly Energy, {userInfo?.firstName} {userInfo?.lastName}
        </h1>
        <h3>
          <center>
            For now you don`t have any workspaces for starting our process. We would recommend you
            to follow TODO list bellow:
          </center>
        </h3>
        <ul>
          <li>
            Go to your personal{' '}
            <Link className="orange" to="/settings">
              settings
            </Link>
          </li>
          <li>Scroll to Workspace section and select one from the list.</li>
          <li>
            If you don`t have workspace - please create a new one and then select it from the list.
          </li>
        </ul>
      </div>
    );

  return isLocationDataLoading ? (
    <Loader />
  ) : (
    <div className={`home-page ${window.innerHeight > scrollHeight ? 'scrollbar' : ''}`}>
      {locationsData && (
        <>
          <h1>Home Page - {workspaceName}</h1>
          <div className="map-section home-page-map">
            <div className="map-container">
              <LocationMap
                options={{
                  center: mapCenter || {
                    lat: defaultCoords.latitude,
                    lng: defaultCoords.longitude,
                  },
                  zoom: mapZoom,
                }}
                data={locations}
                infoWindowContent={['name', 'date', 'GHI']}
                handleChangeZoom={setMapZoom}
                handleSelectLocation={handleSelectLocation}
              />
            </div>
            <ItemsList
              title="Workspace Locations"
              list={locationList}
              selectedItem={selectedLocation}
              handleClick={handleSelectLocation}
            />
          </div>
        </>
      )}
      {selectedLocation && (
        <div className="general-container w-100">
          <ItemsList
            title={`${selectedLocation.dropDownName} Energy Models`}
            list={energyModelList}
            selectedItem={selectedEnergyModel}
            isLoading={isModelListLoading}
            isItemDataLoading={isModelDataLoading}
            error={modelListError}
            handleClick={(model) => handleSelectEnergyModel(model)}
          />
          {isModelDataLoading && (
            <div className="model-data-loader">
              <Loader />
            </div>
          )}
          {modelDataError && <Error />}
          {!isModelDataLoading && energyModelData && (
            <div className="general-section">
              <div className="general-section-inner">
                <div className="properties-section summary">
                  <h3 className="general-section-heading">Simulation Results Summary</h3>
                  <div className="capitalize">
                    <div>Year 1 Mwh:</div>
                    <div>{formatNumber(energyModelData?.simulationResultSummary?.mwh)}</div>
                  </div>
                  <div className="capitalize">
                    <div>Year 1 Specific Yield:</div>
                    <div>
                      {formatNumber(energyModelData?.simulationResultSummary?.specificYield)}
                    </div>
                  </div>
                  <div id="summary-chart" className="home-chart">
                    <Chart
                      chartData={{
                        ...chartConfig,
                        series: [
                          {
                            data:
                              energyModelData &&
                              Object.values(
                                energyModelData.simulationResultSummary?.generatedMwhByMonth
                              ),
                          },
                        ],
                        plotOptions: {
                          series: {
                            pointWidth: 20,
                            borderRadius: 10,
                            color: '#ffab40',
                          },
                        },
                        chart: {
                          type: 'column',
                        },
                        title: {
                          text: 'Generated MWh/Month [Year 1]',
                        },
                        legend: false,
                        yAxis: {
                          title: {
                            enabled: false,
                          },
                        },
                        xAxis: {
                          categories:
                            energyModelData &&
                            Object.keys(
                              energyModelData.simulationResultSummary?.generatedMwhByMonth
                            ),
                        },
                        tooltip: {
                          headerFormat: '<span class="location-chart-tooltip"/>',
                          pointFormat:
                            '<span class="location-chart-tooltip">{point.category}: <b>{point.y}</b></span>',
                          valueSuffix: ' MWh',
                          useHTML: true,
                        },
                      }}
                    />
                  </div>
                  <h3>Layout Summary</h3>
                  {Object.entries(energyModelData?.layoutSummary).map(([key, value]) => (
                    <div className="capitalize" key={key}>
                      <div>
                        {key === 'gcr'
                          ? key?.toUpperCase()
                          : key?.split(/(?=[A-Z])/).map((part) => `${part} `)}{' '}
                        {key === 'azimuth' ? ' [180 deg = South] ' : ''}:
                      </div>
                      <div>{square(formatNumber(value))}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="general-section-inner">
                <div className="properties-section summary">
                  <div>
                    <div>Simulation Name:</div>
                    <div>{energyModelData?.simulationName}</div>
                  </div>
                  <div>
                    <div>Date Run:</div>
                    <div>
                      {new Date(energyModelData?.locationSummary?.dateRun * 1000)
                        .toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'numeric',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                        .replace(/,/g, '')}
                    </div>
                  </div>
                  <h3>Block Electrical Summary</h3>
                  {Object.entries(energyModelData?.blockElectricalSummary).map(([key, value]) => (
                    <div className="capitalize" key={key}>
                      <div>
                        {key
                          ?.split(/(?=[A-Z])/)
                          .reduce(
                            (a, b) =>
                              a[a.length - 1] !== a[a.length - 1]?.toUpperCase() ||
                              (a[a.length - 1] === a[a.length - 1]?.toUpperCase() &&
                                b[1] !== b[1]?.toUpperCase())
                                ? `${a} ${b}`
                                : a + b,
                            ''
                          )
                          .concat('')}
                        :
                      </div>
                      <div>{formatNumber(value)}</div>
                    </div>
                  ))}
                  <h3>Location Summary</h3>
                  {Object.entries(energyModelData?.locationSummary).map(
                    ([key, value]) =>
                      key !== 'dateRun' && (
                        <div className="capitalize" key={key}>
                          <div>{key?.split(/(?=[A-Z])/).map((part) => `${part} `)}:</div>
                          <div>
                            {key === 'locationName' ? (
                              <div className="location-value">{value}</div>
                            ) : (
                              formatNumber(value)
                            )}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;

import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import './chart.css';

const Chart = (props) => {
  const { chartData } = props || {};
  return (
    <div className="capitalize">
      <HighchartsReact highcharts={Highcharts} options={chartData} />
    </div>
  );
};

export default Chart;

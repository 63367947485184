import React from 'react';

export const square = (value) => {
  const stringValue = value?.toString();
  if (stringValue?.slice(stringValue?.length - 2).toLowerCase() === 'm2') {
    return (
      <>
        {stringValue?.slice(0, stringValue?.length - 1)}
        <sup className="square">2</sup>
      </>
    );
  }
  if (stringValue?.slice(stringValue?.length - 3).toLowerCase() === 'm^2') {
    return (
      <>
        {stringValue?.slice(0, stringValue?.length - 2)}
        <sup className="square">2</sup>
      </>
    );
  }

  return stringValue;
};

export const isObjectEmpty = (object) => {
  return Object.keys(object).length === 0;
};

export const formatNumber = (number) => {
  if (number === undefined || number === null) return number;
  const stringNum = number.toString();
  if (Number.isNaN(parseInt(stringNum, 10))) return stringNum;
  const dotIdx = stringNum.indexOf('.');
  const spaceIdx = stringNum.indexOf(' ');
  let formattedNum;
  let integer;
  let rest;
  if (dotIdx === -1 && spaceIdx === -1) {
    integer = stringNum;
    rest = '';
  } else {
    integer = stringNum.slice(
      0,
      (dotIdx < spaceIdx && dotIdx !== -1) || spaceIdx === -1 ? dotIdx : spaceIdx
    );
    rest = stringNum.slice(
      (dotIdx < spaceIdx && dotIdx !== -1) || spaceIdx === -1 ? dotIdx + 1 : spaceIdx + 1
    );
  }
  let copy = integer;
  let isNumberPositive = true;
  if (integer < 1000 && integer > -1000) {
    return stringNum;
  }
  if (integer[0] === '-') {
    isNumberPositive = false;
    copy = integer.slice(1);
  }
  const chunks = [];
  for (let i = copy.length; i > 0; i -= 3) {
    chunks.push(
      copy
        .split('')
        .slice(i - 3 > 0 ? i - 3 : 0, i)
        .join('')
    );
  }
  if (rest !== '') {
    formattedNum =
      dotIdx !== -1
        ? `${chunks.reverse().join(',')}.${rest}`
        : `${chunks.reverse().join(',')} ${rest}`;
  } else {
    formattedNum = chunks.reverse().join(',');
  }

  return isNumberPositive ? formattedNum : `-${formattedNum}`;
};

export const capitalizeFirstLetter = (string) => string[0].toUpperCase() + string.slice(1);

import React, { useState } from 'react';

import './accordion.css';

const Accordion = ({ label, content }) => {
  const [isActive, setActive] = useState(false);
  return (
    <div className="accordion">
      <div className="accordion-item">
        <div role="presentation" className="accordion-title" onClick={() => setActive(!isActive)}>
          {label}
          <div className="accordion-toggle">
            <img
              src="/Icons/Arrow.svg"
              className={`arrow-toggle ${isActive ? 'toggle-active' : ''}`}
              alt="arrow"
            />
          </div>
        </div>
        {isActive && <div className="accordion-content">{content}</div>}
      </div>
    </div>
  );
};

export default Accordion;

import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ref } from 'yup';
import { useHistory } from 'react-router-dom';

import axiosConfig from '../../utils/axiosConfig';
import { TOAST_TYPES } from '../../utils/constants';
import Loader from '../../Components/Shared/Loader';
import InputField from '../../Components/Shared/InputField';
import Toast from '../../Components/Shared/Toast';
import useToast from '../../hooks/useToast';

import './signUp.css';

const SignUp = () => {
  const url = new URLSearchParams(window.location.search);
  const history = useHistory();
  const [registerInfo, setRegisterInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [fromInvite, setFromInvite] = useState(false);
  const { toastList, showToast } = useToast();

  useEffect(() => {
    if (url.get('email')) {
      setFromInvite(true);
      setRegisterInfo({ ...registerInfo, email: url?.get('email') });
    }
  }, [history]);
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        firstName: '',
        secondName: '',
        rePassword: '',
      }}
      onSubmit={async (values, { setErrors }) => {
        try {
          await setLoading(true);
          // eslint-disable-next-line no-param-reassign
          delete values?.rePassword;
          await axiosConfig
            .post(`/api/auth/signUp`, values, {
              params: { inv: url?.get('inv') },
            })
            .then(() => {
              setLoading(false);
              history.push('/login');
              history.previousPath = { from: window.location.pathname };
            });
        } catch (err) {
          setLoading(false);
          if (err.response.status === 406) {
            showToast('Email has been registered already', TOAST_TYPES.error);
          }
          setErrors({
            api: err.response.data.message,
          });
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required('No email provided.')
          .min(3, 'Email is too short - should be 3 chars minimum.')
          .max(50, 'Email is too long - should be 50 chars maximum.')
          .matches(
            /^([0-9A-Za-z]([+\-_.]*[0-9A-Za-z]){0,49})+@(([0-9A-Za-z][-\w]*[0-9A-Za-z]*\.)+[a-z0-9]{2,17})$/,
            'Invalid email address'
          ),
        firstName: Yup.string()
          .required('No First Name provided.')
          .min(3, 'First Name is too short - should be 3 chars minimum.')
          .max(50, 'First Name is too long - should be 50 chars maximum.'),
        // .matches(/^[A-Z][a-z]{2,49}$/, 'First Name should start from capital letter.'),
        secondName: Yup.string()
          .required('No Last Name provided.')
          .min(3, 'Last Name is too short - should be 3 chars minimum.')
          .max(50, 'Last Name is too long - should be 50 chars maximum.'),
        // .matches(/^[A-Z][a-z]{2,49}$/, 'Last Name should start from capital letter.'),
        password: Yup.string()
          .required('No password provided.')
          .min(8, 'Password is too short - should be 8 chars minimum.')
          .max(50, 'Password is too long - should be 50 chars maximum.')
          .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]*)[A-Za-z\d@$!%*#?&]{8,50}$/,
            'Password must be at least 8 characters with one uppercase one lowercase and one number.'
          ),
        rePassword: Yup.string()
          .required('Need to confirm password.')
          .oneOf([ref('password')], 'Passwords do not match.'),
      })}
    >
      {(props) => {
        const { values, handleSubmit, handleReset } = props;

        useEffect(() => {
          if (url.get('email')) {
            values.email = url?.get('email');
          }
        }, [history]);

        return (
          <div id="login-page">
            {loading ? (
              <Loader />
            ) : (
              <>
                <Toast toastList={toastList} />
                <div className="login-box">
                  <button type="button" onClick={() => history.push('/login')}>
                    ← Go back
                  </button>
                  <form id="sign-up-form" onSubmit={handleSubmit}>
                    {/* eslint-disable jsx-a11y/label-has-associated-control */}
                    <div>
                      <label htmlFor="firstName">First Name</label>
                      <InputField
                        name="firstName"
                        placeholder="Enter your First Name"
                        type="text"
                        formikData={props}
                      />
                    </div>
                    <div>
                      <label htmlFor="secondName">Last Name</label>
                      <InputField
                        name="secondName"
                        placeholder="Enter your Last Name"
                        type="text"
                        formikData={props}
                      />
                    </div>
                    <div>
                      <label htmlFor="email">Email</label>
                      <InputField
                        value={registerInfo?.email || values.email}
                        disabled={fromInvite}
                        name="email"
                        placeholder="Enter your Email"
                        type="email"
                        formikData={props}
                      />
                    </div>
                    <div>
                      <label htmlFor="password">Password</label>
                      <InputField
                        name="password"
                        placeholder="Enter your Password"
                        type="password"
                        formikData={props}
                      />
                    </div>
                    <div>
                      <label htmlFor="rePassword">Confirm Password</label>
                      <InputField
                        value={values.rePassword || ''}
                        name="rePassword"
                        placeholder="Confirm Password"
                        type="password"
                        formikData={props}
                      />
                    </div>
                    <div className="buttons">
                      <button type="submit">Sign-Up</button>
                      <button type="button" className="cancel-button" onClick={handleReset}>
                        Clear
                      </button>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default SignUp;

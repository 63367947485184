import { useEffect, useState } from 'react';
import axiosConfig from '../utils/axiosConfig';

export const useOptionsList = (type) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      await setLoading(true);
      await axiosConfig
        .get(`/api${type}/all`)
        .then((response) => {
          if (response) setData(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.type !== undefined ? err : err);
          setLoading(false);
        });
    })();
    return () => setData([]);
  }, []);

  return {
    data,
    isLoading,
    setLoading,
    setData,
    error,
  };
};
